/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */

import {
  Avatar,
  AvatarContainer,
  ButtonContainer,
  DropContainer,
  Label,
} from './style';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { CloudArrowUp, Upload } from 'react-bootstrap-icons';
import { Form as FormikForm, Formik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  getAvatar,
  getSignature,
  updateUser,
  uploadAvatar,
  uploadSignature,
} from '../../utils/API/user';

import { UserContext } from '../../context/UserContext';
import Dropzone from 'react-dropzone';
import * as Yup from 'yup';
import FormItem from '../../components/Form/FormItem';
import moment from 'moment';

const validationSchema = Yup.object().shape({
  private_email: Yup.string().email('Indirizzo email non valido'),
  car_model: Yup.string()
    .strict(true)
    .trim('Non sono ammessi spazi a inizio e/o fine'),
  car_plate: Yup.string()
    .strict(true)
    .trim('Non sono ammessi spazi a inizio e/o fine'),
});

function ProfileForm() {
  const { user, updateUser: updateContextUser } = useContext(UserContext);
  const {
    id,
    car_model,
    car_plate,
    private_email,
    fiscal_code,
    incoming_date,
    email,
    username,
    first_name,
    last_name,
    identification_number,
    company,
  } = user;
  const [signature, setSignature] = useState('');
  const [avatar, setAvatar] = useState('');

  const showSignature = async (response) => {
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const base64data = reader.result;
      setSignature(base64data);
    };
  };

  const showAvatar = async (response) => {
    if (response?.data) {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        setAvatar(base64data);
      };
    }    
  };

  const loadSignature = () =>
    getSignature().then((image) => showSignature(image));

  const loadAvatar = () => getAvatar().then((image) => showAvatar(image));

  useEffect(() => {
    loadSignature();
    loadAvatar();
  }, []);

  async function onSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const payload = {
      car_plate: (values.car_plate || '').trim(),
      car_model: (values.car_model || '').trim(),
      private_email: (values.private_email || '').toLowerCase().trim(),
    };
    return updateUser(payload).then(() => {
      updateContextUser(payload);
      setSubmitting(false);
    });
  }

  const onDropSignature = useCallback(async (acceptedFiles) => {
    await uploadSignature(acceptedFiles[0]);
    loadSignature();
  }, []);

  const onDropAvatar = useCallback(async (acceptedFiles) => {
    await uploadAvatar(acceptedFiles[0]);
    loadAvatar();
  }, []);

  const get_incoming_date = () => {
    const in_date = moment(incoming_date);

    if (!in_date.isValid()) return '';

    return in_date.format('DD/MM/YYYY');
  };

  const get_how_long_in_intre = () => {
    const in_date = moment(incoming_date);
    if (!in_date.isValid()) return '';

    const d = moment.duration(moment().startOf('day') - in_date);
    let s = '';
    const years = d.years();
    const months = d.months();
    const days = d.days();
    if (years > 0) s += `${years} ${years === 1 ? 'anno' : 'anni'} `;
    if (months > 0) s += `${months} ${months === 1 ? 'mese' : 'mesi'} `;
    if (days > 0) s += `${days} ${days === 1 ? 'giorno' : 'giorni'}`;
    if (!s.length) s = '0 giorni';
    return s;
  };

  const avatarStyle = avatar ? { backgroundImage: `url("${avatar}")` } : {};
  const avatarClass = avatar ? '' : 'noAvatar';
  const signatureStyle = signature
    ? { backgroundImage: `url("${signature}")` }
    : {};

  return id ? (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions);
        actions.resetForm({ values });
      }}
      initialValues={{
        car_model,
        car_plate,
        private_email,
        fiscal_code,
        incoming_date: get_incoming_date(),
        how_long_in_intre: get_how_long_in_intre(),
        email,
        username,
        first_name,
        last_name,
        company,
        identification_number: identification_number.toLocaleString(undefined, {minimumIntegerDigits: 7, useGrouping: false}),
      }}
    >
      {({ handleSubmit, dirty, isSubmitting, isValidating, isValid }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col>
                <Dropzone onDrop={onDropAvatar}>
                  {({ getRootProps, getInputProps }) => (
                    <AvatarContainer {...getRootProps()}>
                      <Label>Foto Profilo</Label>
                      <input {...getInputProps()} />
                      <Avatar style={avatarStyle} className={avatarClass}>
                        <Upload />
                      </Avatar>
                    </AvatarContainer>
                  )}
                </Dropzone>
              </Col>
              <Col xs={9}>
                <Row>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Nome"
                      readOnly
                      name="first_name"
                    />
                  </Col>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Cognome"
                      readOnly
                      name="last_name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Username"
                      readOnly
                      name="username"
                    />
                  </Col>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Codice Fiscale"
                      readOnly
                      name="fiscal_code"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Data di assunzione"
                      readOnly
                      name="incoming_date"
                    />
                  </Col>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label={`Sei in ${company.name} da`}
                      readOnly
                      name="how_long_in_intre"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ maxWidth: '50%' }}>
                    <FormItem
                      type="text"
                      label="Matricola"
                      readOnly
                      name="identification_number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Email aziendale"
                      readOnly
                      name="email"
                    />
                  </Col>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Email privata"
                      maxLength="100"
                      name="private_email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Modello Auto"
                      maxLength="100"
                      name="car_model"
                    />
                  </Col>
                  <Col style={{ width: '50%' }}>
                    <FormItem
                      type="text"
                      label="Targa"
                      maxLength="10"
                      name="car_plate"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Firma digitale</Label>
                    <Dropzone onDrop={onDropSignature}>
                      {({ getRootProps, getInputProps }) => (
                        <DropContainer
                          {...getRootProps()}
                          style={signatureStyle}
                        >
                          <input {...getInputProps()} />
                          {!!signature && (
                            <b>
                              <Upload />
                            </b>
                          )}
                          {!signature && (
                            <div>
                              <p>
                                <CloudArrowUp />
                                <br />
                                Trascina qui i file da allegare, oppure{' '}
                                <b>clicca</b> per selezionarli
                              </p>
                            </div>
                          )}
                        </DropContainer>
                      )}
                    </Dropzone>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <ButtonContainer className="buttonContent">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting || !dirty || isValidating || !isValid}
                >
                  Salva
                </Button>
              </ButtonContainer>
            </Row>
          </Container>
        </FormikForm>
      )}
    </Formik>
  ) : (
    <Spinner animation="border" variant="light" />
  );
}

export default ProfileForm;
