import 'moment/locale/it';
import '../../css/tempo.scss';
import { prepareDaysData } from '../../utils/Utility';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { WorklogDayCell } from './WorklogDayCell';
import { WorklogIssueCell } from './WorklogIssueCell';
import cn from 'classnames';
import { decimalFormatter } from '../../components/Table/utility';
import { forwardRef } from 'react';
import { DayHeaderCell } from '../../components/Cells/CalendarTable/DayHeaderCell';
import TableCell from '@mui/material/TableCell';

const WorklogTable = forwardRef((props, ref) => {
  const {
    userIssues,
    userProjects,
    worklogs,
    dayHeaders,
    isReadOnly,
    onDeleteClick,
    onEditClick,
    onTimeSpentChange,
    onScroll,
  } = props;

  const data = prepareDaysData({ worklogs, userIssues, userProjects });

  const totalHoursPerAllIssues = data.reduce(    
    (acc, curr) => acc + curr.hours, 0    
  );

  return (
      <TableContainer component={Paper} sx={{ maxHeight: 537 }} onScroll={onScroll} ref={ref}>
        <Table stickyHeader className='month-table'>
          <TableHead>
            <TableRow>
              <TableCell>Attività</TableCell>
              <TableCell>Ore</TableCell>
              {dayHeaders.map((header) => (
                <DayHeaderCell 
                  day={header.day}
                  isWeekEnd={header.weekEnd}
                  isHoliday={header.isHolidays}
                  isToday={header.isToday}
                  key={header.day.format('DD')}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item.issueId}
              >
                <WorklogIssueCell 
                  projectSummary={item.projectSummary}
                  issueSummary={item.issueSummary}
                />
                <TableCell>
                  {decimalFormatter.format(item.hours)}
                </TableCell>
                {dayHeaders.map((header) => {
                  const key = header.day.format('DD');
                  const worklog = item.days[key];
                  return <WorklogDayCell 
                    worklog={worklog}
                    date={header.day.format('YYYY-MM-DD')}
                    issueId={item.issueId}
                    isReadOnly={isReadOnly}         
                    isWeekEnd={header.weekEnd}
                    isHoliday={header.isHolidays}
                    isToday={header.isToday}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onTimeSpentChange={onTimeSpentChange}
                    key={key}
                  />
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
                <TableCell>Totale</TableCell>
                <TableCell>
                  {decimalFormatter.format(totalHoursPerAllIssues)}
                </TableCell>
                {dayHeaders.map((header) => {
                  /* eslint-disable no-param-reassign */
                  const key = header.day.format('DD');
                  const totalHours = data.reduce(
                    (acc, curr) => {
                      const dayData = curr.days[key];
                      if (dayData) {
                        acc += dayData.time_spent;
                      }     
                      return acc;                 
                    }, 0
                  );
                  /* eslint-enable no-param-reassign */                  
                  const tableCellClassNames = cn({
                    today: header.isToday,
                  });
                  return <TableCell 
                    key={key}
                    className={tableCellClassNames}
                  >
                    {totalHours > 0 ? decimalFormatter.format(totalHours) : ''}
                  </TableCell>
                })}
              </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  );
});

WorklogTable.defaultProps = {
  isReadOnly: false,
  dayHeaders: [],
  userIssues: [],
  userProjects: [],
  worklogs: [],
  onDeleteClick: () => {},
  onEditClick: () => {},
  onTimeSpentChange: () => {},
  onScroll: () => {},
};

WorklogTable.propTypes = {
  isReadOnly: PropTypes.bool,
  dayHeaders: PropTypes.instanceOf(Array),
  userIssues: PropTypes.instanceOf(Array),
  userProjects: PropTypes.instanceOf(Array),
  worklogs: PropTypes.instanceOf(Array),
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onTimeSpentChange: PropTypes.func,
  onScroll: PropTypes.func,
};

WorklogTable.displayName = "WorklogTable";
export default WorklogTable;
