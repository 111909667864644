import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import moment from 'moment';

const MonthContext = createContext({
  month: moment(),
  setMonth: () => {},
});

const MonthContextProvider = ({ children }) => {
  const [month, setMonth] = useState(moment());
  return (
    <MonthContext.Provider value={{ month, setMonth }}>
      {children}
    </MonthContext.Provider>
  );
};

MonthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MonthContext, MonthContextProvider };
