import { useContext, useEffect, useState } from 'react';
import { renderSpinner, prepareDaysColumnHeader } from '../../utils/Utility';
import { UserContext } from '../../context/UserContext';
import { MonthNavHeader } from '../../components/Layout/MonthNavHeader';
import { MonthContext } from '../../context/MonthContext';
import AbsencesTable from './AbsencesTable';
import { fetchAbsences, deleteAbsence } from '../../utils/API/absences';
import I3Modal from '../../components/Layout/I3Modal';
import { Button } from 'react-bootstrap';
import { getReportDetail } from '../../utils/API/reports';
import AbsenceForm from './AbsenceForm';
import { useKeyPress } from '../../hooks/useKeyPress';
import { getCalendarWorklogsHeader } from '../../utils/API/calendar';

export const AbsencesPage = () => {
  const { user } = useContext(UserContext);
  const { month } = useContext(MonthContext);  

  const [absences, setAbsences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dayHeaders, setDayHeaders] = useState([]);
  const [editFormData, setEditFormData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState({});  
  const [showModalDeleteConfirmOptions, setShowModalDeleteConfirmOptions] = useState({ show: false });  
  
  const loadData = () => {
    setIsLoading(true);
    getCalendarWorklogsHeader(month)
    .then(calendarWorklogsHeader => {
      const res = prepareDaysColumnHeader({month, calendarWorklogsHeader});
      setDayHeaders(res);
    })
    .then(() => getReportDetail(month.format('YYYY'), month.format('MM'), user.username, 'worklogs'))
    .then((reportData) => setReport(reportData))
    .then(() => fetchAbsences(month.format('YYYY'), month.format('MM'), [user.username]))
    .then(res => setAbsences(res))
    .finally(() => setIsLoading(false));  
  }

  useEffect(loadData, [month, user]);
    
  const isReportConfirmed = report.confirmed || !report.id;
  
  const handleToggleModal = () => setShowModal(!showModal);

  const handleHideDeleteConfirmModal = () => {
    setShowModalDeleteConfirmOptions({
      ...showModalDeleteConfirmOptions,
      show: !showModalDeleteConfirmOptions.show,
    });
  };

  const handleDeleteConfirmModal = () => {
    setShowModalDeleteConfirmOptions({
      ...showModalDeleteConfirmOptions,
      show: !showModalDeleteConfirmOptions.show,
    });
    const { id } = showModalDeleteConfirmOptions;
    deleteAbsence(id).then(() => {
      handleHideDeleteConfirmModal();
      loadData();
    });
  };

  const handleAddClick = (date) => {
    setEditFormData({
      id: undefined,      
      selectedDate: date,
    });
    setShowModal(true);
  };

  const handleEditClick = (absence) => {
    setEditFormData(absence);
    setShowModal(true);
  };
  
  const handleDeleteClick = (id) => {
    setShowModalDeleteConfirmOptions({
      show: true,
      id,
    });
  };
  
  useKeyPress('F9', () => {
    if (isReportConfirmed) {
      return;
    }
    handleAddClick(undefined);
  });
  useKeyPress('Escape', () => {
    setShowModal(false);
  });

  return (
    <>
      <div className="content__wrapper">
        <MonthNavHeader
          title="Assenze"
          showInsertButton={!isReportConfirmed}
          onInsert={() => handleAddClick(undefined)}
        />

        <div className="content__overflow">
          <div className="tabsContainer" style={{ overflow: 'auto' }}>
            {isLoading ? (
              renderSpinner(true)
            ) : (
              <AbsencesTable
                dayHeaders={dayHeaders}
                data={absences}
                isReadOnly={isReportConfirmed}
                onAddClick={handleAddClick}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
              />
            )}
            <I3Modal
              show={showModalDeleteConfirmOptions.show}
              onHide={() => setShowModalDeleteConfirmOptions({ show: false })}
              title="Elimina"
            >
              <div>
                <hr className="divider" />
                <p className="question-help">
                  Vuoi cancellare l&apos;assenza selezionata?
                </p>
                <div className="buttonContent">
                  <Button
                    variant="secondary"
                    onClick={handleHideDeleteConfirmModal}
                  >
                    Annulla
                  </Button>
                  <Button variant="primary" onClick={handleDeleteConfirmModal}>
                    Elimina
                  </Button>
                </div>
              </div>
            </I3Modal>

            <I3Modal
              show={showModal}
              onHide={handleToggleModal}
              title="Assenza"
              className="I3Modal-log-time"
            >
              <AbsenceForm
                month={month}
                close={handleToggleModal}
                editFormData={editFormData}
                onDataChanged={loadData}
                isReadOnly={isReportConfirmed}
              />
            </I3Modal>
          </div>
        </div>
      </div>
    </>
  );
};
