import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { renderSpinner, prepareDaysColumnHeader } from '../../utils/Utility';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { UserContext } from '../../context/UserContext';
import { MonthNavHeader } from '../../components/Layout/MonthNavHeader';
import { MonthContext } from '../../context/MonthContext';
import { Unauthorized } from '../../components/Unauthorized';
import AbsencesTable from './AbsencesTable';
import { fetchAbsences } from '../../utils/API/absences';
import { PERMISSIONS } from '../../constants';
import { UserListContext } from '../../context/UserListContext';
import I3Modal from '../../components/Layout/I3Modal';
import AbsenceForm from './AbsenceForm';
import { useKeyPress } from '../../hooks/useKeyPress';
import { getCalendarWorklogsHeader } from '../../utils/API/calendar';

const SELECTED_USERS_LOCAL_STORAGE_KEY = "view-absences-selected-users";
const PARAM_NAME = 'u';

export const ViewAbsencesPage = () => {
  const { hasClaim } = useContext(UserContext);
  const { month } = useContext(MonthContext);  
  const { users, usersById } = useContext(UserListContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFromUrl = searchParams
    .getAll(PARAM_NAME)
    .map((n) => +n)
    .filter((n) => n >= 0);
  
  const getSelectedUserIds = () => {
    if (selectedFromUrl.length > 0) {
      return selectedFromUrl;
    }

    const selectedFromStorage = localStorage.getItem(SELECTED_USERS_LOCAL_STORAGE_KEY);
    if (selectedFromStorage) {
      return JSON.parse(selectedFromStorage);
    }

    return [];
  }

  const setSelectedUserIds = (selectedUserIds) => {
    localStorage.setItem(SELECTED_USERS_LOCAL_STORAGE_KEY, JSON.stringify(selectedUserIds));
    setUserIds(selectedUserIds);
  }

  const [userIds, setUserIds] = useState(getSelectedUserIds());
  const [dayHeaders, setDayHeaders] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getCalendarWorklogsHeader(month)
    .then(calendarWorklogsHeader => {
      const headers = prepareDaysColumnHeader({
        month,
        calendarWorklogsHeader,
      });
      setDayHeaders(headers);
    })
    .then(() => {      
      const usernames = userIds.map((id) => usersById[id]?.username).filter((u) => u);
      return fetchAbsences(month.format('YYYY'), month.format('MM'), usernames)
    })
    .then(res => setAbsences(res.filter(i => i.totalHours > 0)))
    .finally(() => setIsLoading(false));      
  }, [month, userIds, usersById]);

  const [editFormData, setEditFormData] = useState();

  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    setSearchParams({ [PARAM_NAME]: userIds });
  }, [setSearchParams, userIds]);

  if (!hasClaim(PERMISSIONS.VIEW_ABSENCES)) {
    return <Unauthorized />;
  }

  const handleEditClick = (data) => {
    const allAbsences = absences.map(i => i.items).flat();
    const absence = allAbsences.find(i => i.id === data.id);
    if (absence) {
      setEditFormData(absence);
      setShowModal(true);
    }
  }

  useKeyPress('Escape', () => {
    setShowModal(false);
  });
  
  return (
    <>
      <div className="content__wrapper">
        <MonthNavHeader title="Assenze">
          <MultiSelectUser
            users={users}
            selected={userIds}
            setSelected={setSelectedUserIds}
          />
        </MonthNavHeader>

        <div className="content__overflow">
          <div className="tabsContainer" style={{ overflow: 'auto' }}>            
            {isLoading ? (
              renderSpinner(isLoading)
            ) : (
            <AbsencesTable
              dayHeaders={dayHeaders}
              data={absences}
              isReadOnly={true}
              onEditClick={handleEditClick}
            />
            )}

            <I3Modal
              show={showModal}
              onHide={handleToggleModal}
              title="Assenza"
              className="I3Modal-log-time"
            >
              <AbsenceForm
                month={month}
                close={handleToggleModal}
                editFormData={editFormData}
                isReadOnly={true}
              />
            </I3Modal>
          </div>
        </div>
      </div>
    </>
  );
};
