import PropTypes from 'prop-types';
import Styled from 'styled-components';

const EmptyTableDiv = Styled.div`
    height: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyTable = ({ message }) => (
  <EmptyTableDiv>
    <div className="nodata__placeholder">
      <span className="icon-notification-circle" />
      <span>{message}</span>
    </div>
  </EmptyTableDiv>
);
EmptyTable.propTypes = { message: PropTypes.string };
EmptyTable.defaultProps = { message: 'No data found' };
