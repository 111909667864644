import PropTypes from 'prop-types';
import FormItem from '../Form/FormItem';
import TrashcanIcon from '../Icons/TrashcanIcon';
import { Button } from 'react-bootstrap';

export const RetrospectiveGoalsAndResultsItem = ({
  index,
  objectName,
  typeList,
  outcomes,
  mode,
  onDelete
}) => (
  <div>
    <div className="inline-fields">
      <FormItem
        type="select"
        name={`${objectName}.${index}.type.id`}
        label="Tipologia"
        className="form-group"
        options={typeList.map((element) => (
          <option key={element.id} value={element.id}>
            {element.name}
          </option>
        ))}
        disabled={mode === 'review'}
      />
      {mode === 'review' && (
        <FormItem
          type="select"
          name={`${objectName}.${index}.outcome`}
          label="Esito"
          className="form-group"
          options={Object.entries(outcomes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        />
      )}
      {mode === 'edit' && (
        <div>
          <Button style={{float:"right"}} type="button" variant="secondary" onClick={onDelete}>
            <TrashcanIcon />
          </Button>
        </div>
      )}
    </div>
    {mode === 'review' && (
      <FormItem
        type="textarea"
        rows="3"
        name={`${objectName}.${index}.reason`}
        label="Motivazione esito"
        className="form-group"
      />
    )}
    <FormItem
      type="textarea"
      name={`${objectName}.${index}.description`}
      label="Descrizione"
      rows="5"
      className="form-group"
      readOnly={mode === 'review'}
    />
  </div>
);

RetrospectiveGoalsAndResultsItem.propTypes = {
  index: PropTypes.number.isRequired,
  objectName: PropTypes.string.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.object),
  outcomes: PropTypes.shape({
    'Non superato': PropTypes.number,
    'Parziale': PropTypes.number,
    'Superato': PropTypes.number
  }),
  mode: PropTypes.oneOf(['edit', 'review']),
  onDelete: PropTypes.func
};

RetrospectiveGoalsAndResultsItem.defaultProps = {
  typeList: [],
  outcomes: {
    achieved: 'Raggiunto',
    partially_achieved: 'Parziale',
    not_achieved: 'Non raggiunto'
  },
  mode: 'edit',
  onDelete: () => {
  }
};

export default RetrospectiveGoalsAndResultsItem;
