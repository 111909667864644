import _ from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { renderSpinner } from '../../utils/Utility';
import I3Modal from '../../components/Layout/I3Modal';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import { RetrospectiveDataGrid } from './RetrospectiveDataGrid';
import {
  deleteRetrospective,
  getRetrospectiveByYearCompanyAndUsernames,
  notifyRetrospective
} from '../../utils/API/retrospective';
import { RetrospectiveForm } from './RetrospectiveForm';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { UserListContext } from '../../context/UserListContext';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import MailForm from '../../components/MailForm';
import { getCompanies } from '../../utils/API/companies';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RetrospectiveReports } from './RetrospectiveReports';
import { getRetrospectiveGoalsReportByYearCompanyAndUsernames } from '../../utils/API/retrospectiveGoals';

export const RetrospectivePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { users } = useContext(UserListContext);
  const { hasClaim } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [retrospectives, setRetrospectives] = useState([]);
  const [retrospectiveGoalsReport, setRetrospectiveGoalsReport] = useState();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [selectedRetrospective, setSelectedRetrospective] = useState(undefined);
  const availableYears = _.range(2023, moment().year() + 1);
  const [selectedYear, setSelectedYear] = useState();
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState();
  const [selectedTabKey, setSelectedTabKey] = useState('retrospectives');

  if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
    return (<Unauthorized />);
  }

  const filtersInitialized = useMemo(() => selectedYear && selectedCompanyId && selectedUserIds, [selectedYear, selectedCompanyId, selectedUserIds]);

  useEffect(() => {
    if (state?.filters) {
      // restore previous filters
      setSelectedYear(state.filters.year);
      setSelectedCompanyId(state.filters.company);
      setSelectedUserIds(state.filters.users);
    } else {
      // initialize filters
      setSelectedYear(moment().year());
      setSelectedCompanyId(1);
      setSelectedUserIds([]);
    }
  }, [state]);

  useEffect(() => {
    if (filtersInitialized) {
      // reload data when year or selected users change
      setIsLoading(true);
      const getData = async () => {
        setAvailableCompanies(await getCompanies());
        const usernames = users.filter(u => selectedUserIds?.includes(u.id)).map(u => u.username) ?? [];
        setRetrospectives(await getRetrospectiveByYearCompanyAndUsernames(selectedYear, selectedCompanyId, usernames));
        setRetrospectiveGoalsReport(await getRetrospectiveGoalsReportByYearCompanyAndUsernames(selectedYear, selectedCompanyId, usernames));
      };
      getData().finally(() => setIsLoading(false));
    }
  }, [users, selectedUserIds, selectedYear]);

  useEffect(() => {
    if (filtersInitialized) {
      // reload users when company changes
      const filteredUsers = users.filter(u => u.company === selectedCompanyId);
      const filteredIds = filteredUsers.map(u => u.id);
      const selectedIds = selectedUserIds.filter(id => filteredIds.includes(id)); // keep selected only users that belong to the given company
      setSelectedUserIds(selectedIds);
      setAvailableUsers(filteredUsers);
    }
  }, [users, selectedCompanyId]);

  const navigateToEditPage = (id) => {
    navigate(`/retrospectives/${id}`, {
      state: {
        filters: {
          year: selectedYear,
          company: selectedCompanyId,
          users: selectedUserIds
        }
      }
    });
  };

  const handleAddClick = () => {
    setShowUpsertModal(true);
  };

  const handleEditClick = navigateToEditPage;

  const handleDeleteClick = (retrospective) => {
    setSelectedRetrospective(retrospective);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    try {
      await deleteRetrospective(selectedRetrospective.id);
      setRetrospectives(retrospectives.filter(i => i.id !== selectedRetrospective.id));
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  const handleNotifyClick = (retrospective) => {
    setSelectedRetrospective(retrospective);
    setShowNotificationModal(true);
  };

  const sendNotification = async (data) => {
    const notifiedRetrospective = await notifyRetrospective(selectedRetrospective.id, data);
    setRetrospectives(retrospectives.map(retrospective => retrospective.id === notifiedRetrospective.id
      ? notifiedRetrospective
      : retrospective
    ));
  };

  const getMailSubject = useCallback(() => {
    if (!selectedRetrospective) {
      return '';
    }
    return `Retrospettiva ${selectedRetrospective.year}`;
  }, [selectedRetrospective]);

  const getMailBody = useCallback(() => {
    if (!selectedRetrospective || !selectedRetrospective.currentGoals?.length) {
      return '';
    }
    const text = [
      `Ciao ${selectedRetrospective.user.first_name},<br/>`,
      'ecco un recap degli obiettivi che abbiamo concordato durante la tua retrospettiva annuale.<br/>',
      'In caso ci fosse qualcosa che non ti torna, non esitare a dirmelo.<br/>',
      '<br/>'
    ];
    if (selectedRetrospective.feedbackForm) {
      text.push(
        `Inoltre, ti chiederemmo anche un feedback (anonimo) per sapere com'è andata la tua retrospettiva e per aiutarci a organizzare al meglio le prossime: <a href="${selectedRetrospective.feedbackForm}" target="_blank">${selectedRetrospective.feedbackForm}</a><br/>`,
        '<br/>'
      );
    }
    text.push('Obiettivi:', '<ul>', ...selectedRetrospective.currentGoals.map(goal => `<li>${goal.description}</li>`), '</ul>', '<br/>');
    text.push('Grazie!');
    return text.join('');
  }, [selectedRetrospective]);

  return (
    filtersInitialized
      ? <>
        <div className="content__wrapper">
          <div className="headerPage content">
            <div className="title">Retrospettiva</div>
            <div className="spacer" />
            <div className="d-flex">
              <div className="form-group">
                <select
                  className="yearContainer"
                  defaultValue={selectedYear}
                  onChange={({ target: { value } }) => setSelectedYear(+value)}
                >
                  {availableYears.map((n) => (
                    <option value={+n} key={+n}>
                      {n}
                    </option>
                  ))}
                </select>
              </div>
              <div className="buttonGroup">
                {availableCompanies.length > 0 && (
                  <TextField
                    sx={{ width: 200 }}
                    select
                    fullWidth
                    size="small"
                    label="Azienda"
                    value={selectedCompanyId}
                    onChange={({ target: { value } }) => setSelectedCompanyId(value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    {availableCompanies.map((c) => (
                      <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                    ))}
                  </TextField>
                )}
                <MultiSelectUser
                  users={availableUsers}
                  selected={selectedUserIds}
                  setSelected={setSelectedUserIds}
                />
                <Button variant="primary" onClick={() => handleAddClick()}>
                  <PlusCircle />
                  Inserisci
                </Button>
              </div>
            </div>
          </div>
          <div className="content__overflow">
            <div className="tabsContainer" style={{ flex: '1 1 0px' }}>
              {isLoading
                ? (renderSpinner(true))
                : (
                  <Tabs
                    className="mb-3 tabs-chart"
                    activeKey={selectedTabKey}
                    onSelect={(k) => setSelectedTabKey(k)}
                    justify
                    mountOnEnter
                  >
                    <Tab eventKey="retrospectives" title="Retro">
                      <RetrospectiveDataGrid
                        data={retrospectives}
                        year={selectedYear}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                        onNotifyClick={handleNotifyClick}
                      />
                    </Tab>
                    <Tab eventKey="reports" title="Report">
                      {!_.isNil(retrospectiveGoalsReport)
                        ? <RetrospectiveReports data={retrospectiveGoalsReport} />
                        : <div>Report non disponibile</div>
                      }
                    </Tab>
                  </Tabs>
                )
              }
            </div>
          </div>
        </div>
        <I3Modal
          show={showUpsertModal}
          onHide={() => setShowUpsertModal(false)}
          title="Aggiungi retrospettiva"
        >
          <RetrospectiveForm
            onClose={() => setShowUpsertModal(false)}
            onSubmitSuccess={(data) => navigateToEditPage(data.id)}
          />
        </I3Modal>
        <I3ModalConfirmation
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
          title="Cancella retrospettiva"
          questionTitle="Vuoi cancellare questa retrospettiva?"
          onConfirm={handleConfirmDeleteClick}
        />
        <I3Modal
          show={showNotificationModal}
          onHide={() => setShowNotificationModal(false)}
          title="Invia obiettivi"
        >
          <MailForm
            htmlEditor={true}
            subject={getMailSubject()}
            body={getMailBody()}
            users={[selectedRetrospective?.user]}
            onSend={sendNotification}
            onClose={() => setShowNotificationModal(false)}
          />
        </I3Modal>
      </>
      : <></>
  );
};
