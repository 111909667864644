import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row
} from 'react-bootstrap';
import { useCallback, useContext, useEffect, useState } from 'react';
import Styled from 'styled-components';
import _ from 'lodash';
import {
  confirmReport,
  getMultiPdf,
  getReportList,
  getXlsxReport
} from '../../utils/API/reports';
import { UserContext } from '../../context/UserContext';
import { MonthNavHeader } from '../../components/Layout/MonthNavHeader';
import BaseTable from '../../components/Table/BaseTable';
import prepareTableAdministration from './prepareTableAdministration';
import I3Modal from '../../components/Layout/I3Modal';
import MailForm from '../../components/MailForm';
import RowDetail from '../../components/PanelTotal/rowDetailPanelTotal';
import { renderSpinner } from '../../utils/Utility';
import SendFiscalDocumentsForm from './sendFiscalDocumentsForm';
import { MonthContext } from '../../context/MonthContext';
import { success, error } from '../../utils/notification';
import { Unauthorized } from '../../components/Unauthorized';
import { PERMISSIONS, API } from '../../constants';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { getCompanies } from '../../utils/API/companies';
import { sendEmail } from '../../utils/API/user';

const Hr = Styled.hr`
  border-top: 1px solid var(--brand);
`;

const fetchData = async (company, yyyy, mm) => {
  try {
    const reportsData = await getReportList(company, yyyy, mm);
    return _.orderBy(reportsData, ['all_confirmed', 'last_name'], ['asc']);
  } catch (e) {
    return undefined;
  }
};

const CardRow = Styled(Row)`
    padding: 7px 15px;
`;

const POLLING_INTERVAL_MS = 20_000;

const Administration = () => {
  const { hasClaim } = useContext(UserContext);
  const { month } = useContext(MonthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFiscalModal, setShowFiscalModal] = useState(false);
  const [selectedFlatRows, setSelectedFlatRows] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(1);

  const getSelectedRows = () => selectedFlatRows.map((r) => r.original);

  const handleHideMailModal = () => setShowModal(false);
  const handleHideFiscalModal = () => setShowFiscalModal(false);

  const monthMM = month.format('MM');
  const yearYYYY = month.format('YYYY');
  const filenameWithDate = (name, ext) =>
    `${yearYYYY}-${monthMM} - ${name}.${ext}`;

  const updateData = useCallback(async () => {
    const response = await fetchData(selectedCompany, yearYYYY, monthMM);
    if (response) setReports(response);
    setIsLoading(false);
  }, [selectedCompany, monthMM, yearYYYY]);

  const generatePDF = async (users, types, filename, toBePrint = false) => {
    setIsLoading(true);
    try {
      const res = await getMultiPdf({
        data: {
          usernames: users || [],
          types,
          to_be_print: toBePrint
        },
        company: selectedCompany,
        year: yearYYYY,
        month: monthMM
      });
      const url = window.URL.createObjectURL(res.data);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.target = '_blank';
      tempLink.setAttribute('download', filenameWithDate(filename, 'pdf'));
      tempLink.click();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const generateXLSX = async (filename, apiUrl) => {
    setIsLoading(true);
    try {
      const res = await getXlsxReport({
        apiUrl,
        company: selectedCompany,
        year: yearYYYY,
        month: monthMM
      });
      if (res.status === 204) {
        success('Esito', 'Il report XLSX non contiene dati');
        setIsLoading(false);
      } else {
        const url = window.URL.createObjectURL(res.data);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.target = '_blank';
        tempLink.setAttribute('download', filenameWithDate(filename, 'xlsx'));
        tempLink.click();
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const [pollingCount, setPollingCount] = useState(0);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (selectedFlatRows.length > 0) {
        return;
      }
      setPollingCount((n) => n + 1);
    }, POLLING_INTERVAL_MS);
    return () => clearInterval(timeoutId);
  }, [selectedFlatRows]);

  useEffect(() => {
    setIsLoading(true);
    updateData().catch(() =>
      error('Errore', 'Errore nel caricamento dei dati')
    );
  }, [updateData, pollingCount]);

  useEffect(() => {
    const getData = async () => {
      setCompanies(await getCompanies());
    };
    getData();
  }, []);

  const prepareTable = prepareTableAdministration({
    data: reports,
    generatePDF
  });

  const lockReports = (lock) =>
    confirmReport(
      {
        confirm: lock,
        notes: '',
        usernames: getSelectedRows().map((r) => r.username)
      },
      month
    ).then(() => updateData());

  return hasClaim(PERMISSIONS.ADMINISTRATION) ? (
    <>
      {renderSpinner(isLoading)}
      <div className="content__wrapper">
        <MonthNavHeader title="Amministrazione">
          {companies.length > 0 && (
            <TextField
              sx={{ width: 200 }}
              select
              fullWidth
              size="small"
              label="Azienda"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            >
              {companies.map((i) => (
                <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
              ))}
            </TextField>
          )}
        </MonthNavHeader>
        <div className="content__overflow">
          <div className="tabsContainer">
            <Col md="12">
              <BaseTable
                columns={prepareTable.columns}
                data={reports}
                hiddenColumns={prepareTable.hiddenColumns}
                columnOrder={prepareTable.columnOrder}
                setSelectedFlatRows={setSelectedFlatRows}
              />
            </Col>
          </div>
        </div>
      </div>
      <div className="sidebar">
        <div className="sidebar__header position-relative">
          Riepilogo {month.format('MMMM YYYY')}
        </div>
        <div className="sidebar__body">
          <RowDetail label="Gli utenti che non hanno chiuso il mese non verranno inclusi" />
          <CardRow>
            <DropdownButton
              as={ButtonGroup}
              variant="primary"
              title="Scarica documenti"
              className="p-0"
            >
              <Dropdown.Item
                onClick={() => {
                  generatePDF([], ['worklogs'], 'Presenze');
                  generateXLSX(
                    'Foglio presenze',
                    API.REPORTS_ATTENDANCE_SHEET
                  );
                }}
              >
                Presenze (PDF/XLSX)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  generateXLSX(
                    'Incongruenze',
                    API.REPORTS_ABSENCES_DISC
                  )
                }
              >
                Incongruenze (XLSX)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  generateXLSX(
                    'Ticket Restaurant',
                    API.REPORTS_TICKET_RESTAURANT
                  )
                }
              >
                Ticket Restaurant (XLSX)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  generatePDF([], ['expenses', 'trips'], 'Nota spese')
                }
              >
                Nota spese (PDF)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  generatePDF(
                    [],
                    ['expenses', 'trips'],
                    'Nota spese (per stampa)',
                    true
                  )
                }
              >
                Nota spese - stampa (PDF)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  generateXLSX(
                    'Riepilogo nota spese',
                    API.REPORTS_XLSX
                  )
                }
              >
                Riepilogo nota spese (XLSX)
              </Dropdown.Item>
            </DropdownButton>
          </CardRow>
          <Hr />
          <CardRow>
            <DropdownButton
              as={ButtonGroup}
              variant="primary"
              title="Cambia stato mese"
              className="p-0"
              disabled={!selectedFlatRows.length}
            >
              <Dropdown.Item onClick={() => lockReports(false)}>
                Apri
              </Dropdown.Item>
              <Dropdown.Item onClick={() => lockReports(true)}>
                Chiudi
              </Dropdown.Item>
            </DropdownButton>
          </CardRow>
          <CardRow>
            <Button
              variant="primary"
              onClick={() => setShowModal(true)}
              disabled={!selectedFlatRows.length}
            >
              Contatta
            </Button>
          </CardRow>
          <Hr />
          <CardRow>
            <Button variant="primary" onClick={() => setShowFiscalModal(true)}>
              Invia documenti fiscali
            </Button>
          </CardRow>
        </div>
      </div>
      <I3Modal
        show={showModal}
        onHide={handleHideMailModal}
        title="Contatta"
        className=""
      >
        <MailForm
          htmlEditor={true}
          users={getSelectedRows()}
          onSend={sendEmail}
          onClose={handleHideMailModal}
        />
      </I3Modal>
      <I3Modal
        show={showFiscalModal}
        onHide={handleHideFiscalModal}
        title="Invia documenti fiscali via mail"
        className=""
      >
        <SendFiscalDocumentsForm companyId={selectedCompany} close={handleHideFiscalModal} />
      </I3Modal>
    </>
  ) : (
    <Unauthorized />
  );
};

export default Administration;
