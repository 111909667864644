import PropTypes from 'prop-types';
import { decimalFormatter } from '../../components/Table/utility';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon  from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { memo } from 'react';
import TableCell from '@mui/material/TableCell';

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(nextProps) === JSON.stringify(prevProps);

export const AbsenceDayCell = memo(({
    data,    
    date,
    isReadOnly,
    isEmbeddedInWorklog,
    isWeekEnd,
    isHoliday,    
    isToday,
    onAddClick,
    onEditClick,
    onDeleteClick,
}) => {
  
  const isAbsence = data && data.id;

  const percentage = isAbsence ? 100 * (data.hours / 8) : 0;
  const style = {
    '--p': percentage,
    '--c': isAbsence ? 'var(--brand)' : 'transparent',
    'margin': 0,
  };

  const cnClasses = cn({
    weekend: isWeekEnd && !isHoliday,
    holiday: isHoliday,
    today: isToday,
    error: data?.isInconsistentWithWorklog,
    greyedOut: isEmbeddedInWorklog,
  });

  if (isEmbeddedInWorklog) {
    return (
      <TableCell
        className={cnClasses}
      >
        {data && decimalFormatter.format(data.hours)}        
      </TableCell>
    );
  }
  
  if (isAbsence) {
    return (
      <TableCell
        className={cnClasses}
      >
        <Tooltip 
          placement="top"
          arrow
          title={(
              <>
                <Box sx={{
                  color: 'var(--text-color)',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                  {`${decimalFormatter.format(data.hours)} ore`}
                </Box>
                {data.fromTime && (  
                <Box sx={{
                  color: 'var(--text-color)',
                  fontSize: '12px',
                  textAlign: 'center',
                }}>
                  {`dalle ${data.fromTime} alle ${data.toTime}`}
                </Box>  
                )}  
                <Box sx={{
                  textAlign: 'center',
                }}>
                {!isReadOnly && (  
                  <IconButton
                    onClick={() => {
                      onDeleteClick(data.id);
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                )}             
                  <IconButton
                    onClick={() => {
                      onEditClick(data);
                    }}
                  >
                    {!isReadOnly ? (
                      <EditOutlinedIcon />
                    ) : (
                      <InfoOutlinedIcon />
                    )}
                  </IconButton>             
                </Box>
              </>
            )}
        >
          <div className="pie" style={style}/>
        </Tooltip>           
      </TableCell>
    );
  }

  if (isReadOnly) {
    return (
      <TableCell
        className={cnClasses}
      />
    );
  }

  return (
    <TableCell
      className={cnClasses}
    >
      <div className="pie" style={style}
        role='button'
        onClick={() => onAddClick(date)}
      />
    </TableCell>
  );

}, arePropsEqual);

AbsenceDayCell.defaultProps = {
  data: undefined,
  date: "",
  isReadOnly: false,
  isEmbeddedInWorklog: false,
  isWeekEnd: false,
  isHoliday: false,  
  isToday: false,  
  onAddClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
};

AbsenceDayCell.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    hours: PropTypes.number,
    fromTime: PropTypes.string,
    toTime: PropTypes.string,
    isInconsistentWithWorklog: PropTypes.bool,    
  }),
  date: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isEmbeddedInWorklog: PropTypes.bool,
  isWeekEnd: PropTypes.bool,
  isHoliday: PropTypes.bool,
  isToday: PropTypes.bool,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

AbsenceDayCell.displayName = 'AbsenceDayCell';