/* eslint-disable no-undef */

// import { error, success } from '../../notification';

import { error, notifyResponseError, success } from '../notification';
import request from '../axios';
import { API } from '../../constants';
import _ from 'lodash';

export const getWorklogs = (momentDate, username) =>
  request
    .get(
      `${API.WORKLOGS}?username=${username}&year=${momentDate.format(
        'YYYY'
      )}&month=${momentDate.format('MM')}`
    )
    .then((res) => _.get(res, 'data.results', []));

export const deleteWorklogById = ({ id }) =>
  request
    .delete(`${API.WORKLOGS}${id}/`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });

export const updateWorklogDay = (data) =>
  request
    .put(`${API.WORKLOGS}${data.id}/`, data)
    .then(() => {
      success('Esito', 'Aggiornamento eseguito con successo');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const createWorklogDay = (data) =>
  request
    .post(API.WORKLOGS, data)
    .then(() => {
      success('Esito', 'Salvataggio riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });
