/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createWorklogDay, updateWorklogDay } from '../../utils/API/worklog';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import FormSelect from '../../components/Form/FormSelect';
import moment from 'moment';
import FormDatePicker from '../../components/Form/FormDatePicker';

const initialValues = {
  activity: '',
  description: '',
  dataDa: moment(),
  dataA: moment(),
  hours: 8,
  id: undefined,
  isPeriod: false,
  createAnother: false,
};

async function onSubmit(values, actions, close, onDataChanged) {
  const { setSubmitting, resetForm } = actions;
  const {
    activity,
    createAnother,
    dataA,
    dataDa,
    description,
    hours,
    id,
    isPeriod,
  } = values;
  const data = {
    time_spent: hours,
    description,
    issue: parseInt(activity, 10),
    date: isPeriod ? dataA.format('YYYY-MM-DD') : dataDa.format('YYYY-MM-DD'),
    from_date: isPeriod ? dataDa.format('YYYY-MM-DD') : '',
    id,
  };

  try {
    if (id) {
      await updateWorklogDay(data);
    } else {
      await createWorklogDay(data);
    }
  } finally {
    setSubmitting(false);
    onDataChanged();
    if (!createAnother) {
      close();
    } else {
      resetForm({
        values: {
          ...values,
          activity: '',
          description: '',
        },
      });
    }
  }
}

const validationSchema = Yup.object().shape({
  isPeriod: Yup.boolean(),
  createAnother: Yup.boolean(),
  activity: Yup.string().required('Campo obbligatorio'),
  description: Yup.string()
    .min(5, 'Lunghezza minima 5 caratteri')
    .max(50, 'Lunghezza massima 50 caratteri'),
  dataDa: Yup.date()
    .required('Campo obbligatorio')
    .test(
      'start_time_test',
      'Data non può essere maggiore di Data Fine',
      function check(value) {
        const { dataA, isPeriod } = this.parent;
        if (isPeriod) {
          if (dataA instanceof Date && !Number.isNaN(dataA.valueOf())) {
            if (Date.parse(value) > Date.parse(dataA)) {
              return false;
            }
          }
        }
        return true;
      }
    ),
  dataA: Yup.date().when('isPeriod', {
    is: true,
    then: Yup.date()
      .required('Campo obbligatorio')
      .min(Yup.ref('dataDa'), 'Data Fine non può essere minore di Data'),
  }),
  hours: Yup.number()
    .required('Campo obbligatorio')
    .positive("Valore non ammesso, dev'essere maggiore di 0"),
});

const getOptions = ({
  userProjects,
  userIssues,
  isFutureMonth
}) => {
  const html = [];
  userProjects.forEach(project => {
    html.push(
      <option
        disabled={true}
        value=''
        key={`key_project_${project.id}`}
      >
        {project.summary}
      </option>
    );
    const projectIssues = userIssues.filter(i => i.projectId === project.id);
    projectIssues.forEach((issue) => {
      html.push(
        <option
          disabled={isFutureMonth && !project.canHaveIssuesInFuture}
          value={issue.id}
          key={`key_issue_${issue.id}`}
        >
          {'\u00A0\u00A0\u00A0'}
          {issue.summary}
        </option>
      );
    });
  });

  return html;
};

const WorklogForm = (props) => {
  const { month, userProjects, userIssues, close, editFormData, onDataChanged } = props;

  const isFutureMonth = month
    .startOf('month')
    .isAfter(moment().startOf('month'));
  const warn = isFutureMonth
    ? 'Per i mesi successivi, si possono inserire ore solo su assenze programmate, non su attività lavorative'
    : null;

  if (editFormData) {
    initialValues.dataDa = editFormData.date ? moment(editFormData.date, 'YYYY-MM-DD') : moment();
    initialValues.description = editFormData.description;
    initialValues.activity = editFormData.issue;
    initialValues.hours = editFormData.time_spent;
    initialValues.id = editFormData.id;
    initialValues.isPeriod = false;
    initialValues.dataA = moment();
  }

  const hasId = !!initialValues?.id;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, onDataChanged);
      }}
      initialValues={initialValues}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        isValidating,
      }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormItem
            values={values}
            type="text"
            name="id"
            style={{ display: 'none' }}
            value={undefined}
          />
          {warn && (
            <div
              style={{ color: 'red', display: 'flex', alignItems: 'center' }}
            >
              <span style={{ marginRight: '10px' }}>
                <ExclamationTriangle />
              </span>
              <span>{warn}</span>
            </div>
          )}

          <FormSelect
            name="activity"
            label="Attività *"
            options={getOptions({userProjects, userIssues, isFutureMonth})}
            disabled={hasId}
          />
          <FormItem
            type="textarea"
            name="description"
            rows="1"
            label="Descrizione"
          />
          <FormDatePicker
            name="dataDa"
            label="Data *"
            minDate={month.clone().startOf('month')}
            maxDate={month.clone().endOf('month')}
            disabled={hasId}
          />
          {!hasId && (
            <>
              <FormCheckbox name="isPeriod" label="Periodo" />
              <FormDatePicker
                name="dataA"
                label="Data Fine *"                
                minDate={month.clone().startOf('month')}
                maxDate={month.clone().endOf('month')}
                disabled={!values.isPeriod}
              />
            </>
          )}
          <FormItem type="number" name="hours" label="Ore lavorate *" />
          <div className="buttonContent">
            {!hasId && (
              <FormCheckbox name="createAnother" label="Aggiungi un altro" />
            )}
            <div className="spacer" />
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !dirty || isValidating || !isValid}
            >
              {hasId ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default WorklogForm;
