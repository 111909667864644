import { API } from '../../constants';
import request from '../axios';
import { success, error } from '../notification';
import { getFormData } from '../formUtils';

export const getCertifications = async () => {
    try {
        const res = await request.get(API.CERTIFICATIONS);
        return res.data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}

export const getCertificationsEmitters = async () => {
    try {
        const res = await request.get(API.CERTIFICATIONS_EMITTERS);
        return res.data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}

export const getCertificationsCategories = async () => {
    try {
        const res = await request.get(API.CERTIFICATIONS_CATEGORIES);
        return res.data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}

export const createCertification = async (data) => {
    const formData = getFormData(data);   
    try {
        const res = await request.post(API.CERTIFICATIONS, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        success('Esito', 'Inserimento riuscito');
        return res.data;
    } catch (e) {
        error('Errore', 'Inserimento fallito');
        throw e;
    }
}

export const updateCertification = async (id, data) => {
    const formData = getFormData(data);   
    try {
        const res = await request.put(`${API.CERTIFICATIONS}${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        success('Esito', 'Aggiornamento riuscito');
        return res.data;
    } catch (e) {
        error('Errore', 'Aggiornamento fallito');
        throw e;
    }
}

export const deleteCertification = async (id) => {
    try {
        await request.delete(`${API.CERTIFICATIONS}${id}/`);
        success('Esito', 'Cancellazione avvenuta con successo');
    } catch (e) {
        error('Errore', 'Cancellazione fallita');
        throw e;
    }
}