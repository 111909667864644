import cn from 'classnames';
import { Button, Col } from 'react-bootstrap';
import { CalendarPlusFill, CartPlusFill } from 'react-bootstrap-icons';
import { UserContext } from '../../context/UserContext';
import {
  EXPENSE_BONUS_BOOKS,
  EXPENSE_BONUS_SMART_WORKING,
  PERMISSIONS,
} from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useContext, useEffect, useMemo, useState } from 'react';
import I3Modal from '../../components/Layout/I3Modal';
import { BudgetForm } from './BudgetForm';
import { DaysForm } from './DaysForm';
import {
  getTrainingDaysAdmin,
  getTrainingExpensesAdmin,
} from '../../utils/API/budgets';

import { UserListContext } from '../../context/UserListContext';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { TabTitle } from './TabTitle';
import { getExpensesByTypeAndYearAdmin } from '../../utils/API/expenses';
import { getCompanies } from '../../utils/API/companies';
import { AdminBonusExpensesDataGrid } from './AdminBonusExpensesDataGrid';
import { AdminTrainingDaysDataGrid } from './AdminTrainingDaysDataGrid';
import { BudgetContext } from '../../context/BudgetContext';
import { renderSpinner } from '../../utils/Utility';
import { AdminTrainingExpensesDataGrid } from './AdminTrainingExpensesDataGrid';
import { decimalFormatter } from '../../components/Table/utility';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const noVisibleModals = {
  trainingDays: false,
  trainingBudget: false,
};

export const AdminBudgetPage = () => {
  const { t } = useTranslation();
  const { hasClaim } = useContext(UserContext);
  const [selectedTabKey, setSelectedTabKey] = useState('training_budget');

  const { year, setYear, configuration, availableYears } = useContext(BudgetContext);
  const [isLoading, setIsLoading] = useState(false);

  const [showInsertModal, setShowInsertModal] = useState(noVisibleModals);

  const [trainingDays, setTrainingDays] = useState([]);
  const [trainingExpenses, setTrainingExpenses] = useState([]);
  const [currentTrainingDay, setCurrentTrainingDay] = useState();
  const [currentTrainingExpense, setCurrentTrainingExpense] = useState();
  const [bookExpenses, setBookExpenses] = useState([]);
  const [smartExpenses, setSmartExpenses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(1);
  const [budgetsConfiguration, setBudgetsConfiguration] = useState({
    trainingExpenses: 0,
    trainingDays: 0,
    books: 0,
    smartWorking: 0,
  });

  const { users, usersById } = useContext(UserListContext);
  const [userIds, setUserIds] = useState([]);
  const usernames = useMemo(
    () => userIds.map((id) => usersById[id]?.username).filter((u) => u),
    [userIds, usersById]
  );
  const [companyUsers, setCompanyUsers] = useState([]);

  const handleShowInsertOrUpdateModal = (key, visible) =>
    setShowInsertModal({
      ...noVisibleModals,
      [key]: visible ?? !showInsertModal[key],
    });
  const closeModal = () => {
    setShowInsertModal({
      ...noVisibleModals,
    });
    setCurrentTrainingDay(undefined);
    setCurrentTrainingExpense(undefined);
  };

  useEffect(() => {
    const getData = async () => {      
      setCompanies(await getCompanies());
    }    
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {      
      setTrainingDays(await getTrainingDaysAdmin(selectedCompany, year, usernames));
      setTrainingExpenses(await getTrainingExpensesAdmin(selectedCompany, year, usernames));
      setBookExpenses(await getExpensesByTypeAndYearAdmin(selectedCompany, EXPENSE_BONUS_BOOKS, year, usernames));
      setSmartExpenses(await getExpensesByTypeAndYearAdmin(selectedCompany, EXPENSE_BONUS_SMART_WORKING, year, usernames));
    }    
    getData().finally(() => setIsLoading(false));
  }, [usernames, year]);

  useEffect(() => {
    setUserIds([]);
    setCompanyUsers(users.filter(i => i.company === selectedCompany));
    
    setBudgetsConfiguration({
      trainingExpenses: configuration.trainingExpenseBudget.find(i => i.company === selectedCompany)?.limit ?? 0,
      trainingDays: configuration.trainingDaysBudget.find(i => i.company === selectedCompany)?.limit ?? 0,
      books: configuration.booksBudget.find(i => i.company === selectedCompany)?.limit ?? 0,
      smartWorking: configuration.smartWorkingBudget.find(i => i.company === selectedCompany)?.limit ?? 0,
    });

  }, [selectedCompany]);

  if (!hasClaim(PERMISSIONS.BUDGET_ADMINISTRATION)) {
    return (
      <Unauthorized />
    );
  }
  return (
    <div className="content__wrapper">
      <div className="headerPage content">
        <div className="title">
          Amministrazione &mdash; Budget formazione e altri bonus
        </div>
        <div className="spacer" />

        <div className="d-flex">
          <div className='form-group'>
            <select
              className="yearContainer"
              onChange={({ target: { value } }) => setYear(+value)}
              defaultValue={year}
            >
              {availableYears.map((n) => (
                <option value={+n} key={+n}>
                  {n}
                </option>
              ))}
            </select>
          </div>
          <div className="buttonGroup">
            { companies.length > 0 && (
              <TextField
                sx={{ width: 200 }} 
                select
                fullWidth
                size="small"
                label="Azienda"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {companies.map((i) => (
                  <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                ))}
              </TextField>
            )}
            <MultiSelectUser
              users={companyUsers}
              selected={userIds}
              setSelected={setUserIds}
            />
            <Button
              variant="primary"
              onClick={() => handleShowInsertOrUpdateModal('trainingBudget')}
            >
              <CartPlusFill />
              Inserisci spese
            </Button>
            <Button
              variant="primary"
              onClick={() => handleShowInsertOrUpdateModal('trainingDays')}
            >
              <CalendarPlusFill />
              Inserisci giorni
            </Button>
          </div>
        </div>
      </div>
      <div className="content__overflow">
        <div className="tabsContainer">
          {isLoading ? (
            renderSpinner(true)
          ) : (
          <Col md="12">
            <Tabs 
              className="mb-3 tabs-chart"
              activeKey={selectedTabKey}
              onSelect={(k) => setSelectedTabKey(k)}
              justify
              mountOnEnter
            >
              <Tab
                eventKey="training_budget"
                title={
                  <TabTitle
                    title="Budget formazione"
                    maxAvailable={budgetsConfiguration.trainingExpenses}
                  />
                }
              >
                <AdminTrainingExpensesDataGrid
                  expenses={trainingExpenses}
                  budget={budgetsConfiguration.trainingExpenses}
                  currentTrainingExpense={currentTrainingExpense}
                  setCurrentTrainingExpense={setCurrentTrainingExpense}
                  handleShowInsertOrUpdateModal={handleShowInsertOrUpdateModal}
                  updateData={() => setUserIds([...userIds])}
                  year={year}
                />
              </Tab>
              <Tab
                eventKey="training_days"
                title={
                  <TabTitle
                    title="Giorni di formazione"
                    unit="giorni"
                    maxAvailable={budgetsConfiguration.trainingDays}
                  />
                }
              >
                <AdminTrainingDaysDataGrid
                  days={trainingDays}
                  budget={budgetsConfiguration.trainingDays}
                  currentTrainingDay={currentTrainingDay}
                  setCurrentTrainingDay={setCurrentTrainingDay}
                  handleShowInsertOrUpdateModal={handleShowInsertOrUpdateModal}
                  updateData={() => setUserIds([...userIds])}
                  year={year}
                />
              </Tab>
              <Tab
                eventKey="bonus"
                title={
                  <div className="tab-title">
                    {t('bonus_books')} -{' '}
                    {decimalFormatter.format(budgetsConfiguration.books)}
                    &nbsp;€ │ {t('bonus_smart_working')} -{' '}
                    {decimalFormatter.format(budgetsConfiguration.smartWorking)}
                    &nbsp;€
                  </div>
                }
              >
                <AdminBonusExpensesDataGrid
                  bookExpenses={bookExpenses}
                  smartWorkingExpenses={smartExpenses}
                  year={year}
                />
              </Tab>
            </Tabs>
          </Col>
          )}
        </div>
      </div>
      <I3Modal
        show={showInsertModal.trainingDays || showInsertModal.trainingBudget}
        onHide={closeModal}
        title={`${currentTrainingDay ? 'Aggiorna' : 'Inserisci'}
         ${showInsertModal.trainingDays ? 'giorni' : 'spese'}  di formazione`}
        className={cn({
          'I3Modal-insert-training-expenses': showInsertModal.trainingBudget,
        })}
      >
        {showInsertModal.trainingDays && (
          <DaysForm
            close={closeModal}
            currentData={currentTrainingDay}
            updateData={() => setUserIds([...userIds])}
            company={selectedCompany}
          />
        )}
        {showInsertModal.trainingBudget && (
          <BudgetForm
            close={closeModal}
            currentData={currentTrainingExpense}
            updateData={() => setUserIds([...userIds])}
            usersById={usersById}
            company={selectedCompany}
          />
        )}
      </I3Modal>
    </div>
  );
};
