import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import './index.css';
import './css/I3Portal.scss';
import './i18n/i18n';

import { Suspense, StrictMode } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from './keycloak';
import App from './App';
import ReactDOM from 'react-dom';
import { ReactNotifications } from 'react-notifications-component';
import { UserContextProvider } from './context/UserContext';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <Suspense fallback="">
      <ReactKeycloakProvider authClient={keycloak}>
        <ReactNotifications />
        <UserContextProvider>        
            <App />
        </UserContextProvider>
      </ReactKeycloakProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
