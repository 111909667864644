import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { EarnedCertificationsDataGrid } from './EarnedCertificationsDataGrid';
import { EarnedCertificationForm } from './EarnedCertificationForm';
import I3Modal from '../../components/Layout/I3Modal';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { deleteEarnedCertification, getEarnedCertifications } from '../../utils/API/earnedCertifications';
import { getUsers } from '../../utils/API/users';
import { getCertifications, getCertificationsEmitters, getCertificationsCategories } from '../../utils/API/certifications';
import { renderSpinner } from '../../utils/Utility';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { USERS_PARAM_NAME, PERMISSIONS, EARNEDCERTIFICATIONS_GROUP_BY } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

export const EarnedCertificationsPage = () => {
  const { hasClaim } = useContext(UserContext);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedEarnedCertification, setSelectedEarnedCertification] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [earnedCertifications, setEarnedCertifications] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [users, setUsers] = useState([]);
  const [certificationsEmitters, setCertificationsEmitters] = useState([]);
  const [certificationsCategories, setCertificationsCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [selectedAchievementDateStart, setSelectedAchievementDateStart] = useState(null);
  const [selectedAchievementDateEnd, setSelectedAchievementDateEnd] = useState(null);
  const [selectedExpirationDateStart, setSelectedExpirationDateStart] = useState(null);
  const [selectedExpirationDateEnd, setSelectedExpirationDateEnd] = useState(null);
  const [selectedCertificationEmitters, setSelectedCertificationEmitters] = useState([]);
  const [selectedCertificationCategories, setSelectedCertificationCategories] = useState([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  if (!hasClaim(PERMISSIONS.VIEW_EARNED_CERTIFICATIONS)) {
    return (
      <Unauthorized />
    );
  }
  
  const isReadOnly = !hasClaim(PERMISSIONS.CERTIFICATION_ADMINISTRATION);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {      
      setEarnedCertifications(await getEarnedCertifications());
      setCertifications(await getCertifications());
      setUsers(await getUsers());
      setCertificationsEmitters(await getCertificationsEmitters());
      setCertificationsCategories(await getCertificationsCategories());      
    }    
    getData().finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const selectedUsersFromUrl = searchParams
      .getAll(USERS_PARAM_NAME)
      .map((id) => Number(id));
    setSelectedUsers(selectedUsersFromUrl);
  }, [searchParams]);

  const handleOnDataChanged = (item) => {
    const earnedCertification = earnedCertifications.find(i => i.id === item.id);
    if (!earnedCertification) {
      setEarnedCertifications([...earnedCertifications, item]);
    } else {
      setEarnedCertifications(earnedCertifications.map(i => i.id === item.id ? item : i));
    }
  }

  const handleAddClick = () => {
    setSelectedEarnedCertification(undefined);
    setShowUpsertModal(true);
  };
  
  const handleEditClick = (id) => {
    const earnedCertification = earnedCertifications.find(i => i.id === id);
    setSelectedEarnedCertification(earnedCertification);
    setShowUpsertModal(true);
  };
  
  const handleDeleteClick = (id) => {
    const earnedCertification = earnedCertifications.find(i => i.id === id);
    setSelectedEarnedCertification(earnedCertification);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    try {
      deleteEarnedCertification(selectedEarnedCertification.id).then(res => {
        setEarnedCertifications(earnedCertifications.filter(i => i.id !== selectedEarnedCertification.id));
      });
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  return (
    <>
        <div className="content__wrapper">
            <div className="headerPage content">
                <div className="title">Certificazioni ottenute</div>
                <div className="spacer" /> 
                { !isReadOnly && (
                <div className="buttonGroup">
                    <Button variant="primary" onClick={() => handleAddClick()}>
                        <PlusCircle />
                        Inserisci
                    </Button>                  
                </div>
                )}
            </div>
            <div style={{ display: "flex", flexFlow: "column", height: "calc(100% - 72px)" }}>
              <div className="tabsContainer" style={{ flex: "none" }}>
                <Box sx={{ p:1 }}>Filtri e raggruppamento</Box>
                <Grid container rowSpacing={2} columnSpacing={1} sx={{ p:1 }}>
                  <Grid item xs={1.5}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Utenti"
                      value={selectedUsers}
                      onChange={(e) => {
                        let selected = e.target.value;
                        if (selected.includes(0)) {
                          selected = [];
                        }                          
                        setSearchParams({ [USERS_PARAM_NAME]: selected });
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        multiple: true,
                        renderValue: selected => {
                          const selectedTexts = users.filter(i => selected.includes(i.id)).map(i => i.username.toUpperCase());
                          return selectedTexts.join(', ');
                        }
                      }}
                    >
                      <MenuItem value={0}>
                        Tutti
                      </MenuItem>
                      {users.map((i) => (
                        <MenuItem key={i.id} value={i.id}>{i.username.toUpperCase()} - {i.fullName}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Certificazioni"
                      value={selectedCertifications}
                      onChange={(e) => {
                        const selected = e.target.value;
                        if (selected.includes(0)) {
                          setSelectedCertifications([]);
                        } else {
                          setSelectedCertifications(selected);
                        }                            
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      <MenuItem value={0}>
                        Tutte
                      </MenuItem>
                      {certifications.map((i) => (
                        <MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Enti"
                      value={selectedCertificationEmitters}
                      onChange={(e) => {
                        const selected = e.target.value;
                        if (selected.includes(0)) {
                          setSelectedCertificationEmitters([]);
                        } else {
                          setSelectedCertificationEmitters(selected);
                        }                            
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      <MenuItem value={0}>
                        Tutti
                      </MenuItem>
                      {certificationsEmitters.map((i) => (
                        <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Categorie"
                      value={selectedCertificationCategories}
                      onChange={(e) => {
                        const selected = e.target.value;
                        if (selected.includes(0)) {
                          setSelectedCertificationCategories([]);
                        } else {
                          setSelectedCertificationCategories(selected);
                        }                            
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      <MenuItem value={0}>
                        Tutte
                      </MenuItem>
                      {certificationsCategories.map((i) => (
                        <MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1.2}>
                    <DatePicker 
                      label="Data acquisizione (dal)"
                      value={selectedAchievementDateStart}
                      onChange={(newValue, context) => {
                        if (context.validationError === null) {
                          setSelectedAchievementDateStart(newValue)
                        }
                      }}
                      slotProps={{
                        field: { clearable: true, size: 'small', fullWidth: true },
                        textField: { InputLabelProps: { shrink: true } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <DatePicker 
                      label="Data acquisizione (al)"
                      value={selectedAchievementDateEnd}
                      onChange={(newValue, context) => {
                        if (context.validationError === null) {
                          setSelectedAchievementDateEnd(newValue)
                        }
                      }}
                      slotProps={{
                        field: { clearable: true, size: 'small', fullWidth: true },
                        textField: { InputLabelProps: { shrink: true } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <DatePicker
                      label="Data scadenza (dal)"
                      value={selectedExpirationDateStart}
                      onChange={(newValue, context) => {
                        if (context.validationError === null) {
                          setSelectedExpirationDateStart(newValue)
                        }
                      }}
                      slotProps={{
                        field: { clearable: true, size: 'small', fullWidth: true },
                        textField: { InputLabelProps: { shrink: true } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <DatePicker
                      label="Data scadenza (al)"
                      value={selectedExpirationDateEnd}
                      onChange={(newValue, context) => {
                        if (context.validationError === null) {
                          setSelectedExpirationDateEnd(newValue)
                        }
                      }}
                      slotProps={{
                        field: { clearable: true, size: 'small', fullWidth: true },
                        textField: { InputLabelProps: { shrink: true } },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Raggruppamento"
                      value={selectedGroupBy}
                      onChange={(e) => setSelectedGroupBy(e.target.value)}
                    >
                      <MenuItem value={EARNEDCERTIFICATIONS_GROUP_BY.NONE}>
                        Nessuno
                      </MenuItem>
                      <MenuItem value={EARNEDCERTIFICATIONS_GROUP_BY.CERTIFICATION}>
                        Certificazione
                      </MenuItem>
                    </TextField>                        
                  </Grid>
                </Grid>
              </div>
              <div className="tabsContainer" style={{ marginTop: "0px", flex:"1 1 0" }}>
                {isLoading ? (
                    renderSpinner(true)
                ) : (
                <EarnedCertificationsDataGrid
                    data={earnedCertifications}
                    isReadOnly={isReadOnly}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    filterUsers={selectedUsers}
                    filterCertifications={selectedCertifications}
                    filterCertificationCategories={selectedCertificationCategories}
                    filterCertificationEmitters={selectedCertificationEmitters}
                    filterAchievementDateStart={selectedAchievementDateStart}
                    filterAchievementDateEnd={selectedAchievementDateEnd}
                    filterExpirationDateStart={selectedExpirationDateStart}
                    filterExpirationDateEnd={selectedExpirationDateEnd}
                    groupBy={selectedGroupBy}
                />
                )}
                </div>
            </div>
        </div>
        <I3Modal
            show={showUpsertModal}
            onHide={() => setShowUpsertModal(false)}
            title={selectedEarnedCertification ? "Modifica certificazione ottenuta" : "Inserisci certificazione ottenuta"}
        >
            <EarnedCertificationForm
                earnedCertification={selectedEarnedCertification}
                certifications={certifications}
                users={users}
                onDataChanged={handleOnDataChanged}
                onClose={() => setShowUpsertModal(false)}                
            />
        </I3Modal>
        <I3ModalConfirmation
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
          title="Cancella certificazione"
          questionTitle="Vuoi cancellare questa certificazione?"
          onConfirm={handleConfirmDeleteClick}
        />
    </>
  );
};
