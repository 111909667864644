/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import { createRetrospectiveGoalType, updateRetrospectiveGoalType } from "../../utils/API/retrospectiveGoalTypes";

const initialValues = (currentData) => ({
    id: currentData?.id || '',
    name: currentData?.name || '',
    easy: currentData?.easy || '',
    hard: currentData?.hard || '',
});

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obbligatorio').max(250, 'Lunghezza massima 250 caratteri'),
    easy: Yup.string(),
    hard: Yup.string(),
});

export const RetrospectiveGoalTypeForm = ({
                                 item,
                                 onDataChanged,
                                 onClose,
                             }) => {
    const isNewItem = item?.id === undefined;

    const onSubmit = async (values, actions) => {
        const { resetForm, setSubmitting, setFieldValue } = actions;
        const { id, name, easy, hard, createAnother } = values;
        const payload = { name, easy, hard };

        let data;
        try {
            if (id) {
                data = await updateRetrospectiveGoalType(id, payload);
            } else {
                data = await createRetrospectiveGoalType(payload);
            }
            onDataChanged(data);
            if (!createAnother) {
                onClose();
            } else {
                resetForm();
                setFieldValue('createAnother', createAnother);
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues(item)}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  isValidating,
              }) => (
                <FormikForm noValidate onSubmit={handleSubmit}>
                    <FormItem
                        type="text"
                        name="name"
                        label="Nome"
                        maxLength="250"
                        className="form-group"
                    />
                    <FormItem
                        type="textarea"
                        name="easy"
                        label="Facile"
                        rows="2"
                        className="form-group"
                    />
                    <FormItem
                        type="textarea"
                        name="hard"
                        label="Difficile"
                        rows="2"
                        className="form-group"
                    />
                    <div className="buttonContent">
                        {isNewItem && (
                            <FormCheckbox
                                name="createAnother"
                                label="Aggiungi un altro"
                            />
                        )}
                        <div className="spacer"/>
                        <Button
                            onClick={onClose}
                            variant='secondary'
                        >
                            Annulla
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting || isValidating || !isValid}
                        >
                            {isNewItem ? 'Inserisci' : 'Aggiorna'}
                        </Button>
                    </div>
                </FormikForm>
            )}
        </Formik>
    );
};
