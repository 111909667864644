import request from '../axios';
import { API } from '../../constants';
import { notifyResponseError, success } from '../notification';
import { getFormData } from '../formUtils';
import { getUserParams } from './paramUtil';

export const getTrainingDays = (year, username = '') =>
  request
    .get(`${API.TRAINING_DAYS}?year=${year}&username=${username}`)
    .then((res) => res?.data ?? []);

export const getTrainingExpenses = (year, username = '') =>
  request
    .get(`${API.TRAINING_EXPENSES}?year=${year}&username=${username}`)
    .then((res) => res?.data ?? []);

export const getTrainingDaysAdmin = (company, year, usernames = []) =>
  request
    .get(`${API.TRAINING_DAYS_ADMIN}?company=${company}&year=${year}&${getUserParams(usernames)}`)
    .then((res) => res?.data?.training_day ?? []);

export const getTrainingExpensesAdmin = (company, year, usernames = []) =>
  request
    .get(
      `${API.TRAINING_EXPENSES_ADMIN}?company=${company}&year=${year}&${getUserParams(usernames)}`
    )
    .then((res) => res?.data?.training_budget ?? []);

export const addTrainingDay = (values, participants, speakers) => {
  const { useDayOff, ...rest } = values;
  return request
      .post(API.TRAINING_DAYS, {
        ...rest,
        members: participants,
        speaker_or_organizer: speakers,
        use_day_off: values.useDayOff,
      });
}
  
export const updateTrainingDay = (values, participants, speakers) => {
  const { useDayOff, ...rest } = values;
  return request
      .put(`${API.TRAINING_DAYS}${values.id}/`, {
        ...rest,
        members: participants,
        speaker_or_organizer: speakers,
        use_day_off: values.useDayOff,
      });
}

export const deleteTrainingDay = (id) =>
  request
    .delete(`${API.TRAINING_DAYS}${id}/`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const updateTrainingExpense = (values, participants, speakers) =>
  request
    .put(
      `${API.TRAINING_EXPENSES}${values.id}/`,
      getFormData({
        ...values,
        members: participants,
        speaker_or_organizer: speakers,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(() => {
      success('Esito', 'Aggiornamento riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const deleteTrainingExpense = (id) =>
  request
    .delete(`${API.TRAINING_EXPENSES}${id}/`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const addTrainingExpense = (values, participants, speakers) =>
  request
    .post(
      API.TRAINING_EXPENSES,
      getFormData({
        ...values,
        members: participants,
        speaker_or_organizer: speakers,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(() => {
      success('Esito', 'Inserimento riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });
