/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { useState } from 'react';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import DropFileZone from '../../components/Form/DropFileZone';
import { createEarnedCertification, updateEarnedCertification } from '../../utils/API/earnedCertifications';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormDatePicker from '../../components/Form/FormDatePicker';

const initialValues = (currentData) => ({
  id: currentData?.id,
  certificationId: currentData?.certificationId,
  userId: currentData?.userId,
  attachment: currentData?.attachment,
  achievementDate: currentData?.achievementDate ? moment(currentData?.achievementDate) : moment(),
  expirationDate: currentData?.expirationDate ? moment(currentData?.expirationDate) : undefined,
  isPublishable: currentData?.isPublishable || true,
  createAnother: false,
});

async function onSubmit(values, actions, onClose, onDataChanged, setExecuteRemoveSelectedFiles) {
  const { setSubmitting, resetForm } = actions;
  const { id, createAnother, certificationId, userId, achievementDate, expirationDate, isPublishable, attachment, } = values;

  const data = {
    certificationId,
    userId,
    achievementDate: achievementDate.format('YYYY-MM-DD'),
    expirationDate: expirationDate && expirationDate.isValid() ? expirationDate.format('YYYY-MM-DD') : '',
    isPublishable,
    attachment,
  }

  let item;
  try {
    if (id) {
      item = await updateEarnedCertification(id, data);
    } else {
      item = await createEarnedCertification(data);
    }
    onDataChanged(item);
    if (!createAnother) {
      onClose();
    } else {
      resetForm();
      setExecuteRemoveSelectedFiles(true);
    }
  } finally {
    setSubmitting(false);    
  }
}

const validationSchema = Yup.object().shape({
  certificationId: Yup.number().required('Campo obbligatorio'),
  userId: Yup.number().required('Campo obbligatorio'),
  achievementDate: Yup.date().required('Campo obbligatorio')
    .test('achievementDateTest', 'Data acquisizione non valida', (value) => {
      if (moment(value, 'YYYY-MM-DD').isAfter(moment(), 'day')) {
        return false;
      }
      return true;
  }),
  expirationDate: Yup.date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    .test('expirationDateTest', 'Data scadenza non valida', (value, context) => {
      const { achievementDate } = context.parent;
      if (moment(value, 'YYYY-MM-DD').isSameOrBefore(moment(achievementDate), 'day')) {
        return false;
      }
      return true;
    }),
});

export const EarnedCertificationForm = ({
  earnedCertification,
  certifications,
  users,
  onClose,
  onDataChanged
}) => {
  const certificationsSortedByEmitter = certifications.sort((a, b) => a.emitterName.localeCompare(b.emitterName) || a.description.localeCompare(b.description));

  const [executeRemoveSelectedFiles, setExecuteRemoveSelectedFiles] = useState(false);
  
  const isNew = earnedCertification?.id === undefined;
  
  const attachments = [];
  if (earnedCertification && earnedCertification.attachment) {
    const fileData = { name: earnedCertification.attachment.substring(earnedCertification.attachment.lastIndexOf('/') + 1) };
    attachments.push({ fileData, deleted: false });
  }

  const isSelectedCertificationPublishable = (selectedCertificationId) => {
    const selectedCertification = certifications.find(i => i.id === Number(selectedCertificationId));
    if (!selectedCertification) {
      return true;
    }
    return selectedCertification.isPublishable;
  }
  
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, onClose, onDataChanged, setExecuteRemoveSelectedFiles);
      }}
      initialValues={initialValues(earnedCertification)}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
        setFieldValue,
      }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <Autocomplete
            name="certificationId"
            options={certificationsSortedByEmitter.map(i => i.id)}
            getOptionLabel={(option) => {
              const certification = certifications.find(i => i.id === option);
              return `${certification.emitterName} - ${certification.description}`;
            }}
            value={values.certificationId}
            onChange={async (event, newValue) => {
              const selectedCertification = certifications.find(i => i.id === newValue);
              setFieldValue("certificationId", selectedCertification?.id ?? null);
            }}
            renderInput={(params) => <TextField {...params} label="Certificazione *" size="small" sx={{ my: 1 }} />}
          />
          <Autocomplete
            name="userId"
            options={users.map(i => i.id)}
            getOptionLabel={(option) => {
              const user = users.find(i => i.id === option);
              return `${user.username.toUpperCase()} - ${user.fullName}`;
            }}
            value={values.userId}
            onChange={async (event, newValue) => {
              const selectedUser = users.find(i => i.id === newValue);
              setFieldValue("userId", selectedUser?.id ?? null);
            }}
            renderInput={(params) => <TextField {...params} label="Utente *" size="small" sx={{ my: 1 }} />}
          />
          <FormDatePicker
            name="achievementDate"
            label="Data acquisizione *"
          />
          <FormDatePicker
            name="expirationDate"
            label="Data scadenza"
          />
          <FormCheckbox
            name="isPublishable"
            label="Pubblicabile"
            disabled={!isSelectedCertificationPublishable(values.certificationId)}
          />
          <DropFileZone
            name="attachment"
            fileTypes=".pdf, .png"
            text="Trascina il file oppure fai click per selezionare il file da allegare alla spesa"
            setFieldValue={setFieldValue}
            attachments={attachments}
            executeRemoveSelectedFiles={executeRemoveSelectedFiles}
            setExecuteRemoveSelectedFiles={setExecuteRemoveSelectedFiles}
          />
          {!isNew && (
            <>
              <div className="inline-fields">
                <FormItem
                  type="datetime"
                  name="createdAt"
                  label="Data/ora inserimento"
                  disabled={true}
                />
                <FormItem
                  type="datetime"
                  name="updatedAt"
                  label="Data/ora aggiornamento"
                  disabled={true}
                />
              </div>
            </>
          )}
          <div className="buttonContent">
            {isNew && (
              <FormCheckbox
                name="createAnother"
                label="Aggiungi un'altra"
              />
            )}
            <div className="spacer" />
            <Button
              onClick={onClose}
              variant='secondary'
            >
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !isValid}
            >
              {isNew ? 'Inserisci' : 'Aggiorna'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
