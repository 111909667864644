/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormError, FormDatePicker as FormDatePickerOptions } from '.';

const FormDatePicker = (props) => {
  const { label, name } = props;

  return (
    <Form.Group controlId={`form${name}`} className="form-group">
      <Form.Label>{label}</Form.Label>
      <FormDatePickerOptions {...props} />
      <FormError {...props} />
    </Form.Group>
  );
};

FormDatePicker.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  minDate: undefined,
  maxDate: undefined,
};

FormDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

export default FormDatePicker;
