import { Button } from 'react-bootstrap';
import { useContext, useEffect, useState, useRef } from 'react';
import { deleteWorklogById, getWorklogs } from '../../utils/API/worklog';
import { getReport } from '../../utils/API/reports';
import ConfirmForm from '../confirmForm';
import { UserContext } from '../../context/UserContext';
import { MonthNavHeader } from '../../components/Layout/MonthNavHeader';
import I3Modal from '../../components/Layout/I3Modal';
import WorklogTable from './WorklogTable';
import WorklogForm from './WorklogForm';
import PanelTotal from '../../components/PanelTotal/panelTotal';
import { getCalendarWorklogsHeader } from '../../utils/API/calendar';
import { extractSummaryData, renderSpinner, prepareDaysColumnHeader } from '../../utils/Utility';
import { MonthContext } from '../../context/MonthContext';
import { Unauthorized } from '../../components/Unauthorized';
import { useKeyPress } from '../../hooks/useKeyPress';
import { fetchAbsences } from '../../utils/API/absences';
import AbsencesTable from '../Absences/AbsencesTable';
import { UserListContext } from '../../context/UserListContext';
import { MonoSelectUser } from '../../components/Form/MonoSelectUser';
import { PERMISSIONS } from '../../constants';
import { getUserIssues } from '../../utils/API/issues';
import { getUserProjects } from '../../utils/API/projects';

export const WorklogPage = () => {
  const { user: userByContext, hasClaim } = useContext(UserContext);
  const { users } = useContext(UserListContext);
  const { month } = useContext(MonthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [dayHeaders, setDayHeaders] = useState([]);
  const [worklogs, setWorklogs] = useState([]);
  const [user, setUser] = useState(userByContext);
  const [userIssues, setUserIssues] = useState([]);
  const [userProjects, setUserProjects] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalDeleteConfirmOptions, setShowModalDeleteConfirmOptions] =
    useState({ show: false, user });
  const [report, setReport] = useState({});
  const [summary, setSummary] = useState({});
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [editFormData, setEditFormData] = useState();
  
  const handleToggleModal = () => setShowModal(!showModal);
  const handleShowModalConfirm = () => setShowModalConfirm(true);
  const handleHideModalConfirm = () => setShowModalConfirm(false);

  const [absences, setAbsences] = useState([]);

  const { is_employee: isEmployee } = user;
  
  const isReadOnly = report.confirmed || !report.id || userByContext.id !== user.id;

  const handleHideDeleteConfirmModal = () => {
    setShowModalDeleteConfirmOptions({
      ...showModalDeleteConfirmOptions,
      show: !showModalDeleteConfirmOptions.show,
    });
  };

  const handleDeleteConfirmModal = () => {
    setShowModalDeleteConfirmOptions({
      ...showModalDeleteConfirmOptions,
      show: !showModalDeleteConfirmOptions.show,
    });
    const { id } = showModalDeleteConfirmOptions;

    deleteWorklogById({ id }).then(() => {
      handleHideDeleteConfirmModal();
      loadData();
    });
  };
  
  const loadData = () => {
    setIsLoading(true);
    getReport(
      month.format('YYYY'),
      month.format('MM'),
      user.username
    )
    .then(reports => {
      const expenseReport = reports.find(i => i.type === 'expenses');
      setReport(expenseReport);
      const summaryData = extractSummaryData(reports);
      setSummary(summaryData);
    })
    .then(() => getCalendarWorklogsHeader(month))
    .then(calendarWorklogsHeader => {
      const res = prepareDaysColumnHeader({
        month,
        calendarWorklogsHeader,
      });
      setDayHeaders(res);
    })
    .then(() => fetchAbsences(month.format('YYYY'), month.format('MM'), [user.username]))
    .then(res => setAbsences(res))
    .then(() => getWorklogs(month, user.username))
    .then(res => setWorklogs(res))
    .finally(() => setIsLoading(false));
  }

  useEffect(loadData, [month, user]);

  useEffect(() => {
    const getData = async () => {
      const projects = await getUserProjects();
      const issues = await getUserIssues();
      
      projects.sort((a, b) => a.summary.localeCompare(b.summary));
      issues.sort((a, b) => a.summary.localeCompare(b.summary));

      setUserProjects(projects);
      setUserIssues(issues);
    }
    
    getData().catch(console.error);
  }, []);

  const onInsert = () => {
    if (isReadOnly) {
      return;
    }
    setEditFormData({
      issue: '',
      description: '',
      date: '',
      time_spent: 8,
      id: null,
      report: null,
    });
    handleToggleModal();
  };

  const userChange = (userId) => {
    const selectedUser = users.find(i => i.id === userId);
    setUser(selectedUser !== undefined ? selectedUser : userByContext);
  }

  useKeyPress('F9', onInsert);
  useKeyPress('Escape', () => {
    setShowModal(false);
    setShowModalConfirm(false);
  });

  const handleDeleteClick = (worklogId) => {
    setShowModalDeleteConfirmOptions({
      ...showModalDeleteConfirmOptions,
      show: true,
      id: worklogId,
    });
  };

  const handleEditClick = (worklog) => {
    setEditFormData(worklog);
    setShowModal(true);
  };

  const worklogTableRef = useRef(null);
  const absencesTableRef = useRef(null);
  
  const onWorklogTableScroll = () => {
    absencesTableRef.current.scrollLeft = worklogTableRef.current.scrollLeft;
  };

  const onAbsencesTableScroll = () => {
    worklogTableRef.current.scrollLeft = absencesTableRef.current.scrollLeft;
  };

  if (!isEmployee && !hasClaim(PERMISSIONS.ADMINISTRATION)) {
    return <Unauthorized />;
  }

  return <>
      <div className="content__wrapper">
        <MonthNavHeader
          title="Log Ore"
          showInsertButton={!isReadOnly}
          onInsert={onInsert}
        >
          {hasClaim(PERMISSIONS.ADMINISTRATION) && (
            <MonoSelectUser 
              users={users}
              select={userChange}
            />
          )}  
        </MonthNavHeader>
        <div className="content__overflow">
          {isLoading ? (
            renderSpinner(true)
          ) : (
            <>
              <div className="tabsContainer">
                <WorklogTable              
                  ref={worklogTableRef}
                  onScroll={onWorklogTableScroll}              
                  userIssues={userIssues}
                  userProjects={userProjects}
                  worklogs={worklogs}
                  dayHeaders={dayHeaders}
                  isReadOnly={isReadOnly}
                  onDeleteClick={handleDeleteClick}
                  onEditClick={handleEditClick}
                  onTimeSpentChange={loadData}
                />
              </div>
              <div className="tabsContainer">
                <AbsencesTable
                  className="embedded-absence-table"
                  ref={absencesTableRef}
                  onScroll={onAbsencesTableScroll}
                  dayHeaders={dayHeaders}
                  data={absences}
                  isEmbeddedInWorklog={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <PanelTotal
        report={report}
        summaryData={summary}
        handleShowModalConfirm={handleShowModalConfirm}
      />
      <I3Modal
        show={showModalConfirm}
        onHide={handleHideModalConfirm}
        title="Chiudi mese"
      >
        <ConfirmForm
          close={handleHideModalConfirm}
          month={month}
          reloadReport={loadData}
        />
      </I3Modal>

      <I3Modal
        show={showModal}
        onHide={handleToggleModal}
        title="Log ore"
        className="I3Modal-log-time"
      >
        <WorklogForm
          month={month}
          userIssues={userIssues}
          userProjects={userProjects}
          close={handleToggleModal}
          editFormData={editFormData}
          onDataChanged={loadData}
          report={report}
        />
      </I3Modal>

      <I3Modal
        show={showModalDeleteConfirmOptions.show}
        onHide={() => setShowModalDeleteConfirmOptions({ show: false })}
        title="Elimina"
        className="I3Modal-log-time"
      >
        <div>
          <hr className="divider" />
          <p className="question-help">
            Vuoi cancellare il record selezionato?
          </p>
          <div className="buttonContent">
            <Button variant="secondary" onClick={handleHideDeleteConfirmModal}>
              Annulla
            </Button>
            <Button variant="primary" onClick={handleDeleteConfirmModal}>
              Elimina
            </Button>
          </div>
        </div>
      </I3Modal>
    </>
};

