/* eslint-disable react/jsx-props-no-spreading */

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormError, FormSelect as FormSelectOptions } from '.';

const FormSelect = (props) => {
  const { label, name } = props;

  return (
    <Form.Group controlId={`form${name}`} className="form-group">
      <Form.Label>{label}</Form.Label>
      <FormSelectOptions {...props} />
      <FormError {...props} />
    </Form.Group>
  );
};

FormSelect.defaultProps = {
  name: '',
  label: '',
};

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default FormSelect;
