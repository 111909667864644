/* eslint-disable react/jsx-props-no-spreading */

import { FormError, FormField, FormTextarea, FormSelect } from '.';

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FormItem = (props) => {
  const { label, name, style, type } = props;

  if (type === 'textarea') {
    return (
      <Form.Group controlId={`form${name}`} className="form-group" style={style}>
        <Form.Label>{label}</Form.Label>
        <FormTextarea {...props} />
        <FormError {...props} />
      </Form.Group>
    );
  }

  if (type === 'select') {
    return (
      <Form.Group controlId={`form${name}`} className="form-group" style={style}>
        <Form.Label>{label}</Form.Label>
        <FormSelect {...props} />
        <FormError {...props} />
      </Form.Group>
    );
  }

  return (
    <Form.Group controlId={`form${name}`} className="form-group" style={style}>
      <Form.Label>{label}</Form.Label>
      <FormField {...props} />
      <FormError {...props} />
    </Form.Group>
  );

};

FormItem.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  style: {},
  type: 'text'
};

FormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  type: PropTypes.string
};

export default FormItem;
