import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/I3Modal.scss';

const I3Modal = (props) => {
  const { show, onHide, title, children, className } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      className={`I3Modal ${className}`}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

I3Modal.defaultProps = {
  show: false,
  title: '',
  onHide: () => {},
  children: <></>,
  className: '',
};

I3Modal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default I3Modal;
