import PropTypes from 'prop-types';
import cn from 'classnames';
import { decimalFormatter } from '../../components/Table/utility';

export const TabWithChart = ({ title, unit, available, maxAvailable }) => {
  const max = Math.max(0, maxAvailable ?? 0);
  const rest = available ?? 0;
  const used = max - rest;
  return (
    <div className="tab-title">
      <span>{title} - </span>
      <span
        className={cn('tab-summary', {
          negative: available < 0,
        })}
      >
        {decimalFormatter.format(used)} / {decimalFormatter.format(max)}&nbsp;
        {unit}
      </span>
    </div>
  );
};

TabWithChart.propTypes = {
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  available: PropTypes.number,
  maxAvailable: PropTypes.number,
};
TabWithChart.defaultProps = { available: 0, unit: '€', maxAvailable: 0.01 };
