import { Store } from 'react-notifications-component';
import _ from 'lodash';

function checkNotificationParameters(title, message) {
  let realTitle = title;
  let realMessage = message;
  if (message == null) {
    realMessage = title;
    realTitle = null;
  }
  return { realTitle, realMessage };
}

export const success = (title, message) => {
  const { realTitle, realMessage } = checkNotificationParameters(
    title,
    message
  );
  Store.addNotification({
    title: realTitle || 'Message',
    message: realMessage,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const error = (title, message) => {
  const { realTitle, realMessage } = checkNotificationParameters(
    title,
    message
  );
  Store.addNotification({
    title: realTitle || 'Errors',
    message: realMessage,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: false,
  });
};

const Notifications = {
  success,
  error,
};
export default Notifications;

export const notifyResponseError = (e) => {
  if (!e?.response) {
    error('Unknown error');
  } else if (e.response.data && Array.isArray(e.response.data)) {
    _.forEach(e.response.data, (errors, field) =>
      Array.isArray(errors)
        ? error(field, errors.join('/n'))
        : error(field, errors)
    );
  } else if (e.response.data && e.response.data.error) {
    error(e.response.data.error);
  } else {
    error(e.response.statusText);
  }
};
