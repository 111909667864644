const TargetAddIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.7626 10.7626C11.0907 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0907 13.75 11.5359 13.75 12C13.75 12.4641 13.5656 12.9092 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0907 13.5656 10.7626 13.2374C10.4344 12.9092 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0907 10.7626 10.7626ZM12 11.75C11.9337 11.75 11.8701 11.7763 11.8232 11.8232C11.7763 11.8701 11.75 11.9337 11.75 12C11.75 12.0663 11.7763 12.1299 11.8232 12.1768C11.8701 12.2237 11.9337 12.25 12 12.25C12.0663 12.25 12.1299 12.2237 12.1768 12.1768C12.2236 12.1299 12.25 12.0663 12.25 12C12.25 11.9337 12.2236 11.8701 12.1768 11.8232C12.1299 11.7763 12.0663 11.75 12 11.75Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.80546 7.21905C9.75104 6.58723 10.8627 6.25 12 6.25C12.4142 6.25 12.75 6.58578 12.75 7C12.75 7.41421 12.4142 7.75 12 7.75C11.1594 7.75 10.3377 7.99925 9.63881 8.46625C8.9399 8.93325 8.39517 9.59701 8.0735 10.3736C7.75183 11.1502 7.66766 12.0047 7.83165 12.8291C7.99564 13.6535 8.40041 14.4108 8.99478 15.0052C9.58916 15.5996 10.3464 16.0043 11.1709 16.1683C11.9953 16.3323 12.8498 16.2482 13.6264 15.9265C14.403 15.6048 15.0667 15.0601 15.5337 14.3612C16.0007 13.6623 16.25 12.8406 16.25 12C16.25 11.5858 16.5858 11.25 17 11.25C17.4142 11.25 17.75 11.5858 17.75 12C17.75 13.1372 17.4128 14.2489 16.7809 15.1945C16.1491 16.1401 15.2511 16.8771 14.2004 17.3123C13.1497 17.7475 11.9936 17.8614 10.8782 17.6395C9.76283 17.4176 8.73827 16.87 7.93412 16.0659C7.12997 15.2617 6.58234 14.2372 6.36047 13.1218C6.13861 12.0064 6.25248 10.8502 6.68768 9.79957C7.12288 8.74889 7.85987 7.85086 8.80546 7.21905Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.2778 3.46686C9.04636 2.48872 11.0752 2.08435 13.0836 2.30967C13.4952 2.35585 13.7915 2.72698 13.7453 3.13861C13.6991 3.55024 13.328 3.8465 12.9164 3.80032C11.2169 3.60966 9.50024 3.95183 8.00377 4.77948C6.5073 5.60713 5.305 6.87936 4.56319 8.42019C3.82138 9.96102 3.57672 11.6943 3.86306 13.3803C4.14939 15.0662 4.95257 16.6215 6.16149 17.831C7.37041 19.0406 8.92532 19.8445 10.6111 20.1317C11.0195 20.2013 11.2941 20.5887 11.2245 20.997C11.155 21.4054 10.7676 21.68 10.3592 21.6104C8.3669 21.271 6.52929 20.3209 5.10056 18.8914C3.67184 17.462 2.72263 15.6239 2.38424 13.6314C2.04584 11.6389 2.33498 9.5905 3.21166 7.76952C4.08835 5.94854 5.50924 4.44499 7.2778 3.46686Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.6088 9.26877C21.0127 9.17673 21.4147 9.4295 21.5067 9.83336C21.7101 10.7259 21.788 11.6477 21.7337 12.572C21.7094 12.9855 21.3545 13.301 20.941 13.2767C20.5275 13.2524 20.212 12.8975 20.2363 12.484C20.2822 11.7019 20.2164 10.9219 20.0442 10.1667C19.9522 9.76282 20.205 9.36081 20.6088 9.26877ZM6.69781 19.3104C6.91233 18.9561 7.37348 18.8428 7.72782 19.0573C9.15916 19.9238 10.8202 20.3347 12.4904 20.2353C12.9039 20.2107 13.259 20.526 13.2836 20.9395C13.3082 21.3529 12.993 21.7081 12.5795 21.7327C10.6056 21.8501 8.64256 21.3646 6.95097 20.3404C6.59663 20.1259 6.48329 19.6648 6.69781 19.3104Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.287 2.30709C18.5673 2.42317 18.75 2.69665 18.75 3V5.25H21C21.3033 5.25 21.5768 5.43273 21.6929 5.71298C21.809 5.99324 21.7448 6.31583 21.5303 6.53033L18.5303 9.53033C18.3897 9.67098 18.1989 9.75 18 9.75H15C14.5858 9.75 14.25 9.41421 14.25 9V6C14.25 5.80108 14.329 5.61032 14.4697 5.46967L17.4697 2.46967C17.6842 2.25517 18.0067 2.191 18.287 2.30709ZM15.75 6.31066V8.25H17.6893L19.1893 6.75H18C17.5858 6.75 17.25 6.41421 17.25 6V4.81066L15.75 6.31066Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.5303 8.46967C15.8232 8.76256 15.8232 9.23743 15.5303 9.53033L12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303C11.1768 12.2374 11.1768 11.7626 11.4697 11.4697L14.4697 8.46967C14.7626 8.17677 15.2374 8.17677 15.5303 8.46967Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.25 19C15.25 18.5858 15.5858 18.25 16 18.25H22C22.4142 18.25 22.75 18.5858 22.75 19C22.75 19.4142 22.4142 19.75 22 19.75H16C15.5858 19.75 15.25 19.4142 15.25 19Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19 15.25C19.4142 15.25 19.75 15.5858 19.75 16V22C19.75 22.4142 19.4142 22.75 19 22.75C18.5858 22.75 18.25 22.4142 18.25 22V16C18.25 15.5858 18.5858 15.25 19 15.25Z"
              fill="currentColor"/>
    </svg>

);

export default TargetAddIcon;