import PropTypes from 'prop-types';
import _ from 'lodash';
import { useState, useContext } from 'react';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { deleteTrainingDay } from '../../utils/API/budgets';
import Box from '@mui/material/Box';
import { itIT, GridActionsCellItem } from '@mui/x-data-grid';
import { UserListContext } from '../../context/UserListContext';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { decimalFixed2ValueFormatter, decimalFixed2ColumnOptions, booleanColumnOptions, upperCaseColumnOptions } from '../../components/DataGrid/DataGridUtils';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter';
import moment from 'moment';
import { ExportToolbar } from '../../components/DataGrid/ExportToolbar';

const getColumns = ({
  handleEditClick,
  handleDeleteClick
}) =>
  [
    { field: 'date_id', headerName: 'Data' },
    { field: 'created_by', headerName: 'Creato da', ...upperCaseColumnOptions },
    { field: 'description', headerName: 'Descrizione', flex: 2 },
    { field: 'participants', headerName: 'Partecipanti', flex: 1.2, ...upperCaseColumnOptions },
    { field: 'speakers', headerName: 'Organizzatori / Speaker', flex: 1.2, ...upperCaseColumnOptions },
    { field: 'use_day_off', headerName: 'Ferie?', ...booleanColumnOptions },
    { field: 'duration', headerName: 'Giorni', ...decimalFixed2ColumnOptions},
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditOutlinedIcon />}
          label="Edit"
          onClick={() => handleEditClick(id)}
          color="inherit"
          key={`edit_${id}`}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlinedIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
          key={`delete_${id}`}
        />,
      ]
    }
  ];

const getRows = ({
  days,
  usersById
}) => days.map(({ user_training_day_detail: users, ...rest }) => ({
  ...rest,
  participants: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => !speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
  speakers: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
  total: _.sumBy(users, 'amount'),
  use_day_off: Boolean(users?.find(i => i.use_day_off)),
  duration: users?.find(i => i.use_day_off) ? 0 : rest.duration,
}));

export const AdminTrainingDaysDataGrid = ({
  days,
  setCurrentTrainingDay,
  handleShowInsertOrUpdateModal,
  currentTrainingDay,
  updateData,
  year,
}) => {
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const { usersById } = useContext(UserListContext);
  
  const handleEditClick = (id) => {
    const item = days.find((i) => i.id === id);
    const participants = item.user_training_day_detail
      .filter((detail) => detail.is_organizer_or_speaker === false)
      .map((detail) => detail.user_id);
    const speakers = item.user_training_day_detail
      .filter((detail) => detail.is_organizer_or_speaker === true)
      .map((detail) => detail.user_id);
    const useDayOff = Boolean(item.user_training_day_detail.find(i => i.use_day_off));
    const day = { 
      ...item,
      participants,
      speakers,
      useDayOff,
    }
    setCurrentTrainingDay(day);
    handleShowInsertOrUpdateModal('trainingDays');
  };
  
  const handleDeleteClick = (id) => {
    const [current] = days.filter((day) => day.id === id);
    setCurrentTrainingDay(current);
    setDeleteMessage(current.description);
    setDeleteConfirmVisible(true);
  };
  
  const closeDeleteConfirm = () => {
    setCurrentTrainingDay(undefined);
    setDeleteConfirmVisible(false);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteTrainingDay(currentTrainingDay.id);
    } finally {
      updateData();
      closeDeleteConfirm();
    }
  };
  
  const columns = getColumns({ handleEditClick, handleDeleteClick });  
  const rows = getRows({ days, usersById });

  const totalTimeSpent = rows.reduce(    
    (acc, curr) => acc + curr.total, 0    
  );

  const footerData = [
    { id: 1, label: 'Totale speso', value: totalTimeSpent, ...decimalFixed2ValueFormatter}
  ];

  const csvFilename = `${moment().format('YYYY-MM-DD')} - Report giorni di formazione (${year})`;

  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
              toolbar: ExportToolbar,
          }}                      
          slotProps={{
              footer: { data: footerData },
              toolbar: { csvFilename },
          }}
        />
      </Box>
      <I3ModalConfirmation
        show={isDeleteConfirmVisible}
        onHide={closeDeleteConfirm}
        title="Cancella giorni di formazione"
        questionTitle="Vuoi cancellare questa entry di formazione?"
        questionText={deleteMessage}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

AdminTrainingDaysDataGrid.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object),
  setCurrentTrainingDay: PropTypes.func,
  handleShowInsertOrUpdateModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentTrainingDay: PropTypes.any,
  updateData: PropTypes.func,
  year: PropTypes.number,
};

AdminTrainingDaysDataGrid.defaultProps = {
  days: [],
  currentTrainingDay: undefined,
  setCurrentTrainingDay: () => {},
  handleShowInsertOrUpdateModal: () => {},
  updateData: () => {},
  year: undefined,
};
