/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { Button, Form, FormControl } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { confirmReport } from '../utils/API/reports';
import { FormError, FormField } from '../components/Form';

const Textarea = ({ field, ...props }) => (
  <FormControl {...field} {...props} as="textarea" aria-label="With textarea" />
);

const initialValues = () => ({
  notes: '',
  confirm: true,
});

const onValidate = () => ({});

function ConfirmForm(props) {
  const { close, month, reloadReport } = props;

  async function onSubmit(values) {
    await confirmReport(values, month);
    reloadReport();
    close();
  }

  return (
    <Formik
      validate={onValidate}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close);
      }}
      initialValues={initialValues()}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="formNotes">
            <Form.Label>Note</Form.Label>
            <FormField component={Textarea} name="notes" />
            <FormError name="notes" />
          </Form.Group>
          <p className="question-title" />
          <p className="question-text">
            Una volta chiuso, non potrai pi&ugrave; modificare i dati inseriti.
            <br />
            In caso dovessi fare correzioni, contatta l&apos;amministrazione.
          </p>
          <hr className="divider" />
          <p className="question-help">
            Si consiglia di confermare a fine mese.
          </p>

          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Chiudi Mese
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

export default ConfirmForm;
