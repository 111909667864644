import PropTypes from 'prop-types';
import _ from 'lodash';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import { itIT } from '@mui/x-data-grid';
import { UserListContext } from '../../context/UserListContext';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { decimalFixed2ValueFormatter, decimalFixed2ColumnOptions, booleanColumnOptions, upperCaseColumnOptions } from '../../components/DataGrid/DataGridUtils';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter';

const columns = [
  { field: 'date_id', headerName: 'Data', flex: 1.2 },
  { field: 'created_by', headerName: 'Creato da', flex: 1.2, ...upperCaseColumnOptions },
  { field: 'description', headerName: 'Descrizione', flex: 2 },
  { field: 'is_organizer_or_speaker', headerName: 'Organizzatore / Speaker?', flex: 1.2, ...booleanColumnOptions },
  { field: 'use_day_off', headerName: 'Ferie?', ...booleanColumnOptions },
  { field: 'duration', headerName: 'Giorni', ...decimalFixed2ColumnOptions},
];

const getRows = ({
  days,
  usersById
}) => days.map(({ user_training_day_detail: users, ...rest }) => ({
  ...rest,
  participants: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => !speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
  speakers: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
  total: _.sumBy(users, 'amount'),
  use_day_off: rest.use_day_off || Boolean(users?.find(i => i.use_day_off)),
  duration: (rest.use_day_off || Boolean(users?.find(i => i.use_day_off))) ? 0 : rest.duration,
}));

export const TrainingDaysDataGrid = ({
  days,
  available,
  budget,
}) => {
  const { usersById } = useContext(UserListContext);  
  const rows = getRows({ days, usersById });
    
  const footerData = [
    { id: 1, label: 'Totale speso', value: budget - available, ...decimalFixed2ValueFormatter},
    { id: 2, label: 'Budget', value: budget, ...decimalFixed2ValueFormatter},
    { id: 3, label: 'Disponibilità', value: available, ...decimalFixed2ValueFormatter},
  ];

  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
          }}                    
          slotProps={{
              footer: { data: footerData },
          }}
        />
      </Box>
    </>
  );
};

TrainingDaysDataGrid.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object),
  available: PropTypes.number,
  budget: PropTypes.number,
};

TrainingDaysDataGrid.defaultProps = {
  days: [],
  available: 0,
  budget: 0,
};
