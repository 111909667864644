import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { Box } from '@mui/system';
import { TableCell } from '@mui/material';

export const DayHeaderCell = ({ 
    day,
    isWeekEnd,
    isHoliday,
    isToday
}) => {
    const classNames = cn({
        weekend: isWeekEnd && !isHoliday,
        holiday: isHoliday,
        today: isToday,
    });
    return (
        <TableCell className={classNames}>
            <Box>
                {day.format('DD')}
            </Box>
            <Box>
                {day.format('dd')}
            </Box>
        </TableCell>
    );
};

DayHeaderCell.propTypes = {
    day: PropTypes.instanceOf(moment),
    isWeekEnd: PropTypes.bool,
    isHoliday: PropTypes.bool,
    isToday: PropTypes.bool,
};
DayHeaderCell.defaultProps = {
    day: moment(),
    isWeekEnd: false,
    isHoliday: false,
    isToday: false,
};
