import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import I3Modal from '../../components/Layout/I3Modal';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { renderSpinner } from '../../utils/Utility';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import { RetrospectiveGoalTypesDataGrid } from "./RetrospectiveGoalTypesDataGrid";
import { deleteRetrospectiveGoalType, getRetrospectiveGoalTypes } from "../../utils/API/retrospectiveGoalTypes";
import { RetrospectiveGoalTypeForm } from "./RetrospectiveGoalTypeForm";

export const RetrospectiveGoalTypesPage = () => {
    const { hasClaim } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedRetrospectiveGoalType, setSelectedRetrospectiveGoalType] = useState(undefined);
    const [retrospectiveGoalTypes, setRetrospectiveGoalTypes] = useState([]);

    if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
        return (<Unauthorized/>);
    }

    useEffect(() => {
        setIsLoading(true);
        const getData = async () => {
            setRetrospectiveGoalTypes(await getRetrospectiveGoalTypes());
        }
        getData().finally(() => setIsLoading(false));
    }, []);

    const handleAddClick = () => {
        setSelectedRetrospectiveGoalType(undefined);
        setShowUpsertModal(true);
    };

    const handleEditClick = (id) => {
        const retrospectiveGoalType = retrospectiveGoalTypes.find(i => i.id === id);
        setSelectedRetrospectiveGoalType(retrospectiveGoalType);
        setShowUpsertModal(true);
    };

    const handleDeleteClick = (id) => {
        const retrospectiveGoalType = retrospectiveGoalTypes.find(i => i.id === id);
        setSelectedRetrospectiveGoalType(retrospectiveGoalType);
        setShowDeleteConfirmationModal(true);
    };

    const handleOnDataChanged = (item) => {
        const retrospectiveGoalType = retrospectiveGoalTypes.find(i => i.id === item.id);
        if (!retrospectiveGoalType) {
            setRetrospectiveGoalTypes([...retrospectiveGoalTypes, item]);
        } else {
            setRetrospectiveGoalTypes(retrospectiveGoalTypes.map(i => i.id === item.id ? item : i));
        }
    }

    const handleConfirmDeleteClick = async () => {
        try {
            await deleteRetrospectiveGoalType(selectedRetrospectiveGoalType.id);
            setRetrospectiveGoalTypes(retrospectiveGoalTypes.filter(i => i.id !== selectedRetrospectiveGoalType.id));
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    return (<>
        <div className="content__wrapper">
            <div className="headerPage content">
                <div className="title">Tipologia degli obiettivi</div>
                <div className="spacer"/>
                <div className="buttonGroup">
                    <Button variant="primary" onClick={() => handleAddClick()}>
                        <PlusCircle/>
                        Inserisci
                    </Button>
                </div>
            </div>
            <div className="content__fixed_height">
                <div className="tabsContainer" style={{ flex: '1 1 0px' }}>
                    {isLoading
                        ? (renderSpinner(true))
                        : (<RetrospectiveGoalTypesDataGrid
                            data={retrospectiveGoalTypes}
                            onEditClick={handleEditClick}
                            onDeleteClick={handleDeleteClick}
                        />)
                    }
                </div>
            </div>
        </div>
        <I3Modal
            show={showUpsertModal}
            onHide={() => setShowUpsertModal(false)}
            title={selectedRetrospectiveGoalType ? "Modifica tipologia di obiettivo" : "Inserisci tipologia di obiettivo"}
        >
            <RetrospectiveGoalTypeForm
                item={selectedRetrospectiveGoalType}
                onDataChanged={handleOnDataChanged}
                onClose={() => setShowUpsertModal(false)}
            />
        </I3Modal>
        <I3ModalConfirmation
            show={showDeleteConfirmationModal}
            onHide={() => setShowDeleteConfirmationModal(false)}
            title="Cancella tipologia di obiettivo"
            questionTitle="Vuoi cancellare questa tipologia di obiettivo?"
            onConfirm={handleConfirmDeleteClick}
        />
    </>);
};
