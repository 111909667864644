import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { GridFooterContainer, GridFooter } from '@mui/x-data-grid';

export const DataGridFooter = ({
    data,
}) => (    
    <GridFooterContainer>
        {data.map(item => 
            <Box key={item.id} sx={{ fontSize: '18px', fontWeight: 'bold', padding: '10px'}}>
                {item.label}:
                <Box 
                    component="span" 
                    sx={{
                        ml: 1,
                        ...(item.value < 0 && {
                            color: 'tomato',
                        }),
                    }}
                >
                    {item.valueFormatter({ value: item.value})}
                </Box>
            </Box>
        )}
        <GridFooter sx={{ border: 'none' }}/>
    </GridFooterContainer>
);

DataGridFooter.defaultProps = {
    data: [],
};

DataGridFooter.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};
