import { API } from '../../constants';
import request from '../axios';

export const getProjects = async () => {
    const res = await request.get(API.PROJECTS);
    return res.data;
}
  
export const getUserProjects = async () => {
    const res = await request.get(API.ME_PROJECTS);
    return res.data;
}
  