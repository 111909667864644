import PropTypes from 'prop-types';
import { RetrospectiveReportCard } from './RetrospectiveReportCard';
import CheckSuccessIcon from '../../components/Icons/CheckSuccessIcon';
import CheckProgressIcon from '../../components/Icons/CheckProgressIcon';
import CheckFailIcon from '../../components/Icons/CheckFailIcon';

export const RetrospectiveReports = ({ data }) => {
  const { currentYear, previousYear, total, outcomes } = data;

  const getPercentage = (v, tot) => {
    if (tot === 0) {
      return null;
    }
    return Math.round((v / tot) * 100);
  };

  const getFooter = (v, tot) => `${v}/${tot} totali`;

  return (
    <div className="retrospective-cards">
      <>
        <RetrospectiveReportCard title="Obiettivi totali"
                                 iconBackgroundColor="#E3E5E7"
                                 iconColor="#696F7C"
                                 data={[
                                   { header: currentYear, value: total.currentYear },
                                   { header: previousYear, value: total.previousYear }
                                 ]} />
        <RetrospectiveReportCard title="Obiettivi raggiunti"
                                 icon={<CheckSuccessIcon />}
                                 iconBackgroundColor="#ECF8F2"
                                 iconColor="#0F6D03"
                                 data={[
                                   {
                                     header: currentYear,
                                     value: getPercentage(outcomes.achieved.currentYear, total.currentYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.achieved.currentYear, total.currentYear)
                                   },
                                   {
                                     header: previousYear,
                                     value: getPercentage(outcomes.achieved.previousYear, total.previousYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.achieved.previousYear, total.previousYear)
                                   }
                                 ]} />
        <RetrospectiveReportCard title="Obiettivi parziali"
                                 icon={<CheckProgressIcon />}
                                 iconBackgroundColor="#FDEFD8"
                                 iconColor="#955F04"
                                 data={[
                                   {
                                     header: currentYear,
                                     value: getPercentage(outcomes.partiallyAchieved.currentYear, total.currentYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.partiallyAchieved.currentYear, total.currentYear)
                                   },
                                   {
                                     header: previousYear,
                                     value: getPercentage(outcomes.partiallyAchieved.previousYear, total.previousYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.partiallyAchieved.previousYear, total.previousYear)
                                   }
                                 ]} />
        <RetrospectiveReportCard title="Obiettivi non raggiunti"
                                 icon={<CheckFailIcon />}
                                 iconBackgroundColor="#FFF0F0"
                                 iconColor="#C20600"
                                 data={[
                                   {
                                     header: currentYear,
                                     value: getPercentage(outcomes.notAchieved.currentYear, total.currentYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.notAchieved.currentYear, total.currentYear)
                                   },
                                   {
                                     header: previousYear,
                                     value: getPercentage(outcomes.notAchieved.previousYear, total.previousYear),
                                     isPercentage: true,
                                     footer: getFooter(outcomes.notAchieved.previousYear, total.previousYear)
                                   }
                                 ]} />
      </>
    </div>
  );
};

RetrospectiveReports.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired
};

RetrospectiveReports.defaultProps = {};