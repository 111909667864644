import Styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const ButtonContainer = Styled.div`
  margin-top: 15px;
`;

export const DropContainer = Styled.div`
  border: 1px dashed var(--input-border-color);
  border-radius: 8px;
  padding: 2em;
  min-height: 12em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--brand);
  & > * {
    max-width: 16em;
    margin: auto;
  }
  b svg { display: none }
  p svg { display: inline-block; font-size: 2em; }
  &:hover {
    position: relative;
    b svg {
      display: inline-block;
      font-size: 2em;
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
`;
export const Label = Styled(Form.Label)`
  margin-bottom: 0;
  font-size: 0.9em;
  color: var(--text-color);
`;

export const Avatar = Styled.div`
  width: 100%;
  border: 1px solid var(--brand-extra-light);
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 50%;
  svg { display: none };
  &:hover, &.noAvatar {
    &:before:{
      background-color: red;
    }
    svg {
      display: block;
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2em;
    }
  }
`;

export const AvatarContainer = Styled.div`;
  padding: 0 1.5em;
`;

export default AvatarContainer;
