import { API } from '../../constants';
import request from '../axios';

export const getIssues = async () => {
    const res = await request.get(API.ISSUES);
    return res.data;
}
  
export const getUserIssues = async () => {
    const res = await request.get(API.ME_ISSUES);
    return res.data;
}
