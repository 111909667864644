import _ from 'lodash';
import { columnsFromData } from '../../components/Table/utility';
import { CurrencyCell } from '../../components/Cells/CurrencyCell';
import { IntegerCell } from '../../components/Cells/IntegerCell';

const columnOrder = [
  'rowNumber',
  'date',
  'description',
  'kilometers',
  'kilometer_cost',
  'total_cost',
  'action',
];

const prepareTableKilometers = (props) => {
  const { trips, isReportConfirmed } = props;
  const columns = columnsFromData({ data: trips });
  const hiddenColumns = ['id', 'report'];

  if (isReportConfirmed) {
    hiddenColumns.push('action');
  }
  const kilometersColumn = _.find(columns, ['Header', 'kilometers']);
  if (kilometersColumn) {
    kilometersColumn.Cell = IntegerCell;
  }
  const costColumn = _.find(columns, ['Header', 'total_cost']);
  if (costColumn) {
    costColumn.Cell = CurrencyCell;
  }
  const kmCostColumn = _.find(columns, ['Header', 'kilometer_cost']);
  if (kmCostColumn) {
    kmCostColumn.Cell = CurrencyCell;
  }

  return {
    columns,
    columnOrder,
    hiddenColumns,
  };
};

export default prepareTableKilometers;
