import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  deleteExpense,
  getExpense,
  getExpenses,
} from '../../utils/API/expenses';
import { UserContext } from '../../context/UserContext';
import { ExpenseForm } from './ExpenseForm';
import I3Modal from '../../components/Layout/I3Modal';
import BaseTable from '../../components/Table/BaseTable';
import prepareTableExpenses from './prepareTableExpenses';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { Unauthorized } from '../../components/Unauthorized';
import { completeAttachmentColumn } from '../../utils/Utility';
import { currencyFormatter } from '../../components/Table/utility';

const remappingCost = (expense) => ({
  ...expense,
  cost: expense.real_cost,
});

const fetchData = async (setExpenses, date) => {
  const expensesData = await getExpenses(date);
  const orderedData = _.orderBy(expensesData, ['date'], ['asc']);
  setExpenses(orderedData.map(remappingCost));
};

const Expenses = (props) => {
  const {
    showModal,
    handleShowModal,
    handleHideModal,
    showModalTableConfirm,
    handleShowModalTableConfirm,
    handleHideModalTableConfirm,
    month,
    isReportConfirmed,
    expensesUpdated,
  } = props;
  const { user } = useContext(UserContext);

  const [expenses, setExpenses] = useState([]);
  const [currentData, setCurrentData] = useState(false);
  const updateData = () => fetchData(setExpenses, month);
  const { t } = useTranslation();

  const handleHideTripModal = () => {
    handleHideModal();
    setCurrentData(false);
    updateData().finally();
  };

  useEffect(() => updateData(), [month]);

  useEffect(() => expensesUpdated(expenses), [expenses, expensesUpdated]);

  const prepareTable = prepareTableExpenses({
    data: expenses,
    isReportConfirmed,
  });

  const edit = (id) => {
    getExpense(id).then((trip) => {
      setCurrentData(trip);
      handleShowModal();
      return trip;
    });
  };

  const remove = (id) => {
    handleShowModalTableConfirm(id);
    setCurrentData(id);
  };

  const actions = {
    edit,
    remove,
  };

  const onConfirm = () => {
    deleteExpense(currentData).then(updateData);
    handleHideModalTableConfirm();
  };

  const typeColumn = _.find(prepareTable.columns, { accessor: 'type' });
  if (typeColumn) {
    Object.assign(typeColumn, {
      Cell: (prop) => {
        const { cell } = prop;
        return <div>{t(cell.value)}</div>;
      },
    });
  }

  const attachmentColumn = _.find(prepareTable.columns, {
    accessor: 'attachment',
  });
  if (attachmentColumn) {
    completeAttachmentColumn(attachmentColumn);
  }

  const expenseSummary = () => {
    const item = expenses.find((e) => e.id === currentData);
    if (!item) return '';
    const { date, description, real_cost: cost } = item;
    return `${date} - ${description} - ${currencyFormatter.format(cost)}`;
  };

  return user.is_employee ? (
    <>
      <BaseTable
        columns={prepareTable.columns}
        data={expenses}
        hiddenColumns={prepareTable.hiddenColumns}
        columnOrder={prepareTable.columnOrder}
        actions={actions}
        getCellProps={prepareTable.getCellProps}
      />

      <I3ModalConfirmation
        show={showModalTableConfirm.expense}
        onHide={handleHideModalTableConfirm}
        title="Cancella nota spese"
        questionTitle="Vuoi cancellare la spesa?"
        questionText={expenseSummary()}
        onConfirm={onConfirm}
      />

      <I3Modal
        show={showModal}
        onHide={handleHideTripModal}
        title={currentData?.id ? 'Modifica spesa' : 'Inserisci una nuova spesa'}
      >
        <ExpenseForm
          close={handleHideTripModal}
          currentData={currentData}
          updateData={updateData}
          month={month}
        />
      </I3Modal>
    </>
  ) : (
    <Unauthorized />
  );
};

Expenses.defaultProps = {
  showModal: false,
  handleShowModal: () => {},
  handleHideModal: () => {},
  showModalTableConfirm: false,
  handleShowModalTableConfirm: () => {},
  handleHideModalTableConfirm: () => {},
  month: moment(),
  expensesUpdated: () => {},
  isReportConfirmed: false,
};

Expenses.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  handleHideModal: PropTypes.func,
  showModalTableConfirm: PropTypes.shape({
    trip: PropTypes.bool,
    expense: PropTypes.bool,
  }),
  handleShowModalTableConfirm: PropTypes.func,
  handleHideModalTableConfirm: PropTypes.func,
  month: PropTypes.instanceOf(moment),
  expensesUpdated: PropTypes.func,
  isReportConfirmed: PropTypes.bool,
};

export default Expenses;
