import request from '../axios';
import { API } from '../../constants';
import { error, success } from '../notification';
import _ from 'lodash';
import { roundTime } from '../Utility';
import { stringify } from 'querystring';

export const getMultiPdf = ({ data, company, year, month }) =>
  request
    .post(`${API.REPORTS_PDF}?company=${company}&year=${year}&month=${month}`, data, {
      responseType: 'blob',
    })
    .then((res) => ({ ...res }))
    .catch((e) => {
      error('Errore', 'Creazione del PDF fallita');
      throw e;
    });

export const confirmReport = (values, momentDate) => {
  const { confirm } = values;
  return request
    .post(
      `${API.REPORTS_CONFIRM}?year=${momentDate.format(
        'YYYY'
      )}&month=${momentDate.format('MM')}`,
      values
    )
    .then(() => {
      success('Esito', confirm ? 'Mese chiuso' : 'Mese aperto');
    })
    .catch((e) => {
      error('Errore', e.response.data);
      throw e;
    });
};

export const getReportDetail = (year, month, username, type) =>
  request
    .get(
      `${API.REPORTS_DETAIL}?year=${year}&month=${month}&username=${username}&type=${type}`
    )
    .then((res) => ({ ...res.data }))
    .catch((e) => {
      throw e;
    });
export const getReport = (year, month, username) =>
  request
    .get(`${API.REPORTS}?year=${year}&month=${month}&username=${username}`)
    .then((res) =>
      _.forEach(res.data, (singleElement) => {
        if (singleElement.type === 'worklogs') {
          const it = singleElement.detail;
          // eslint-disable-next-line no-param-reassign
          it.total_time_spent = roundTime(it.total_time_spent);
          // eslint-disable-next-line no-param-reassign
          it.total_working_time_spent = roundTime(it.total_working_time_spent);
          // eslint-disable-next-line no-param-reassign
          it.total_non_working_time_spent = roundTime(
            it.total_non_working_time_spent
          );
        }
      })
    )
    .catch((e) => {
      throw e;
    });

export const getReportList = (company, year, month) =>
  request
    .get(`${API.REPORTS_LIST}?company=${company}&year=${year}&month=${month}`)
    .then((res) => [...res.data.results]);

export const getWorklogsByPeriod = (startDate, endDate) => {
  const params = {
    start_date: startDate,
    end_date: endDate,
  };
  const qs = stringify(params);
  return request
    .get(`${API.REPORTS_WORKLOGS_PREVIEW}/?${qs}`)
    .then((res) => res.data.result);
};

export const getXlsxReport = ({ apiUrl, company, year, month }) =>
  request
    .post(
      `${apiUrl}?company=${company}&year=${year}&month=${month}`,
      {},
      {
        responseType: 'blob',
      }
    )
    .then((res) => ({ ...res }))
    .catch((e) => {
      error('Errore', 'Creazione del report fallita');
      throw e;
    });
