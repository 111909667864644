import PropTypes from 'prop-types';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { deleteTrainingExpense } from '../../utils/API/budgets';
import { useState, useContext } from 'react';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import Box from '@mui/material/Box';
import { itIT, GridActionsCellItem } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { currencyValueFormatter, currencyColumnOptions, booleanColumnOptions, upperCaseColumnOptions } from '../../components/DataGrid/DataGridUtils';
import { UserListContext } from '../../context/UserListContext';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { openPreview } from '../../utils/API/fileDownload';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter'
import moment from 'moment';
import { ExportToolbar } from '../../components/DataGrid/ExportToolbar';

const getColumns = ({
  handleEditClick,
  handleDeleteClick
}) =>
  [
    { field: 'date', headerName: 'Data' },
    { field: 'created_by', headerName: 'Creato da', ...upperCaseColumnOptions },
    { field: 'description', headerName: 'Descrizione', flex: 2.5 },
    { field: 'participantsUsernames', headerName: 'Partecipanti', flex: 2, ...upperCaseColumnOptions },
    { field: 'speakersUsernames', headerName: 'Organizzatori / Speaker', flex: 2, ...upperCaseColumnOptions },
    { field: 'invoice_made_out_to_company', headerName: 'Fattura all\'azienda', flex: 1.1, ...booleanColumnOptions },
    { field: 'created_by_expense', headerName: 'Nota spese?', flex: 1.1, ...booleanColumnOptions },
    { field: 'total_spent', headerName: 'Totale', flex: 1.2, ...currencyColumnOptions },
    { 
      field: 'attachment',
      headerName: 'File',
      type: 'actions',
      cellClassName: 'actions',
      getActions: ({ row }) =>  {
        if (!row.attachment) {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={<FileDownloadOutlinedIcon />}
            label="Download"
            onClick={() => openPreview(row.attachment)}
            color="inherit"
            key={`download_${row.id}`}
          />,
        ]
      }
    },
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      cellClassName: 'actions',
      getActions: ({ row }) =>  {
        if (row.created_by_expense) {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={() => handleEditClick(row.id)}
            color="inherit"
            key={`edit_${row.id}`}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(row.id)}
            color="inherit"
            key={`delete_${row.id}`}
          />,
        ]
      }
    }
  ];

const getRows = ({
  expenses,
  usersById
}) => expenses.map(({ participants, speakers, ...rest }) => ({
  ...rest,
  participantsUsernames: participants
    ?.map((u) => usersById[u].username)
    ?.join(', '),
  speakersUsernames: speakers
    ?.map((u) => usersById[u].username)
    ?.join(', '),
}));

export const AdminTrainingExpensesDataGrid = ({
  expenses,
  currentTrainingExpense,
  setCurrentTrainingExpense,
  updateData,
  handleShowInsertOrUpdateModal,
  year,
}) => {
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');  
  const { usersById } = useContext(UserListContext);

  const handleEditClick = (id) => {
    const expense = expenses.find((b) => b.id === id);
    setCurrentTrainingExpense(expense);
    handleShowInsertOrUpdateModal('trainingBudget');
  };

  const handleDeleteClick = (id) => {
    const [current] = expenses.filter((day) => day.id === id);
    setCurrentTrainingExpense(current);
    setDeleteMessage(current.description);
    setDeleteConfirmVisible(true);
  };

  const closeDeleteConfirm = () => {
    setCurrentTrainingExpense(undefined);
    setDeleteConfirmVisible(false);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteTrainingExpense(currentTrainingExpense.id);
    } finally {
      updateData();
      closeDeleteConfirm();
    }
  };

  const columns = getColumns({ handleEditClick, handleDeleteClick });
  const rows = getRows({ expenses, usersById });
  
  const totalSpent = rows.reduce(    
    (acc, curr) => acc + curr.total_spent, 0    
  );
  const totalSpentCompany = rows.reduce(    
    (acc, curr) => acc + (curr.invoice_made_out_to_company ? curr.total_spent : 0), 0
  );
  const footerData = [
    { id: 1, label: 'Totale speso', value: totalSpent, ...currencyValueFormatter},
    { id: 2, label: 'Totale speso (con fattura ad azienda)', value: totalSpentCompany, ...currencyValueFormatter}
  ];

  const csvFilename = `${moment().format('YYYY-MM-DD')} - Report budget formazione (${year})`;

  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
              toolbar: ExportToolbar,
          }}                      
          slotProps={{
              footer: { data: footerData },
              toolbar: { csvFilename },
          }}
        />
      </Box>
      <I3ModalConfirmation
        show={isDeleteConfirmVisible}
        onHide={closeDeleteConfirm}
        title="Cancella budget formazione"
        questionTitle="Vuoi cancellare questa entry di budget?"
        questionText={deleteMessage}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

AdminTrainingExpensesDataGrid.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  setCurrentTrainingExpense: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentTrainingExpense: PropTypes.any,
  handleShowInsertOrUpdateModal: PropTypes.func,
  updateData: PropTypes.func,
  year: PropTypes.number,
};

AdminTrainingExpensesDataGrid.defaultProps = {
  expenses: [],
  currentTrainingExpense: undefined,
  setCurrentTrainingExpense: () => {},
  handleShowInsertOrUpdateModal: () => {},
  updateData: () => {},
  year: undefined,
};
