
import Button from '@mui/material/Button';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { UserContext } from '../context/UserContext';
import Stack from '@mui/material/Stack';
import { Link as RouterLink } from 'react-router-dom';
import { USERS_PARAM_NAME, ROUTES } from '../constants';

const EXP_CERT_NOTIFICATION_DELAYED_DATE = "exp-cert-notification-delayed-date";

export const EarnedCertificationsAlert = () => {

    const { user } = useContext(UserContext);
    const [showAlert, setShowAlert] = useState(false);
    const [dismissAlert, setDismissAlert] = useState(false);
    const [expiringCertificationsDelayedDate, setExpiringCertificationsDelayedDate] = useState();
    
    useEffect(() => {
      setExpiringCertificationsDelayedDate(localStorage.getItem(EXP_CERT_NOTIFICATION_DELAYED_DATE));
    }, []);
  
    useEffect(() => {
      let show = false;
      if (!dismissAlert && user?.exp_certifications) {
        if (!expiringCertificationsDelayedDate || moment().isAfter(moment(expiringCertificationsDelayedDate, 'YYYY-MM-DD').add(7, 'd'), 'day')) {
          show = true;
        }
      }
      setShowAlert(show);
    }, [user, expiringCertificationsDelayedDate, dismissAlert]);
    
    const handleDelayClick = () => {    
      const today = moment().format('YYYY-MM-DD');
      localStorage.setItem(EXP_CERT_NOTIFICATION_DELAYED_DATE, today);
      setExpiringCertificationsDelayedDate(today);
    }
  
    return <>
        {showAlert && (
            <Alert severity="warning" onClose={() => setDismissAlert(true)}>
              Hai delle certificazioni scadute o in scadenza.              
              <Stack direction="row" spacing={1} sx={{ mt: 2}}>
                <Button component={RouterLink} to={{ pathname: ROUTES.EARNED_CERTIFICATIONS, search: `?${USERS_PARAM_NAME}=${user.id}` }} variant="contained">
                    Vedi dettaglio
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleDelayClick()}>
                    Non mostrare per 7 giorni
                </Button>
              </Stack>              
            </Alert>
        )}
    </>
};

