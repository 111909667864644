const ArcheryArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1912_3946)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.2873 3.30724C17.5675 3.42333 17.7502 3.6968 17.7502 4.00015V6.25015H20.0002C20.3036 6.25015 20.5771 6.43288 20.6932 6.71314C20.8092 6.99339 20.7451 7.31598 20.5306 7.53048L17.5306 10.5305C17.3899 10.6711 17.1992 10.7502 17.0002 10.7502H14.0002C13.586 10.7502 13.2502 10.4144 13.2502 10.0002V7.00015C13.2502 6.80124 13.3293 6.61047 13.4699 6.46982L16.4699 3.46982C16.6844 3.25532 17.007 3.19116 17.2873 3.30724ZM14.7502 7.31081V9.25015H16.6896L18.1896 7.75015H17.0002C16.586 7.75015 16.2502 7.41437 16.2502 7.00015V5.81081L14.7502 7.31081Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.5306 9.46982C14.8235 9.76272 14.8235 10.2376 14.5306 10.5305L5.53057 19.5305C5.23768 19.8234 4.76281 19.8234 4.46991 19.5305C4.17702 19.2376 4.17702 18.7627 4.46991 18.4698L13.4699 9.46982C13.7628 9.17693 14.2377 9.17693 14.5306 9.46982Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.00024 14.25C5.41446 14.25 5.75024 14.5858 5.75024 15V18.25H9.00024C9.41446 18.25 9.75024 18.5858 9.75024 19C9.75024 19.4142 9.41446 19.75 9.00024 19.75H5.00024C4.58603 19.75 4.25024 19.4142 4.25024 19V15C4.25024 14.5858 4.58603 14.25 5.00024 14.25Z"
                  fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_1912_3946">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default ArcheryArrowIcon;