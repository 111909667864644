import { useContext } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { MonthContext } from '../../context/MonthContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MonthNav = () => {
  const { month, setMonth } = useContext(MonthContext);
  const handlePrev = () => setMonth(month.clone().subtract(1, 'months'));
  const handleNext = () => setMonth(month.clone().add(1, 'months'));

  return (
    <div className="d-flex">
      <div className="month-container">      
        <DatePicker
          views={['month', 'year']}
          value={month}
          onChange={(newValue) => setMonth(newValue)}
          slotProps={{ textField: { size: 'small' } }}
        />  
      </div>
      <div className="arrow">
        <ChevronLeft onClick={handlePrev} className="left" />
        <ChevronRight onClick={handleNext} className="right" />
      </div>
    </div>
  );
};

MonthNav.propTypes = {};

export default MonthNav;
