import _ from 'lodash';
import { API } from '../../constants';
import request from '../axios';

export const getCalendarWorklogsHeader = (momentDate) => {
  const y = momentDate.format('YYYY');
  const m = momentDate.format('MM');
  return request
    .get(`${API.CALENDAR}worklogs?year=${y}&month=${m}`)
    .then((res) => _.get(res, 'data.results', []));
};
