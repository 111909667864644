import request from '../axios';
import { API } from '../../constants';
import { error } from '../notification';

export const getVersion = () =>
  request
    .get(`${API.SYSTEM_VERSION}`)
    .then((res) => res?.data ?? {})
    .catch(() => {
      /* ignore */
    });

export const getSystem = () =>
  request
    .get(`${API.SYSTEM}`)
    .then((res) => res?.data ?? {})
    .catch((e) => {
      error('Errore', 'System api failure');
      throw e;
    });

export const getBudgets = () =>
  request
    .get(`${API.SYSTEM_BUDGETS}`)
    .then(({ data: { results } }) => results)
    .catch(() => {
      error('Errore', 'System budget api failure');
      return [];
    });
