import PropTypes from 'prop-types';
import { Pen, Trash } from 'react-bootstrap-icons';

export const ActionCellRenderer = ({ actions, rowData }) => (
  <div className="tableAction">
    {actions?.edit && (
      <button type="button" className="btn btn-action" aria-label="Edit">
        <Pen
          onClick={() => actions.edit(rowData.id)}
          size="26px"
          className="icon"
          key="Pen"
        />
      </button>
    )}
    {actions?.remove && (
      <button type="button" className="btn btn-action" aria-label="Delete">
        <Trash
          onClick={() => actions.remove(rowData.id)}
          size="26px"
          className="icon"
          key="Trash"
        />
      </button>
    )}
  </div>
);

ActionCellRenderer.defaultProps = {
  actions: {},
  rowData: {},
};

ActionCellRenderer.propTypes = {
  actions: PropTypes.shape({ edit: PropTypes.func, remove: PropTypes.func }),
  rowData: PropTypes.shape({ id: PropTypes.number }),
};
