import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

export const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: '#7b818e08',
    },
}));

export const BorderlessStripedDataGrid = styled(StripedDataGrid)(() => ({
    border: 0,
}));