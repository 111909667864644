import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Card, Container } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormItem from '../../components/Form/FormItem';
import Styled from 'styled-components';
import {
  RetrospectiveGoalsAndResults
} from '../../components/RetrospectiveGoalsAndResults/RetrospectiveGoalsAndResults';
import { getRetrospective, updateRetrospective } from '../../utils/API/retrospective';
import { InterviewersSelector } from './InterviewersSelector';
import { renderSpinner } from '../../utils/Utility';
import LazyAvatar from '../../components/User/Avatar';
import { getRetrospectiveGoalTypes } from '../../utils/API/retrospectiveGoalTypes';
import * as Yup from 'yup';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PageAnchors } from '../../components/PageAnchor/PageAnchor';

const validationSchema = Yup.object().shape({
  interviewers: Yup.array().of(Yup.number()).min(1, 'Seleziona almeno un intervistatore'),
  mood: Yup.string(),
  comments: Yup.string(),
  notes: Yup.string(),
  currentGoals: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        id: Yup.number().required('Campo obbligatorio')
      }),
      description: Yup.string().required('Campo obbligatorio')
    })
  )
});

const CARD_MARGIN = 50;
const StyledCard = Styled(Card)`
      margin-top: ${CARD_MARGIN}px;
      margin-bottom: ${CARD_MARGIN}px;
      border-radius: 12px;
      border: 1px solid var(--brand-light);
      padding: 2em;
    `;

export const RetrospectiveEditPage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { hasClaim } = useContext(UserContext);
  const [retrospective, setRetrospective] = useState(null);
  const [goalTypes, setGoalTypes] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [arrayHelpers, setArrayHelpers] = useState(undefined);
  const [selectedGoalIndex, setSelectedGoalIndex] = useState(undefined);

  const commentsAndNotesRef = useRef(null);
  const previousGoalsRef = useRef(null);
  const currentGoalsRef = useRef(null);
  const sectionContainerRef = useRef(null);

  const sections = useMemo(() => {
    if (!retrospective) {
      return [];
    }
    const data = [
      {
        anchor: 'commentsAndNotes',
        name: 'Commenti e note',
        ref: commentsAndNotesRef
      },
      {
        anchor: 'currentGoals',
        name: `Obiettivi ${retrospective.year}`,
        ref: currentGoalsRef
      }
    ];
    if (retrospective?.previousGoals?.length) {
      data.splice(1, 0, {
        anchor: 'previousGoals',
        name: `Obiettivi ${retrospective.year - 1}`,
        ref: previousGoalsRef
      });
    }
    return data;
  }, [retrospective, commentsAndNotesRef, previousGoalsRef, currentGoalsRef]);

  useEffect(() => {
    const loadRetrospective = async () => {
      try {
        setGoalTypes(await getRetrospectiveGoalTypes());
        setRetrospective(await getRetrospective(id));
      } catch (error) {
        console.error('Errore nel caricamento della retrospettiva:', error);
      }
    };

    loadRetrospective().then();
  }, [id]);

  if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
    return (<Unauthorized />);
  }

  const navigateToListPage = () => {
    navigate(`/retrospectives`, { state });
  };

  const handleSaveClick = async (values) => {
    try {
      await updateRetrospective(id, values);
      navigateToListPage();
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
    }
  };

  const handleDeleteClick = (helpers, index) => {
    setArrayHelpers(helpers);
    setSelectedGoalIndex(index);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    arrayHelpers.remove(selectedGoalIndex);
    setArrayHelpers(undefined);
    setSelectedGoalIndex(undefined);
    setShowDeleteConfirmationModal(false);
  };

  if (!retrospective) {
    return renderSpinner();
  }

  return (
    <div className="content__wrapper">
      <div className="headerPage content">
        <div className="title">
          <KeyboardBackspaceIcon
            className="back-button"
            onClick={navigateToListPage}
          />
          Retrospettiva
        </div>
      </div>

      <div className="headerPage content">
        <div className="title">
          <LazyAvatar username={retrospective.user.username} />
          <div>
            <div>{retrospective.user.full_name}</div>
            <div className="subtitle">
              <span>Retrospettiva del: {retrospective.year}</span> | <span>Ultima mail inviata: {retrospective.lastNotificationDate ? new Date(retrospective.lastNotificationDate).toLocaleDateString() : ''}</span>
            </div>
          </div>
        </div>
        <div className="spacer" />
        <div className="d-flex gap-3 form-group">
          <Button variant="Secondary" onClick={navigateToListPage}>
            Annulla
          </Button>

          <Button variant="primary" type="submit" form="retrospective-form">
            Salva
          </Button>
        </div>
      </div>

      <div className="d-flex">
        <div className="anchors__column">
          <PageAnchors sections={sections} />
        </div>

        <div className="content__column" ref={sectionContainerRef}>
          <Container className="content__fixed_height">
            <Formik
              initialValues={{
                interviewers: retrospective.interviewers || [],
                mood: retrospective?.mood || '',
                comments: retrospective?.comments || '',
                notes: retrospective?.notes || '',
                currentGoals: retrospective.currentGoals || [],
                previousGoals: retrospective.previousGoals || []
              }}
              validationSchema={validationSchema}
              onSubmit={handleSaveClick}
            >
              {({ values, setFieldValue }) => (
                <FormikForm noValidate id="retrospective-form">
                  <StyledCard id="commentsAndNotes" ref={commentsAndNotesRef}>
                    <Card.Body>
                      <Card.Title className="card-header-with-line">
                        <div className="text-bold">Commenti e note</div>
                        <div className="text-small">Inserisci gli appunti raccolti durante la retrospettiva e i commenti
                          fatti a posteriori.
                        </div>
                      </Card.Title>
                      <Card.Body>
                        <InterviewersSelector
                          name="interviewers"
                          interviewers={values.interviewers}
                          setInterviewers={(interviewers) => {
                            setFieldValue('interviewers', interviewers);
                          }}
                        />
                        <FormItem
                          type="select"
                          name="mood"
                          label="Clima"
                          className="form-group"
                          options={Object.entries({
                            positive: 'Positivo',
                            neutral: 'Neutro',
                            negative: 'Negativo'
                          }).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        />
                        <FormItem
                          type="textarea"
                          name="comments"
                          label="Commenti condivisi"
                          rows="5"
                          className="form-group"
                        />
                        <FormItem
                          type="textarea"
                          name="notes"
                          label="Note interne"
                          rows="5"
                          className="form-group"
                        />
                      </Card.Body>
                    </Card.Body>
                  </StyledCard>

                  {retrospective.previousGoals && retrospective.previousGoals.length > 0 && (
                    <RetrospectiveGoalsAndResults
                      objectName="previousGoals"
                      year={retrospective.year - 1}
                      mode="review"
                      data={values.previousGoals}
                      typeList={goalTypes}
                      metadata={{ id: 'previousGoals', ref: previousGoalsRef }}
                    />
                  )}
                  <RetrospectiveGoalsAndResults
                    objectName="currentGoals"
                    year={retrospective.year}
                    mode="edit"
                    data={values.currentGoals}
                    typeList={goalTypes}
                    onDelete={handleDeleteClick}
                    metadata={{ id: 'currentGoals', ref: currentGoalsRef }}
                  />
                </FormikForm>
              )}
            </Formik>
          </Container>
        </div>
      </div>

      <I3ModalConfirmation
        show={showDeleteConfirmationModal}
        onHide={() => setShowDeleteConfirmationModal(false)}
        title="Cancella Obiettivo"
        questionTitle="Vuoi cancellare questo obiettivo?"
        onConfirm={handleConfirmDeleteClick}
      />
    </div>);
};
