/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';

import { Button } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { sendFiscalDocuments } from '../../utils/API/sendFiscalDocuments';

import DropFileZone from '../../components/Form/DropFileZone';
import FormItem from '../../components/Form/FormItem';

const initialValues = () => ({
  subject: '',
  text: '',
  attachment: '',
});

const validationSchema = Yup.object().shape({
  subject: Yup.string().trim().required('Campo obbligatorio'),
  text: Yup.string().trim(),
  attachment: Yup.mixed().required('Campo obbligatorio'),
});

const SendFiscalDocumentsForm = ({ companyId, close }) => {
  const [executeRemoveSelectedFiles, setExecuteRemoveSelectedFiles] =
    useState(false);
  const [isFileAttachment, setIsFileAttachment] = useState(false);
  const attachments = [];

  async function onSubmit(values, { setSubmitting }) {
    try {
      await sendFiscalDocuments({ ...values, company: companyId });
    } finally {
      setSubmitting(false);
      close();
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values, actions).then();
      }}
      initialValues={initialValues()}
    >
      {({ isSubmitting, setFieldValue, isValid }) => (
        <Form>
          <FormItem type="text" name="subject" label="Oggetto della mail *" />
          <FormItem type="text" name="text" label="Testo della mail" />
          <DropFileZone
            name="attachment"
            fileTypes=".zip"
            text="Trascina il file oppure fai click per selezionare il file contenente i documenti da allegare alla mail identificati tramite la presenza nel nome del Codice Fiscale"
            setFieldValue={setFieldValue}
            attachments={attachments}
            executeRemoveSelectedFiles={executeRemoveSelectedFiles}
            setExecuteRemoveSelectedFiles={setExecuteRemoveSelectedFiles}
            setIsFileAttachment={setIsFileAttachment}
          />
          <hr />
          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !isFileAttachment || !isValid}
            >
              Invia
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SendFiscalDocumentsForm;
