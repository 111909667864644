import { API } from '../../constants';
import request from '../axios';
import { error } from '../notification';

export const getCompanies = async () => {
    try {
        const res = await request.get(API.COMPANIES);
        return res.data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}
