import { useContext, useEffect, useState } from 'react';
import { renderSpinner } from '../../utils/Utility';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { UserContext } from '../../context/UserContext';
import moment from 'moment';
import { Unauthorized } from '../../components/Unauthorized';
import { PERMISSIONS, REPORT_GROUP_BY } from '../../constants';
import { UserListContext } from '../../context/UserListContext';
import { getWorklogsByPeriod } from '../../utils/API/reports';
import { getIssues } from '../../utils/API/issues';
import { getProjects } from '../../utils/API/projects';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { ReportDataGrid } from './ReportDataGrid';

export const ReportPage = () => {
  const { hasClaim } = useContext(UserContext);
  const { users } = useContext(UserListContext);

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: moment(firstDay),
    end: moment(lastDay),
  });
  const [selectedProjectId, setSelectedProjectId] = useState(0);
  const [selectedIssueId, setSelectedIssueId] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [dataPeriod, setDataPeriod] = useState({
    start: moment(firstDay),
    end: moment(lastDay),
  });
  const [projects, setProjects] = useState([]);
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
    
  useEffect(() => {
    getProjects().then(res => setProjects(res.sort((a, b) => a.summary.localeCompare(b.summary))));
    getIssues().then(res => {
      setIssues(res.sort((a, b) => a.summary.localeCompare(b.summary)));
      setFilteredIssues([]);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);    
    getWorklogsByPeriod(dataPeriod.start.format('YYYY-MM-DD'), dataPeriod.end.format('YYYY-MM-DD'))
    .then((res) => setData(res))
    .finally(() => setIsLoading(false));
  }, [dataPeriod]);
  
  if (!hasClaim(PERMISSIONS.DATA_ANALYSIS)) {
    return (
      <Unauthorized />
    );
  }

  const handleSelectedProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
    setSelectedIssueId(0);
    if (event.target.value) {
      setFilteredIssues(issues.filter(i => i.projectId === event.target.value));
    } else {
      setFilteredIssues([]);
    }
  }

  const handleSelectedPeriodChange = (period) => {
    if (period.start.isBefore(dataPeriod.start) || period.end.isAfter(dataPeriod.end)) {
      setDataPeriod(period);
    }
    setSelectedPeriod(period);
  }

  const filterProjectSummary = selectedProjectId ? projects.find(i => i.id === selectedProjectId).summary : '';
  const filterIssueSummary = selectedIssueId ? issues.find(i => i.id === selectedIssueId).summary : '';
  
  return (
    <>
      <div className="content__wrapper">
        <div className="headerPage content">
          <div className="title">Report</div>
          <div className="spacer" />
          <div className="filter__row">
            <FormControl sx={{ width: 250, m: 1 }} size="small">
              <InputLabel id="project-label">Progetto</InputLabel>
                <Select
                  labelId="project-label"
                  id="project-select"
                  value={selectedProjectId}
                  label="Progetto"
                  onChange={handleSelectedProjectChange}
                >
                  <MenuItem value={0}>
                    Tutti i progetti
                  </MenuItem>
                  {projects.map((i) => (
                    <MenuItem key={i.id} value={i.id}>{i.summary}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width: 250, m: 1 }} size="small">
              <InputLabel id="issue-label">Attività</InputLabel>
                <Select
                  labelId="issue-label"
                  id="issue-select"
                  value={selectedIssueId}
                  label="Attività"
                  onChange={(e) => setSelectedIssueId(e.target.value)}
                >
                  <MenuItem value={0}>
                    Tutte le attività
                  </MenuItem>
                  {filteredIssues.map((i) => (
                    <MenuItem key={i.id} value={i.id}>{i.summary}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width: 250, m: 1 }} size="small">
              <InputLabel id="groupby-label">Raggruppamento</InputLabel>
                <Select
                  labelId="groupby-label"
                  id="groupby-select"
                  value={selectedGroupBy}
                  label="Raggruppamento"
                  onChange={(e) => setSelectedGroupBy(e.target.value)}
                >
                  <MenuItem value={REPORT_GROUP_BY.NONE}>
                    Nessuno
                  </MenuItem>
                  <MenuItem value={REPORT_GROUP_BY.PROJECT}>
                    Progetto
                  </MenuItem>
                  <MenuItem value={REPORT_GROUP_BY.ISSUE}>
                    Attività
                  </MenuItem>
                </Select>
            </FormControl>              
            <div className="form-group">
              <MultiSelectUser
                users={users}
                selected={selectedUserIds}
                setSelected={setSelectedUserIds}
              />              
            </div>
            <div className="form-group">
              <DateRangePicker
                onCallback={(start, end) => handleSelectedPeriodChange({start, end})}
                initialSettings={{ startDate: firstDay, endDate: lastDay }}
              >
                <input
                  type="text"
                  name="dateRange"
                  className="form-control col-4"
                />
              </DateRangePicker>
            </div>
          </div>
        </div>
        <div className="content__overflow">
          <div className="tabsContainer" style={{ overflow: 'auto' }}>
            <Box sx={{ height: 700 }}>
                {isLoading ? (
                  renderSpinner(true)
                ) : (
                  <ReportDataGrid
                    data={data}
                    filterProjectId={selectedProjectId}
                    filterProjectSummary={filterProjectSummary}
                    filterIssueId={selectedIssueId}
                    filterIssueSummary={filterIssueSummary}
                    filterUserIds={selectedUserIds}
                    filterPeriodStart={selectedPeriod.start}
                    filterPeriodEnd={selectedPeriod.end}
                    groupBy={selectedGroupBy}
                  />
                )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
