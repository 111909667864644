import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getBudgets } from '../utils/API/system';

const thisYear = moment().year();
const BudgetContext = createContext({
  configuration: {},
  year: thisYear,
  setYear: () => {},
  availableYears: [],
});

const getConfigurationForYear = (budgets, year) => {
  const yearBudgets = budgets.filter(({ year: y }) => +y === +year);
  return {
    trainingDaysBudget: yearBudgets.filter(i => i.type === 'training_days'),
    trainingExpenseBudget: yearBudgets.filter(i => i.type === 'training_budget'),
    booksBudget: yearBudgets.filter(i => i.type === 'book'),
    smartWorkingBudget: yearBudgets.filter(i => i.type === 'smart_working'),
  };
};

const BudgetContextProvider = ({ children }) => {
  const [budgets, setBudgets] = useState([]);
  const [year, setYear] = useState(thisYear);

  useEffect(() => {
    getBudgets().then(setBudgets);
  }, []);

  const configuration = useMemo(() => {
    if (!budgets) {
      return {
        trainingDaysBudget: [],
        trainingExpenseBudget: [],
        booksBudget: [],
        smartWorkingBudget: [],
      };
    }
    return getConfigurationForYear(budgets, year);
  }, [year, budgets]);

  const availableYears = useMemo(
    () => [...new Set(budgets.map((e) => e.year))].sort(),
    [budgets]
  );

  return (
    <BudgetContext.Provider
      value={{ configuration, year, setYear, availableYears }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

BudgetContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BudgetContext, BudgetContextProvider };
