import { columnsFromData } from '../../components/Table/utility';
import { UserWithAvatarRenderer } from '../../components/User/UserWithAvatarRenderer';

const Closed = () => (
  <div className="report-status closed">
    <span className="icon-close-circle" />
    <span>CHIUSO</span>
  </div>
);
const Opened = () => (
  <div className="report-status open">
    <span className="icon-check-circle" />
    <span>APERTO</span>
  </div>
);

const columnOrder = ['nome', 'stato del mese', 'pdf'];

const getVirtualColumns = (generatePDF) => ({
  nome: UserWithAvatarRenderer,
  'stato del mese': (data) => {
    const {
      row: { original },
    } = data;
    return original.all_confirmed ? <Closed /> : <Opened />;
  },
  pdf: (data) => {
    const {
      row: { original },
    } = data;
    return original.all_confirmed ? (
      <a
        href="#"
        onClick={() =>
          generatePDF(
            [original.username],
            ['worklogs', 'expenses', 'trips'],
            `${original.last_name} ${original.first_name} (${original.username})`
          )
        }
      >
        Scarica
      </a>
    ) : (
      <span style={{ color: '#ccc' }}>Scarica</span>
    );
  },
});

const prepareTableAdministration = (props) => {
  const { data, generatePDF } = props;
  const virtualColumns = getVirtualColumns(generatePDF);
  const columns = columnsFromData({ data, virtualColumns });
  const hiddenColumns = [
    'rowNumber',
    'action',
    'id',
    'reports',
    'username',
    'first_name',
    'last_name',
    'full_name',
    'all_confirmed',
    'email',
    'avatar',
  ];

  return {
    columns,
    columnOrder,
    hiddenColumns,
  };
};

export default prepareTableAdministration;
