import request from '../axios';
import { keycloak } from '../../keycloak';

const getFile = (filePath, filename) =>
  request
    .get(`${filePath}`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${keycloak?.token}`,
      },
    })
    .then((response) => {
      const url = URL.createObjectURL(new Blob([response.data]));
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.target = '_blank';
      tempLink.setAttribute('download', filename);
      tempLink.click();
    });

export const openPreview = (value) => {
  const filename = value.replace(/^.*[\\/]/, '');
  getFile(value, filename);
};
