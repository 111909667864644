import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { Unauthorized } from '../../components/Unauthorized';
import { PERMISSIONS } from '../../constants';
import { CertificationsDataGrid } from './CertificationsDataGrid';
import { CertificationForm } from './CertificationForm';
import I3Modal from '../../components/Layout/I3Modal';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { deleteCertification, getCertifications, getCertificationsEmitters, getCertificationsCategories } from '../../utils/API/certifications';
import { renderSpinner } from '../../utils/Utility';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';

export const CertificationsPage = () => {
  const { hasClaim } = useContext(UserContext);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [certificationsEmitters, setCertificationsEmitters] = useState([]);
  const [certificationsCategories, setCertificationsCategories] = useState([]);  

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {      
      setCertifications(await getCertifications());
      setCertificationsEmitters(await getCertificationsEmitters());
      setCertificationsCategories(await getCertificationsCategories());
    }    
    getData().finally(() => setIsLoading(false));
  }, []);

  if (!hasClaim(PERMISSIONS.CERTIFICATION_ADMINISTRATION)) {
    return (
      <Unauthorized />
    );
  }

  const handleOnDataChanged = (item) => {
    const certification = certifications.find(i => i.id === item.id);
    if (!certification) {
      setCertifications([...certifications, item]);
    } else {
      setCertifications(certifications.map(i => i.id === item.id ? item : i));
    }
  }

  const handleAddClick = () => {
    setSelectedCertification(undefined);
    setShowUpsertModal(true);
  };
  
  const handleEditClick = (id) => {
    const certification = certifications.find(i => i.id === id);
    setSelectedCertification(certification);
    setShowUpsertModal(true);
  };
  
  const handleDeleteClick = (id) => {
    const certification = certifications.find(i => i.id === id);
    setSelectedCertification(certification);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDeleteClick = async () => {
    try {
      deleteCertification(selectedCertification.id).then(res => {
        setCertifications(certifications.filter(i => i.id !== selectedCertification.id));
      });
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  return (
    <>
        <div className="content__wrapper">
            <div className="headerPage content">
                <div className="title">Certificazioni</div>
                <div className="spacer" />          
                <div className="buttonGroup">
                    <Button variant="primary" onClick={() => handleAddClick()}>
                        <PlusCircle />
                        Inserisci
                    </Button>                  
                </div>
            </div>
              <div className="tabsContainer" style={{ flex:'1 1 0'}}>
                  {isLoading ? (
                      renderSpinner(true)
                  ) : (
                  <CertificationsDataGrid
                      certifications={certifications}
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                  />
                  )}
              </div>
        </div>
        <I3Modal
            show={showUpsertModal}
            onHide={() => setShowUpsertModal(false)}
            title={selectedCertification ? "Modifica certificazione" : "Inserisci certificazione"}
        >
            <CertificationForm
                certification={selectedCertification}
                emitters={certificationsEmitters}
                categories={certificationsCategories}
                onDataChanged={handleOnDataChanged}
                onClose={() => setShowUpsertModal(false)}                
            />
        </I3Modal>
        <I3ModalConfirmation
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
          title="Cancella certificazione"
          questionTitle="Vuoi cancellare questa certificazione?"
          questionText={selectedCertification?.description}
          onConfirm={handleConfirmDeleteClick}
        />
    </>
  );
};
