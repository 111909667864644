import { MultiSelectUser } from './MultiSelectUser';
import { useState } from 'react';
import PropTypes from 'prop-types';

export const MonoSelectUser = ({
  users,
  select,
  label,
}) => {
  const [selected, setSelected] = useState([]);
  const applySelection = (array) => {
    setSelected(array);
    select(array[0]);
  };
  return (
    <MultiSelectUser
      users={users}
      selected={selected}
      setSelected={applySelection}
      label={label}
      promptLabel="Seleziona utente"
      emptySelectionLabel="Seleziona utente"
      maxSelected={1}
      variant="light"
    />
  );
};

MonoSelectUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  label: PropTypes.string,
};

MonoSelectUser.defaultProps = {
  label: undefined,
};
