import PropTypes from 'prop-types';
import { useContext } from 'react';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import Box from '@mui/material/Box';
import { itIT, GridActionsCellItem } from '@mui/x-data-grid';
import { currencyValueFormatter, currencyColumnOptions, booleanColumnOptions, upperCaseColumnOptions } from '../../components/DataGrid/DataGridUtils';
import { UserListContext } from '../../context/UserListContext';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { openPreview } from '../../utils/API/fileDownload';

const columns = [
  { field: 'date', headerName: 'Data' },
  { field: 'created_by', headerName: 'Creato da', ...upperCaseColumnOptions },
  { field: 'description', headerName: 'Descrizione', flex: 1.5 },
  { field: 'place', headerName: 'Luogo', flex: 1 },
  { field: 'note', headerName: 'Note', flex: 1 },
  { field: 'is_organizer_or_speaker', headerName: 'Organizzatore / Speaker?', ...booleanColumnOptions },
  { field: 'is_guild', headerName: 'Gilda?', ...booleanColumnOptions },
  { field: 'invoice_made_out_to_company', headerName: 'Fattura all\'azienda?', ...booleanColumnOptions },
  { field: 'created_by_expense', headerName: 'Nota spese?', ...booleanColumnOptions },
  { field: 'ticket', headerName: 'Biglietto', ...currencyColumnOptions },
  { field: 'room', headerName: 'Albergo', ...currencyColumnOptions },
  { field: 'plane', headerName: 'Aereo', ...currencyColumnOptions },
  { field: 'highway', headerName: 'Autostrada / Pedaggio', ...currencyColumnOptions },
  { field: 'other', headerName: 'Altri costi', ...currencyColumnOptions },
  { field: 'total_spent', headerName: 'Totale', ...currencyColumnOptions },
  { 
    field: 'attachment',
    headerName: 'File',
    type: 'actions',
    flex: 0.2,
    cellClassName: 'actions',
    getActions: ({ row }) =>  {
      if (!row.attachment) {
        return [];
      }
      return [
        <GridActionsCellItem
          icon={<FileDownloadOutlinedIcon />}
          label="Download"
          onClick={() => openPreview(row.attachment)}
          color="inherit"
          key={`download_${row.id}`}
        />,
      ]
    }
  },
];

const getRows = ({
  expenses,
  usersById
}) => expenses.map(({ user_budget_detail: users, ...rest }) => ({
  ...rest,
  participants: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => !speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
  speakers: users
    ?.filter(({ is_organizer_or_speaker: speaker }) => speaker)
    ?.map((u) => usersById[u.user_id].username)
    ?.join(', '),
}));

export const TrainingExpensesDataGrid = ({
  expenses,
  available,
  budget,
}) => {
  const { usersById } = useContext(UserListContext);

  const rows = getRows({ expenses, usersById });
  
  const footerData = [
    { id: 1, label: 'Totale speso', value: budget - available, ...currencyValueFormatter},
    { id: 2, label: 'Budget', value: budget, ...currencyValueFormatter},
    { id: 3, label: 'Disponibilità', value: available, ...currencyValueFormatter},
  ];
  
  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
          }}                    
          slotProps={{
              footer: { data: footerData },
          }}
        />
      </Box>
    </>
  );
};

TrainingExpensesDataGrid.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  available: PropTypes.number,
  budget: PropTypes.number,
};

TrainingExpensesDataGrid.defaultProps = {
  expenses: [],
  available: 0,
  budget: 0,
};
