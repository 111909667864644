import PropTypes from 'prop-types';
import { itIT } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter'
import moment from 'moment';
import { ExportToolbar } from '../../components/DataGrid/ExportToolbar';
import { REPORT_GROUP_BY } from '../../constants';
import { decimalFixed2ValueFormatter, decimalFixed2ColumnOptions } from '../../components/DataGrid/DataGridUtils';

const allColumns = [
  { field: 'userFullName', headerName: 'Utente', flex: 1.5 },
  { field: 'projectSummary', headerName: 'Progetto', flex: 2 },
  { field: 'issueSummary', headerName: 'Attività', flex: 2 },
  { field: 'date', headerName: 'Data', flex: 0.5 },
  { field: 'timeSpent', headerName: 'Ore', flex: 0.5, ...decimalFixed2ColumnOptions },
];

const getColumns = (groupBy) => {
  let columns = allColumns;
  if (groupBy !== REPORT_GROUP_BY.NONE) {
    if (groupBy === REPORT_GROUP_BY.PROJECT) {
      columns = allColumns.filter(i  => i.field !== 'issueSummary' && i.field !== 'userFullName');
    }
    columns = columns.filter(i  => i.field !== 'date');
  }
  return columns;
}

const getRows = ({
  data,
  filterProjectId,
  filterIssueId,
  filterUserIds,
  filterPeriodStart,
  filterPeriodEnd,
  groupBy,
}) => {

  // clone data
  let rows = JSON.parse(JSON.stringify(data));
  rows = rows.filter(i  => moment(i.date).isBetween(filterPeriodStart, filterPeriodEnd, 'days', []));
  if (filterUserIds.length > 0) {
      rows = rows.filter(i  => filterUserIds.includes(i.userId));
  }
  if (filterProjectId) {
      rows = rows.filter(i  => i.projectId === filterProjectId);
  }
  if (filterIssueId) {
      rows = rows.filter(i  => i.issueId === filterIssueId);
  }
  if (groupBy !== REPORT_GROUP_BY.NONE) {
    rows = rows.reduce((acc, cur) => {
      if (groupBy === REPORT_GROUP_BY.PROJECT) {
        const item = acc.find(i => i.projectId === cur.projectId);
        if (item) {
          item.timeSpent += cur.timeSpent;
        } else {
          acc.push(cur);
        }
      } else if (groupBy === REPORT_GROUP_BY.ISSUE) {
        const item = acc.find(i => i.issueId === cur.issueId);
        if (item) {
          item.timeSpent += cur.timeSpent;
        } else {
          acc.push(cur);
        }
      }
      return acc;
    }, []);
  }

  return rows;
}

export const ReportDataGrid = ({
    data,
    filterProjectId,
    filterProjectSummary,
    filterIssueId,
    filterIssueSummary,
    filterUserIds,
    filterPeriodStart,
    filterPeriodEnd,
    groupBy,    
}) => {   

    const columns = getColumns(groupBy);

    const rows = getRows({
      data,
      filterProjectId,
      filterIssueId,
      filterUserIds,
      filterPeriodStart,
      filterPeriodEnd,
      groupBy,
    });

    const totalHours = rows.reduce(    
      (acc, curr) => acc + curr.timeSpent, 0    
    );

    const footerData = [
      { id: 1, label: 'Ore totali', value: totalHours, ...decimalFixed2ValueFormatter}
    ];

    let csvFilename = `${moment().format('YYYY-MM-DD')} Report - dal ${filterPeriodStart.format('YYYY-MM-DD')} al ${filterPeriodEnd.format('YYYY-MM-DD')}`;
    if (filterProjectSummary) {
      csvFilename += ` - ${filterProjectSummary}`;
    }
    if (filterIssueSummary) {
      csvFilename += ` - ${filterIssueSummary}`;
    }

    return ( 
        <StripedDataGrid
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            density='compact'
            disableColumnMenu
            disableRowSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [
                        { field: 'userFullName', sort: 'asc' }
                    ],
            },
            pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            slots={{
                footer: DataGridFooter,
                toolbar: ExportToolbar,
            }}                      
            slotProps={{
                footer: { data: footerData },
                toolbar: { csvFilename }
            }}
        />
    )
};

ReportDataGrid.defaultProps = {
    data: [],
    filterProjectId: 0,
    filterProjectSummary: '',
    filterIssueId: 0, 
    filterIssueSummary: '',   
    filterUserIds: [],
    filterPeriodStart: moment(),
    filterPeriodEnd: moment(),
    groupBy: 0,
};

ReportDataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    filterProjectId: PropTypes.number,
    filterProjectSummary: PropTypes.string,
    filterIssueId: PropTypes.number,
    filterIssueSummary: PropTypes.string,  
    filterUserIds: PropTypes.arrayOf(PropTypes.number),
    filterPeriodStart: PropTypes.instanceOf(moment),
    filterPeriodEnd: PropTypes.instanceOf(moment),
    groupBy: PropTypes.number,
};
