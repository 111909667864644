import Avatar from './Avatar';

export const UserWithAvatarRenderer = (data) => {
  const {
    row: {
      original: { full_name: name, username },
    },
  } = data;
  return (
    <>
      <Avatar username={username} />
      <div className="fw-normal">{name}</div>
    </>
  );
};
