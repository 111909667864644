import PropTypes from 'prop-types';
import { Avatar, StyledSpinner } from './Styles';
import { useEffect, useState } from 'react';
import { avatarLoader } from './avatarLoader';

const LazyAvatar = ({ username }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (username) {
      avatarLoader.loadAvatar(username).then(setUrl);
    }
  }, [username]);
  return (
    <Avatar style={{ backgroundImage: `url("${url}")` }}>
      {!url && <StyledSpinner animation="border" variant="light" />}
    </Avatar>
  );
};

LazyAvatar.defaultProps = {
  username: undefined,
};

LazyAvatar.propTypes = {
  username: PropTypes.string,
};

export default LazyAvatar;
