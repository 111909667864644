import PropTypes from 'prop-types';

export const TextHighlight = ({ text, highlight }) => {
  const start = text.toLowerCase().indexOf(highlight.toLowerCase());
  if (!(highlight.length > 0) || start < 0) {
    return <span>{text}</span>;
  }
  const end = start + highlight.length;
  const prefix = text.substring(0, start);
  const middle = text.substring(start, end);
  const suffix = text.substring(end);
  return (
    <span>
      {prefix}
      <span className="text-highlight">{middle}</span>
      <TextHighlight text={suffix} highlight={highlight} />
    </span>
  );
};

TextHighlight.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};
