import PropTypes from 'prop-types';
import { itIT } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { StatusBadge } from '../../components/StatusBadge';
import { useMemo } from 'react';

export const RetrospectiveGoalsDataGrid = ({ data }) => {
  const outcomeLabelMap = {
    achieved: 'Raggiunto',
    partially_achieved: 'Parziale',
    not_achieved: 'Non raggiunto'
  };
  const outcomeStatusMap = {
    achieved: 'success',
    partially_achieved: 'warning',
    not_achieved: 'error'
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Data',
      width: 100,
      valueFormatter: ({ value }) => value ? new Date(value).toLocaleDateString() : ''
    },
    {
      field: 'type',
      headerName: 'Tipologia',
      flex: 1,
      valueGetter: (value) => value.row.type.name
    },
    { field: 'description', headerName: 'Descrizione', flex: 3 },
    {
      field: 'outcome', headerName: 'Esito', width: 150,
      valueGetter: (value) => ({
        value: value.row.outcome,
        label: outcomeLabelMap[value.row.outcome] || 'Esito mancante',
        status: outcomeStatusMap[value.row.outcome] || 'info'
      }),
      renderCell: (params) => <StatusBadge value={params.value.label} status={params.value.status} />,
      sortComparator: (v1, v2) => v1.label.localeCompare(v2.label)
    }
  ];

  const rows = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  return (
    <StripedDataGrid
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      rows={rows}
      columns={columns}
      density="standard"
      disableColumnMenu
      disableRowSelectionOnClick
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      getRowHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'name', sort: 'asc' }
          ]
        },
        pagination: {
          paginationModel: {
            pageSize: 100
          }
        }
      }}
      pageSizeOptions={[25, 50, 100]}
      sx={{
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
      }}
    />
  );
};

RetrospectiveGoalsDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

RetrospectiveGoalsDataGrid.defaultProps = {
  data: []
};
