import PropTypes from 'prop-types';
import { decimalFormatter } from '../../components/Table/utility';

export const TabTitle = ({ title, unit, maxAvailable }) => (
  <div className="tab-title">
    {title} - {decimalFormatter.format(Math.max(0, maxAvailable))}&nbsp;
    {unit}
  </div>
);

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  maxAvailable: PropTypes.number.isRequired,
};
TabTitle.defaultProps = { unit: '€' };
