import { Card } from 'react-bootstrap';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { RetrospectiveGoalsAndResultsItem } from './RetrospectiveGoalsAndResultsItem';
import { FieldArray } from 'formik';

const CARD_MARGIN = 50;
const StyledCard = Styled(Card)`
      margin-top: ${CARD_MARGIN}px;
      margin-bottom: ${CARD_MARGIN}px;
      border-radius: 12px;
      border: 1px solid var(--brand-light);
      padding: 2em;
    `;

export const RetrospectiveGoalsAndResults = ({
                                               objectName,
                                               data,
                                               typeList,
                                               year,
                                               mode,
                                               onDelete,
                                               metadata
                                             }) => (
  <StyledCard id={metadata?.id} ref={metadata?.ref}>
    <Card.Body>
      <Card.Title className="card-header-with-line">
        <div className="text-bold">Obiettivi {year}</div>
        <div className="text-small">{mode === 'review'
          ? 'Inserisci gli esiti relativi agli obiettivi dell’anno passato.'
          : 'Inserisci una descrizione dettagliata degli obiettivi annuali definiti per il collaboratore.'
        }</div>
      </Card.Title>
      <Card.Body>
        <FieldArray name={objectName}
                    render={arrayHelpers => (
                      <>
                        {data && data.length > 0 ?
                          data.map((currentGoal, index) => (
                            <div key={index}>
                              <RetrospectiveGoalsAndResultsItem
                                index={index}
                                objectName={objectName}
                                typeList={typeList}
                                mode={mode}
                                onDelete={() => onDelete(arrayHelpers, index)}
                              />
                              {index < data.length - 1 && <hr />}
                              {mode === 'edit' && index === data.length - 1 && (
                                <div className="card-footer-with-line">
                                  <a onClick={() => arrayHelpers.push({ description: '' })} className="text-link">
                                    + Aggiungi nuovo
                                  </a>
                                </div>
                              )}
                            </div>
                          ))
                          : mode === 'edit' && (
                          <div className="card-footer-without-line">
                            <a onClick={() => arrayHelpers.push({ description: '' })} className="text-link">
                              + Aggiungi nuovo
                            </a>
                          </div>
                        )}
                      </>
                    )}
        />
      </Card.Body>
    </Card.Body>
  </StyledCard>
);

RetrospectiveGoalsAndResults.propTypes = {
  objectName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    type: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      name: PropTypes.string,
      easy: PropTypes.string,
      hard: PropTypes.string
    }),
    reason: PropTypes.string,
    description: PropTypes.string,
    outcome: PropTypes.oneOf(['achieved', 'partially_achieved', 'not_achieved'])
  })).isRequired,
  typeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(['edit', 'review']),
  onDelete: PropTypes.func,
  metadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired
  })
};

RetrospectiveGoalsAndResults.defaultProps = {
  mode: 'edit',
  onDelete: () => undefined,
  metadata: undefined
};

export default RetrospectiveGoalsAndResults;
