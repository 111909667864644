const TargetIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.7625 10.7626C11.0907 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0908 13.75 11.5359 13.75 12C13.75 12.4641 13.5656 12.9093 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0907 13.5656 10.7625 13.2374C10.4344 12.9093 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0908 10.7625 10.7626ZM12 11.75C11.9337 11.75 11.8701 11.7763 11.8232 11.8232C11.7763 11.8701 11.75 11.9337 11.75 12C11.75 12.0663 11.7763 12.1299 11.8232 12.1768C11.8701 12.2237 11.9337 12.25 12 12.25C12.0663 12.25 12.1299 12.2237 12.1768 12.1768C12.2236 12.1299 12.25 12.0663 12.25 12C12.25 11.9337 12.2236 11.8701 12.1768 11.8232C12.1299 11.7763 12.0663 11.75 12 11.75Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.80546 7.21905C9.75104 6.58723 10.8627 6.25 12 6.25C12.4142 6.25 12.75 6.58579 12.75 7C12.75 7.41422 12.4142 7.75 12 7.75C11.1594 7.75 10.3377 7.99926 9.63881 8.46626C8.9399 8.93325 8.39517 9.59701 8.0735 10.3736C7.75183 11.1502 7.66766 12.0047 7.83165 12.8291C7.99564 13.6536 8.40041 14.4108 8.99478 15.0052C9.58916 15.5996 10.3464 16.0044 11.1709 16.1683C11.9953 16.3323 12.8498 16.2482 13.6264 15.9265C14.403 15.6048 15.0667 15.0601 15.5337 14.3612C16.0007 13.6623 16.25 12.8406 16.25 12C16.25 11.5858 16.5858 11.25 17 11.25C17.4142 11.25 17.75 11.5858 17.75 12C17.75 13.1372 17.4128 14.2489 16.7809 15.1945C16.1491 16.1401 15.2511 16.8771 14.2004 17.3123C13.1497 17.7475 11.9936 17.8614 10.8782 17.6395C9.76283 17.4177 8.73827 16.87 7.93412 16.0659C7.12997 15.2617 6.58234 14.2372 6.36047 13.1218C6.13861 12.0064 6.25247 10.8502 6.68768 9.79957C7.12288 8.7489 7.85987 7.85087 8.80546 7.21905Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.2778 3.46687C9.04636 2.48873 11.0752 2.08436 13.0836 2.30968C13.4952 2.35586 13.7915 2.72699 13.7453 3.13862C13.6991 3.55025 13.328 3.84651 12.9164 3.80033C11.2169 3.60967 9.50024 3.95183 8.00377 4.77949C6.5073 5.60714 5.305 6.87937 4.56319 8.4202C3.82138 9.96103 3.57672 11.6943 3.86306 13.3803C4.14939 15.0662 4.95257 16.6215 6.16149 17.8311C7.37041 19.0406 8.92532 19.8445 10.6111 20.1317C12.2969 20.4189 14.0303 20.1751 15.5715 19.4341C17.1127 18.6931 18.3856 17.4914 19.214 15.9954C20.0424 14.4993 20.3854 12.7828 20.1956 11.0832C20.1496 10.6716 20.4461 10.3006 20.8577 10.2546C21.2694 10.2087 21.6404 10.5051 21.6864 10.9168C21.9107 12.9253 21.5053 14.9539 20.5262 16.722C19.5472 18.49 18.043 19.9102 16.2215 20.786C14.4001 21.6617 12.3516 21.9498 10.3592 21.6104C8.3669 21.271 6.52929 20.3209 5.10056 18.8914C3.67184 17.462 2.72263 15.6239 2.38424 13.6314C2.04584 11.6389 2.33498 9.59051 3.21166 7.76953C4.08835 5.94855 5.50924 4.445 7.2778 3.46687Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.287 2.30709C18.5673 2.42318 18.75 2.69666 18.75 3V5.25H21C21.3033 5.25 21.5768 5.43274 21.6929 5.71299C21.809 5.99325 21.7448 6.31584 21.5303 6.53033L18.5303 9.53033C18.3897 9.67099 18.1989 9.75 18 9.75H15C14.5858 9.75 14.25 9.41422 14.25 9V6C14.25 5.80109 14.329 5.61033 14.4697 5.46967L17.4697 2.46967C17.6842 2.25518 18.0067 2.19101 18.287 2.30709ZM15.75 6.31066V8.25H17.6893L19.1893 6.75H18C17.5858 6.75 17.25 6.41422 17.25 6V4.81066L15.75 6.31066Z"
              fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.5303 8.46967C15.8232 8.76257 15.8232 9.23744 15.5303 9.53033L12.5303 12.5303C12.2374 12.8232 11.7625 12.8232 11.4697 12.5303C11.1768 12.2374 11.1768 11.7626 11.4697 11.4697L14.4697 8.46967C14.7625 8.17678 15.2374 8.17678 15.5303 8.46967Z"
              fill="currentColor"/>
    </svg>
);

export default TargetIcon;