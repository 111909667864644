import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserListContext } from '../../context/UserListContext';
import { UsersSelector } from '../../components/User/UsersSelector';

export const InterviewersSelector = ({
                                       name,
                                       interviewers,
                                       setInterviewers
                                     }) => {
  const { users, usersById } = useContext(UserListContext);
  const availableUsers = users.filter((u) => u.groups.some(g => ['amministrazione', 'direzione', 'adm'].includes(g)));

  return (
    <UsersSelector
      name={name}
      label="Intervistatori"
      availableUsers={availableUsers}
      usersById={usersById}
      selected={interviewers}
      setSelected={setInterviewers}
      fixedPromptLabel={true}
    />
  );
};

InterviewersSelector.propTypes = {
  name: PropTypes.string.isRequired,
  interviewers: PropTypes.arrayOf(PropTypes.number).isRequired,
  setInterviewers: PropTypes.func.isRequired
};
