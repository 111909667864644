import PropTypes from 'prop-types';
import { itIT, GridActionsCellItem } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { booleanColumnOptions } from '../../components/DataGrid/DataGridUtils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { openPreview } from '../../utils/API/fileDownload';
import moment from 'moment';
import { EARNEDCERTIFICATIONS_GROUP_BY } from '../../constants';
import { ExportToolbar } from '../../components/DataGrid/ExportToolbar';

const getColumns = ({
    isReadOnly,
    groupBy,
    onEditClick,
    onDeleteClick,
  }) => {

    let columns = [
        { field: 'userFullName', headerName: 'Utente', flex: 1.5 },        
        { field: 'certificationDescription', headerName: 'Certificazione', visibleWhenGrouped: true, flex: 1.5 },
        { field: 'certificationEmitterName', headerName: 'Ente', visibleWhenGrouped: true, flex: 1.25 },
        { field: 'certificationCategoryDescription', headerName: 'Categoria', visibleWhenGrouped: true, flex: 1.25 },
        { field: 'achievementDate', headerName: 'Data acquisizione', flex: 0.5 },
        { field: 'expirationDate', headerName: 'Data scadenza', visibleWhenGrouped: true, flex: 0.55 },
        { 
            field: 'isPublishable',
            headerName: 'Pubblicabile?',
            flex: 0.55,
            ...booleanColumnOptions,            
            valueGetter: ({ row }) => {
                if (!row.certificationIsPublishable) {
                  return false;
                }
                return row.isPublishable;
              },
        },
        { 
            field: 'attachment',
            headerName: 'File',
            type: 'actions',
            cellClassName: 'actions',
            disableExport: true,
            getActions: ({ row }) =>  {
              if (!row.attachment) {
                return [];
              }
              return [
                <GridActionsCellItem
                  icon={<FileDownloadOutlinedIcon />}
                  label="Download"
                  onClick={() => openPreview(row.attachment)}
                  color="inherit"
                  key={`download_${row.id}`}
                />,
              ]
            }
        }
    ];

    if (!isReadOnly) {
        columns.push({
            field: 'actions',
            headerName: 'Azioni',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => [
                    <GridActionsCellItem
                        icon={<EditOutlinedIcon />}
                        label="Edit"
                        onClick={() => onEditClick(id)}
                        color="inherit"
                        key={`edit_${id}`}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteOutlinedIcon />}
                        label="Delete"
                        onClick={() => onDeleteClick(id)}
                        color="inherit"
                        key={`delete_${id}`}
                    />,
                ]
        });
    }

    if (groupBy !== EARNEDCERTIFICATIONS_GROUP_BY.NONE) {
        if (groupBy === EARNEDCERTIFICATIONS_GROUP_BY.CERTIFICATION) {
            columns = columns.filter(i => i.visibleWhenGrouped);

            columns.push({ 
                field: 'count',
                headerName: 'Totale',
                flex: 0.5,
            });
        }
    }

    return columns;
}


const getRows = ({
    data,
    filterUsers,
    filterCertifications,
    filterCertificationCategories,
    filterCertificationEmitters,
    filterAchievementDateStart,
    filterAchievementDateEnd,
    filterExpirationDateStart,
    filterExpirationDateEnd,
    groupBy,
}) => {

    // clone data
    let rows = JSON.parse(JSON.stringify(data));
    if (filterUsers.length > 0) {
        rows = rows.filter(i  => filterUsers.includes(i.userId));
    }
    if (filterCertifications.length > 0) {
        rows = rows.filter(i  => filterCertifications.includes(i.certificationId));
    }
    if (filterCertificationCategories.length > 0) {
        rows = rows.filter(i  => filterCertificationCategories.includes(i.certificationCategoryId));
    }
    if (filterCertificationEmitters.length > 0) {
        rows = rows.filter(i  => filterCertificationEmitters.includes(i.certificationEmitterId));
    }
    if (filterAchievementDateStart) {
        rows = rows.filter(i  => moment(i.achievementDate, 'YYYY-MM-DD').isSameOrAfter(filterAchievementDateStart));
    }
    if (filterAchievementDateEnd) {
        rows = rows.filter(i  => moment(i.achievementDate, 'YYYY-MM-DD').isSameOrBefore(filterAchievementDateEnd));
    }
    if (filterExpirationDateStart) {
        rows = rows.filter(i  => i.expirationDate && moment(i.expirationDate, 'YYYY-MM-DD').isSameOrAfter(filterExpirationDateStart));
    }
    if (filterExpirationDateEnd) {
        rows = rows.filter(i  => i.expirationDate && moment(i.expirationDate, 'YYYY-MM-DD').isSameOrBefore(filterExpirationDateEnd));
    }
    if (groupBy !== EARNEDCERTIFICATIONS_GROUP_BY.NONE) {
        rows = rows.reduce((acc, cur) => {
        if (groupBy === EARNEDCERTIFICATIONS_GROUP_BY.CERTIFICATION) {
            let item = acc.find(i => i.certificationId === cur.certificationId);
            if (item) {
                item.count++;
                if (item.expirationDate && moment(cur.expirationDate, 'YYYY-MM-DD').isBefore(item.expirationDate, 'YYYY-MM-DD')) {
                    item.expirationDate = cur.expirationDate;
                }
            } else {
                item = cur;
                item.count = 1;
                acc.push(item);
            }
        }
        return acc;
        }, []);
    }

    return rows;
}

export const EarnedCertificationsDataGrid = ({
    data,
    isReadOnly,
    onEditClick,
    onDeleteClick,
    filterUsers,
    filterCertifications,
    filterCertificationCategories,
    filterCertificationEmitters,
    filterAchievementDateStart,
    filterAchievementDateEnd,
    filterExpirationDateStart,
    filterExpirationDateEnd,
    groupBy,
}) => {

    const columns = getColumns({
        isReadOnly,
        groupBy,
        onEditClick,
        onDeleteClick,
    });

    const rows = getRows({
        data,
        filterUsers,
        filterCertifications,
        filterCertificationCategories,
        filterCertificationEmitters,
        filterAchievementDateStart,
        filterAchievementDateEnd,
        filterExpirationDateStart,
        filterExpirationDateEnd,
        groupBy,
    });
  
    const csvFilename = `${moment().format('YYYY-MM-DD')} - Certificazioni ottenute`;

    return (
        <StripedDataGrid
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            density='compact'
            disableColumnMenu
            disableRowSelectionOnClick
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            initialState={{
                sorting: {
                    sortModel: [
                        { field: 'userFullName', sort: 'asc' }
                    ],
            },
            pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
                toolbar: ExportToolbar,
            }}                      
            slotProps={{
                toolbar: { csvFilename }
            }}
        />
    );
};

EarnedCertificationsDataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),  
    isReadOnly: PropTypes.bool,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    filterUsers: PropTypes.arrayOf(PropTypes.number),
    filterCertifications: PropTypes.arrayOf(PropTypes.number),
    filterCertificationCategories: PropTypes.arrayOf(PropTypes.number),
    filterCertificationEmitters: PropTypes.arrayOf(PropTypes.number),
    filterAchievementDateStart: PropTypes.instanceOf(moment),
    filterAchievementDateEnd: PropTypes.instanceOf(moment),
    filterExpirationDateStart: PropTypes.instanceOf(moment),
    filterExpirationDateEnd: PropTypes.instanceOf(moment),
    groupBy: PropTypes.number,
};

EarnedCertificationsDataGrid.defaultProps = {
    data: [], 
    isReadOnly: true, 
    onEditClick: () => {},
    onDeleteClick: () => {},
    filterUsers: [],
    filterCertifications: [],
    filterCertificationCategories: [],
    filterCertificationEmitters: [],
    filterAchievementDateStart: undefined,
    filterAchievementDateEnd: undefined,
    filterExpirationDateStart: undefined,
    filterExpirationDateEnd: undefined,
    groupBy: 0,
};
