import PropTypes from 'prop-types';

export const IntegerCell = ({ value, className = '' }) => (
  <div className={`w-100 ${className}`}>{value}</div>
);

IntegerCell.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};

IntegerCell.defaultProps = { className: '' };
