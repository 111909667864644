/* eslint-disable react/prop-types */

import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { useState } from 'react';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import DropFileZone from '../../components/Form/DropFileZone';
import { createCertification, updateCertification } from '../../utils/API/certifications';
import FormSelect from '../../components/Form/FormSelect';

async function onSubmit(values, actions, onClose, onDataChanged, setExecuteRemoveSelectedFiles) {
  const { setSubmitting, resetForm } = actions;
  const { id, createAnother, description, isPublishable, attachment, emitterId, categoryId } = values;

  const data = {
    description,
    isPublishable,
    badge: attachment,
    emitterId,
    categoryId,
  }

  let item;
  try {
    if (id) {
      item = await updateCertification(id, data);
    } else {
      item = await createCertification(data);
    }
    onDataChanged(item);
    if (!createAnother) {
      onClose();
    } else {
      resetForm();
      setExecuteRemoveSelectedFiles(true);
    }
  } finally {
    setSubmitting(false);    
  }
}

const validationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obbligatorio'),
  emitterId: Yup.number().required('Campo obbligatorio'),
  categoryId: Yup.number().required('Campo obbligatorio'),
});

export const CertificationForm = ({
  certification,
  emitters,
  categories,
  onClose,
  onDataChanged
}) => {
  const [executeRemoveSelectedFiles, setExecuteRemoveSelectedFiles] = useState(false);
  const [isFileAttached, setIsFileAttached] = useState(false);

  const getOptions = (items, fieldName) => {
    const html = [];
    items.forEach((item) => {
      html.push(
        <option
          value={item.id}
          key={item.id}
        >
          {item[fieldName]}
        </option>
      );
    });
    return html;
  };

  const initialValues = {
    id: undefined,
    description: '',
    badge: '',
    categoryId: 0,
    emitterId: 0,
    isPublishable: false,
    createAnother: false,
  };

  let isNew = true;
  if (certification?.id) {
    isNew = false;
    Object.assign(initialValues, certification);
  }

  const attachments = [];
  if (certification && certification.badge) {
    const fileData = { name: '<badge>' };
    attachments.push({ fileData, deleted: false });
  }
  
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, onClose, onDataChanged, setExecuteRemoveSelectedFiles);
      }}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
        setFieldValue,
      }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>          
          <FormItem
            type="textarea"
            name="description"
            label="Descrizione *"
          />
          <FormSelect
            name="emitterId"
            label="Ente *"
            options={getOptions(emitters, 'name')}
          />
          <FormSelect
            name="categoryId"
            label="Categoria *"
            options={getOptions(categories, 'description')}
          />
          <FormCheckbox
            name="isPublishable"
            label="Pubblicabile"
          />
          <DropFileZone
            name="badge"
            fileTypes=".png"
            text="Trascina il file oppure fai click per aggiungere il badge"
            setFieldValue={setFieldValue}
            attachments={attachments}
            executeRemoveSelectedFiles={executeRemoveSelectedFiles}
            setExecuteRemoveSelectedFiles={setExecuteRemoveSelectedFiles}
            setIsFileAttachment={setIsFileAttached}
          />
          <div className="buttonContent">
            {isNew && (
              <FormCheckbox
                name="createAnother"
                label="Aggiungi un'altra"
              />
            )}
            <div className="spacer" />
            <Button
              onClick={onClose}
              variant='secondary'
            >
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !isValid || !isFileAttached}
            >
              {isNew ? 'Inserisci' : 'Aggiorna'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
