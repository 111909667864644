import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { AnchorElement } from './PageAnchorElement';
import PropTypes from 'prop-types';

const Anchor = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 20px;
    gap: 12px;
    @media (max-width: 1080px) {
        display: none;
    }
`;

export const PageAnchors = ({
                              sections
                            }) => {
  const [visibleSection, setVisibleSection] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setVisibleSection(entry.target.id);
            break;
          }
        }
      },
      {
        /**
         * This rootMargin creates a horizontal line at 25% of the viewport height
         * that will help trigger an intersection at that the very point.
         */
        rootMargin: '-75% 0% -75% 0%',

        /**
         * This is the default so you could remove it.
         * I just wanted to leave it here to make it more explicit
         * as this threshold is the only one that works with the above
         * rootMargin
         */
        threshold: 0
      }
    );

    sections.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sections.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sections]);

  const isVisible = useCallback(
    (anchor) => anchor === visibleSection,
    [visibleSection]
  );

  return (
    <Anchor>
      {sections.map(({ anchor, name }) => (
        <div
          key={`page_anchor_${anchor}`}
          style={{
            display: 'block'
          }}
        >
          <AnchorElement
            anchor={`#${anchor}`}
            isVisible={isVisible(anchor)}
            name={name}
          />
        </div>
      ))}
    </Anchor>
  );
};

PageAnchors.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    anchor: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired
  })).isRequired
};

PageAnchors.defaultProps = {};
