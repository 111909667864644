import { getUserAvatar } from '../../utils/API/user';
import _ from 'lodash';

const avatarBase64 = async (response) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => resolve(reader.result);
  });

const loadAvatar = async (username) => {
  let avatar;
  try {
    const image = await getUserAvatar(username).catch();
    if (image) {
      avatar = await avatarBase64(image);
    }
  } catch (e) {
    avatar =
      'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';
  }
  return avatar;
};

export const avatarLoader = {
  loadAvatar: _.memoize(loadAvatar),
};
