const EmailSentIcon = () => (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1975_24127)">
      <path d="M8.33301 11.6667L17.4997 2.5" stroke="#696F7C" strokeWidth="1.25" strokeLinecap="round"
            strokeLinejoin="round" />
      <path
        d="M17.4998 2.5L12.0831 17.5C12.0466 17.5798 11.9879 17.6474 11.914 17.6948C11.8402 17.7422 11.7542 17.7674 11.6665 17.7674C11.5787 17.7674 11.4928 17.7422 11.4189 17.6948C11.3451 17.6474 11.2864 17.5798 11.2498 17.5L8.33315 11.6667L2.49981 8.75C2.42003 8.71344 2.35242 8.65474 2.30502 8.58088C2.25762 8.50701 2.23242 8.4211 2.23242 8.33333C2.23242 8.24557 2.25762 8.15965 2.30502 8.08579C2.35242 8.01193 2.42003 7.95323 2.49981 7.91667L17.4998 2.5Z"
        stroke="#696F7C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <g clipPath="url(#clip1_1975_24127)">
      <path
        d="M23.0312 11.5276C23.6251 11.8705 24.1192 12.3625 24.4644 12.955C24.8097 13.5475 24.9942 14.22 24.9998 14.9057C25.0053 15.5914 24.8317 16.2667 24.496 16.8647C24.1604 17.4627 23.6743 17.9627 23.0861 18.3152C22.4978 18.6676 21.8277 18.8603 21.1421 18.8742C20.4565 18.8881 19.7791 18.7226 19.177 18.3943C18.575 18.0659 18.0692 17.586 17.7096 17.0021C17.35 16.4182 17.1492 15.7504 17.127 15.065L17.125 14.9375L17.127 14.8099C17.149 14.1299 17.3469 13.4672 17.7013 12.8864C18.0557 12.3056 18.5546 11.8266 19.1492 11.496C19.7439 11.1655 20.4141 10.9946 21.0944 11.0001C21.7747 11.0057 22.442 11.1874 23.0312 11.5276ZM22.5221 13.8716C22.4543 13.8038 22.3641 13.7631 22.2684 13.757C22.1727 13.751 22.0781 13.7801 22.0024 13.8389L21.9654 13.8716L20.6687 15.1678L20.1596 14.6591L20.1226 14.6264C20.0469 14.5677 19.9523 14.5386 19.8566 14.5446C19.7609 14.5507 19.6708 14.5914 19.603 14.6592C19.5352 14.727 19.4945 14.8171 19.4884 14.9128C19.4824 15.0085 19.5115 15.1031 19.5702 15.1788L19.6029 15.2158L20.3904 16.0033L20.4274 16.036C20.4964 16.0896 20.5813 16.1187 20.6687 16.1187C20.7561 16.1187 20.8411 16.0896 20.9101 16.036L20.9471 16.0033L22.5221 14.4283L22.5548 14.3913C22.6136 14.3156 22.6427 14.221 22.6367 14.1253C22.6307 14.0296 22.5899 13.9394 22.5221 13.8716Z"
        fill="#17A905" />
    </g>
    <defs>
      <clipPath id="clip0_1975_24127">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_1975_24127">
        <rect width="9" height="9" fill="white" transform="translate(16 11)" />
      </clipPath>
    </defs>
  </svg>

);

export default EmailSentIcon;
