/* eslint comma-dangle: [1, "never"] */
/* eslint-disable react/jsx-props-no-spreading */

import { CloudUpload, Reply, Trash } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';

import Dropzone from 'react-dropzone';
import { FormError } from '.';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import _ from 'lodash';
import cn from 'classnames';
import { error } from '../../utils/notification';

const DropzoneContent = Styled.div`
  border: 1px solid var(--gray-90);
  border-radius: 8px;
  text-align: center;
  padding: 17px;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--text-color);
`;

const DropzoneP = Styled.p`
  margin-bottom: 0px;
`;

const SelectedFileWrapper = Styled.aside`
  margin-bottom: 20px;
`;

const CardHeader = Styled.div`
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    color: var(--text-color);
    opacity: 1;
    margin-bottom: 0.5rem;
`;

const Ul = Styled.ul`
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 6px;
`;

const Li = Styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LiError = Styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: darkred;
  font-size: 12px;
  font-weight: bold;
`;

const CardBody = Styled.div`
    height: 40px;
    border: 1px solid var(--gray-90);
    border-radius: 6px;
    opacity: 1;
    letter-spacing: 0px;
    min-width: 248px;
    color: var(--black);
    padding: 0 16px;
    font-weight: bold;
    outline: none;
    font-size: 15px;
`;

const DropFileZone = (props) => {
  const {
    name,
    setFieldValue,
    attachments,
    fileTypes,
    text,
    executeRemoveSelectedFiles,
    setExecuteRemoveSelectedFiles,
    setIsFileAttachment
  } = props;
  const [selectedFiles, setSelectedFiles] = useState(attachments || []);
  const onDrop = (files) => {
    if (selectedFiles.length > 1) {
      error('Errore', 'Puoi allegare solo un file');
      return;
    }
    if (files.length > 0) {
      const i3Files = files.map((f) => ({ fileData: f, deleted: false }));
      setSelectedFiles([...selectedFiles, ...i3Files]);
      setIsFileAttachment(true);
    }
  };

  useEffect(() => {
    if (executeRemoveSelectedFiles) {
      setSelectedFiles([]);
      setExecuteRemoveSelectedFiles(false);
    }
  }, [executeRemoveSelectedFiles]);

  useEffect(() => {
    if (attachments.length) {
      setIsFileAttachment(true);
    }
  }, []);

  const removeFile = (i) => {
    let selectedCloned = _.cloneDeep(selectedFiles);
    const file = selectedCloned[i];
    if (file.fileData.path) {
      selectedCloned = _.remove(selectedCloned, (n, index) => i !== index);
      selectedCloned = selectedCloned.map((f) => ({ ...f, deleted: false }));
    } else {
      selectedCloned[i].deleted = true;
    }
    setSelectedFiles(selectedCloned);
    setIsFileAttachment(false);
  };

  const restoreFile = (i) => {
    const selectedCloned = _.cloneDeep(selectedFiles);
    const file = selectedCloned[i];
    file.deleted = false;
    setSelectedFiles([file]);
    setIsFileAttachment(true);
  };

  const removeButton = (file, i) => (
    <div role="button" tabIndex="-1">
      <Trash
        size="16px"
        color="var(--brand)"
        style={{ display: file.deleted ? 'none' : '' }}
        onClick={() => removeFile(i)}
        onKeyDown={() => removeFile(i)}
      />
      <Reply
        size="16px"
        color="var(--brand)"
        style={{ display: !file.deleted ? 'none' : '' }}
        onClick={() => restoreFile(i)}
        onKeyDown={() => restoreFile(i)}
      />
    </div>
  );

  const renderFileList = () => {
    const html = [];
    const removePath = (filePath) => {
      let elements = _.split(filePath, '_');
      if (elements.length > 1) {
        elements = _.drop(elements);
      }
      return _.join(elements, '_');
    };
    if (selectedFiles && selectedFiles.length > 0) {
      _.forEach(selectedFiles, (file, i) => {
        html.push(
          <Li style={{ opacity: file.deleted ? '0.5' : '1' }} key={`li_${i}`}>
            {removePath(file.fileData.name, '_')}
            {removeButton(file, i)}
          </Li>
        );
      });
      return html;
    }
    return <></>;
  };

  const fileRejectionItems = (fileRejections) => {
    const html = [];
    fileRejections.forEach(({ file, errors }) => {
      html.push(
        <LiError key={file?.path} className="error">
          {file?.path}
          <Ul>
            {errors.map((e) => (
              <Li key={e.code}>{e.message}</Li>
            ))}
          </Ul>
        </LiError>
      );
    });
    return html;
  };

  const dropZoneContent = (getRootProps, getInputProps) => {
    if (
      selectedFiles.length === 0 ||
      (selectedFiles.length === 1 && _.find(selectedFiles, { deleted: true }))
    ) {
      return (
        <>
          <DropzoneContent {...getRootProps()}>
            <input {...getInputProps()} name={name} />
            <CloudUpload size="32" />
            <div>
              <DropzoneP>
                {text} ({fileTypes})
              </DropzoneP>
            </div>
          </DropzoneContent>
          <FormError {...props} />
        </>
      );
    }
    return '';
  };

  const dropZoneFiles = (classesListFiles, fileRejections) => {
    if (
      !(
        selectedFiles.length === 0 ||
        (selectedFiles.length === 1 && _.find(selectedFiles, { deleted: true }))
      )
    ) {
      return (
        <>
          <SelectedFileWrapper>
            <CardHeader>Allegato</CardHeader>
            <CardBody className={classesListFiles}>
              <Ul>
                {renderFileList()}
                {fileRejectionItems(fileRejections)}
              </Ul>
            </CardBody>
          </SelectedFileWrapper>
        </>
      );
    }
    return '';
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFieldValue('attachment', selectedFiles);
    }
  }, [selectedFiles]);

  return (
    <div className="drop-file">
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        accept={fileTypes}
        maxFiles={1}
        maxSize={10000000}
      >
        {({ getRootProps, getInputProps, fileRejections }) => {
          const classesListFiles = cn('card', {
            hidden:
              ((!selectedFiles || selectedFiles.length === 0) &&
                (!fileRejections || fileRejections.length === 0)) ||
              false
          });

          return (
            <section>
              {dropZoneContent(getRootProps, getInputProps)}
              {dropZoneFiles(classesListFiles, fileRejections)}
            </section>
          );
        }}
      </Dropzone>
    </div>
  );
};
DropFileZone.defaultProps = {
  name: '',
  setFieldValue: () => {},
  attachments: [],
  fileTypes: '.pdf, .png',
  text: 'Trascina il file oppure fai click per selezionare il file',
  executeRemoveSelectedFiles: false,
  setExecuteRemoveSelectedFiles: () => {},
  setIsFileAttachment: () => {},
  isFileAttachment: false
};

DropFileZone.propTypes = {
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  attachments: PropTypes.instanceOf(Array),
  fileTypes: PropTypes.string,
  text: PropTypes.string,
  executeRemoveSelectedFiles: PropTypes.bool,
  setExecuteRemoveSelectedFiles: PropTypes.func,
  setIsFileAttachment: PropTypes.func,
  isFileAttachment: PropTypes.bool
};

export default DropFileZone;
