import PropTypes from 'prop-types';
import { itIT, GridActionsCellItem } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { booleanColumnOptions } from '../../components/DataGrid/DataGridUtils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Avatar from '@mui/material/Avatar';

const getColumns = ({
    onEditClick,
    onDeleteClick
  }) =>
    [
        { 
            field: 'badge', 
            headerName: 'Badge',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => 
                (
                    <Avatar sx={{ width: 80, height: 80 }} src={`data:image/png;base64, ${params.value}`}/>
                )
            ,
        },
        { field: 'description', headerName: 'Descrizione', flex: 1.5 },
        { field: 'emitterName', headerName: 'Ente', flex: 1 },
        { field: 'categoryDescription', headerName: 'Categoria', flex: 1 },
        { field: 'isPublishable', headerName: 'Pubblicabile?', ...booleanColumnOptions, flex: 0.5 },
        {
            field: 'actions',
            headerName: 'Azioni',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => [
            <GridActionsCellItem
                icon={<EditOutlinedIcon />}
                label="Edit"
                onClick={() => onEditClick(id)}
                color="inherit"
                key={`edit_${id}`}
            />,
            <GridActionsCellItem
                icon={<DeleteOutlinedIcon />}
                label="Delete"
                onClick={() => onDeleteClick(id)}
                color="inherit"
                key={`delete_${id}`}
            />,
            ]
        }
    ];
  
export const CertificationsDataGrid = ({
    certifications,
    onEditClick,
    onDeleteClick,
}) => {

    const columns = getColumns({
        certifications,
        onEditClick,
        onDeleteClick,
    });

    return (
        <StripedDataGrid
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={certifications}
            columns={columns}
            rowHeight={100}
            disableColumnMenu
            disableRowSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [
                        { field: 'description', sort: 'asc' }
                    ],
            },
            pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
        />
    );
};

CertificationsDataGrid.propTypes = {
    certifications: PropTypes.arrayOf(PropTypes.object),  
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

CertificationsDataGrid.defaultProps = {
    certifications: [],  
    onEditClick: () => {},
    onDeleteClick: () => {},
};
