/* eslint-disable no-undef */
import request from '../axios';
import { API } from '../../constants';
import { success, error } from '../notification';

export const getUser = () =>
  request.get(API.CURRENT_USER).then((res) => ({ ...res.data }));

export const getUserList = () =>
  request.get(API.USER_LIST).then((res) => ({ ...res.data }));

export const updateUser = (user) =>
  request
    .put(API.CURRENT_USER, user)
    .then((res) => {
      success('Esito', 'Salvataggio dati utente riuscito');
      return { ...res.data };
    })
    .catch((e) => {
      error('Errore', 'Modifica utente fallita');
      throw e;
    });

export const uploadSignature = async (file) => {
  const formData = new FormData();
  formData.append('file_uploaded', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return request
    .post(API.SIGNATURE, formData, config)
    .then(() => {
      success('Esito', 'Firma caricata correttamente');
    })
    .catch((e) => {
      error('Errore', 'Caricamento firma fallita');
      throw e;
    });
};

export const uploadAvatar = async (file) => {
  const formData = new FormData();
  formData.append('file_uploaded', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return request
    .post(API.AVATAR, formData, config)
    .then(() => {
      success('Esito', 'Avatar caricato correttamente');
    })
    .catch((e) => {
      error('Errore', 'Caricamento avatar fallito');
      throw e;
    });
};

export const getSignature = () =>
  request
    .get(API.SIGNATURE, { responseType: 'blob' })
    .then((res) => ({ ...res }));

export const getAvatar = () =>
  request.get(API.AVATAR, { responseType: 'blob' }).then((res) => ({ ...res })).catch(() => {
    error('Errore', 'Foto non trovata');
  });

export const getUserAvatar = (username) =>
  request
    .get(`${API.USER_AVATAR}/${username}`, { responseType: 'blob' })
    .then((res) => ({ ...res }));

export const sendEmail = (data) =>
  request
    .post(API.USERS_SEND_MAIL, data)
    .then((res) => ({ ...res }))
    .catch((e) => {
      error('Errore invio email', 'sadasd');
      throw new Error(e);
    });

export default getUser;
