import Styled from 'styled-components';
import BTable from 'react-bootstrap/Table';

// TODO: move in new file, refactor with a styled component for each sub component
// language=SCSS
export const StyledTable = Styled(BTable)`
  & {
    margin-bottom: 0 !important;
  }

  &.sticky {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
      min-width: 100%;
    }

    .header {
      top: 0;
    }

    .footer {
      bottom: 0;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  thead {
    background-color: #ffffff !important;
    border-style: none !important;

    tr {
      height: 48px;
      min-width: 100%;

      th {
        border: none;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0;
        color: var(--brand-dark);
        text-transform: uppercase;
        background-color: #ffffff;
        align-items: center;
        display: flex;
      }
    }
  }

  tbody {
    border-top: none !important;

    tr {
      border-style: none;
      min-width: 100%;

      &:nth-child(even) {
        background-color: var(--gray-95);
      }

      &:last-child {
        border-bottom: 0;
      }

      td {
        letter-spacing: 0;
        font-size: 14px;
        --bs-table-accent-bg: none !important;
        color: var(--text-color) !important;
        border: none;
        vertical-align: middle;
        align-items: center;
        display: flex;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  .tableAction {
    display: flex;
    justify-content: center;
    width: 100%;

    .icon {
      padding: 4px;
      cursor: pointer;
    }
  }

  .text-center {
    justify-content: center !important;
  }
`;
