import '../../css/headerPage.scss';

import { Button } from 'react-bootstrap';
import MonthNav from '../MonthNav';
import { PlusCircle } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';

export const MonthNavHeader = ({
  title,
  onInsert,
  showInsertButton,
  children,
}) => (
  <div className="headerPage content">
    <div className="title">{title}</div>
    <div className="spacer" />
    {children}
    <div className="monthNavContainer">
      <MonthNav />
    </div>
    {showInsertButton && (
      <div className="buttonGroup">
        <Button variant="primary" onClick={onInsert}>
          <PlusCircle />
          Inserisci
        </Button>
      </div>
    )}
  </div>
);

MonthNavHeader.defaultProps = {
  showInsertButton: false,
  onInsert: () => {},
  children: <></>,
};

MonthNavHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showInsertButton: PropTypes.bool,
  onInsert: PropTypes.func,
  children: PropTypes.node,
};
