import { API } from '../../constants';
import request from '../axios';
import { error, success } from '../notification';

export const getRetrospectiveByYearCompanyAndUsernames = async (year, company, usernames = []) => {
  try {
    const { data } = await request.get(`${API.RETROSPECTIVE}?year=${year}&company=${company}&usernames=${usernames}`);
    return data;
  } catch (e) {
    error('Errore', 'Caricamento fallito');
    throw e;
  }
};

export const getRetrospective = async (id = []) => {
  try {
    const { data } = await request.get(`${API.RETROSPECTIVE}${id}`);
    return {
      ...data,
      interviewers: (data.interviewers || []).map((interviewer) => interviewer.id)
    };
  } catch (e) {
    error('Errore', 'Caricamento fallito');
    throw e;
  }
};

export const createRetrospective = async (data) => {
  try {
    const res = await request.post(API.RETROSPECTIVE, data);
    return res.data;
  } catch (e) {
    error('Errore', 'Inserimento fallito');
    throw e;
  }
};

export const deleteRetrospective = async (id) => {
  try {
    await request.delete(`${API.RETROSPECTIVE}${id}/`);
    success('Esito', 'Cancellazione avvenuta con successo');
  } catch (e) {
    error('Errore', 'Cancellazione fallita');
    throw e;
  }
};

export const updateRetrospective = async (id, data) => {
  try {
    const res = await request.put(`${API.RETROSPECTIVE}${id}/`, data);
    success('Esito', 'Aggiornamento riuscito');
    return res.data;
  } catch (e) {
    error('Errore', 'Aggiornamento fallito');
    throw e;
  }
};

export const notifyRetrospective = async (id, data) => {
  try {
    const res = await request.post(`${API.RETROSPECTIVE}${id}/notify`, data);
    success('Esito', 'Email inviata');
    return res.data;
  } catch (e) {
    error('Errore', 'Invio email fallito');
    throw e;
  }
};

export const getRetrospectiveUsersByYear = async (year) => {
  try {
    const { data } = await request.get(`${API.USER_RETROSPECTIVE}?year=${year}`);
    return data;
  } catch (e) {
    error('Errore', 'Caricamento fallito');
    throw e;
  }
};