import styled from 'styled-components';
import PropTypes from 'prop-types';

const AnchorCurrentElementBar = styled.div`
    height: 42px;
    width: 4px;
    border-radius: 4px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    background-color: var(--brand);
    transition: opacity 0.3s ease-in-out;
`;

const AnchorLink = styled.a`
    height: 50px;
    display: flex;
    gap: 12px;
    align-items: center;
    text-decoration: none;
`;

const AnchorDiv = styled.div`
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 16px 4px 0px;
`;

const AnchorTitle = styled.h3`
    color: ${(props) =>
            props.$isComplete || props.$isVisible ? 'black' : 'var(--text-color)'};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
`;

export const AnchorElement = ({
                                anchor,
                                name,
                                isVisible = false
                              }) => {
  const handleClick = (event) => {
    event.preventDefault();
    const anchorElement = document.getElementById(anchor.replace('#', ''));
    anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <AnchorLink href={anchor} onClick={handleClick}>
      <AnchorCurrentElementBar isVisible={isVisible} />
      <AnchorDiv>
        <AnchorTitle $isVisible={isVisible}>{name}</AnchorTitle>
      </AnchorDiv>
    </AnchorLink>
  );
};

AnchorElement.propTypes = {
  anchor: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool
};

AnchorElement.defaultProps = {
  isVisible: false
};