import { useContext, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import Styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import ProfileForm from './form';

const CARD_MARGIN = 50;

const StyledCard = Styled(Card)`
  margin-top: ${CARD_MARGIN}px;
  margin-bottom: ${CARD_MARGIN}px;
  border-radius: 12px;
  border: 1px solid var(--brand-light);
  padding: 2em;
`;

const Profile = () => {
  const { user } = useContext(UserContext);
  const [system] = useState({});

  return (
    <Container>
      <StyledCard>
        <Card.Body>
          <ProfileForm user={user} system={system} />
        </Card.Body>
      </StyledCard>
    </Container>
  );
};

export default Profile;
