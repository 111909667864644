const CheckProgressIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.57983 11.9561C3.7175 11.7167 4.02316 11.6342 4.26254 11.7719C4.73506 12.0437 5.24561 12.2431 5.7772 12.3637C6.0465 12.4248 6.2153 12.6926 6.15421 12.9619C6.09313 13.2312 5.8253 13.4 5.556 13.3389C4.92707 13.1963 4.32304 12.9603 3.76399 12.6388C3.52461 12.5011 3.44216 12.1954 3.57983 11.9561Z" fill="currentColor" />
        <path d="M7.84582 1.03734C7.9073 0.768128 8.17538 0.599732 8.44459 0.661217C9.88045 0.989149 11.1624 1.79485 12.0806 2.94642C12.9988 4.09798 13.4989 5.52717 13.4989 7C13.4989 8.47282 12.9988 9.90202 12.0806 11.0536C11.1624 12.2051 9.88045 13.0108 8.44459 13.3388C8.17538 13.4003 7.9073 13.2319 7.84582 12.9627C7.78433 12.6934 7.95273 12.4254 8.22194 12.3639C9.43696 12.0864 10.5218 11.4046 11.2988 10.4301C12.0758 9.45569 12.4989 8.24631 12.4989 7C12.4989 5.75369 12.0758 4.54431 11.2988 3.56985C10.5218 2.5954 9.43696 1.91361 8.22194 1.63611C7.95273 1.57463 7.78433 1.30655 7.84582 1.03734Z" fill="currentColor" />
        <path d="M1.09574 8.187C1.36102 8.11031 1.63824 8.26319 1.71493 8.52847C1.87815 9.09308 2.13124 9.62771 2.46447 10.1118C2.62103 10.3393 2.56355 10.6506 2.33609 10.8072C2.10862 10.9638 1.7973 10.9063 1.64073 10.6788C1.24663 10.1062 0.947311 9.47394 0.754268 8.80619C0.677577 8.54091 0.830459 8.26369 1.09574 8.187Z" fill="currentColor" />
        <path d="M2.0376 3.57598C2.27914 3.70982 2.36645 4.01413 2.23261 4.25567L2.12287 4.45372C1.86033 4.95639 1.6731 5.50446 1.57565 6.08304C1.52979 6.35535 1.27186 6.53892 0.999558 6.49306C0.72725 6.44719 0.54368 6.18927 0.589543 5.91696C0.7051 5.23084 0.927596 4.58066 1.23965 3.98472L1.24513 3.97426L1.35792 3.771C1.49175 3.52946 1.79606 3.44215 2.0376 3.57598Z" fill="currentColor" />
        <path d="M6.15417 1.03787C6.21536 1.30714 6.04668 1.57504 5.7774 1.63623C5.1008 1.78999 4.45964 2.07108 3.88811 2.46451C3.66066 2.6211 3.34933 2.56364 3.19275 2.33618C3.03617 2.10872 3.09363 1.7974 3.32108 1.64082C3.9971 1.17545 4.75549 0.842963 5.5558 0.661096C5.82508 0.599904 6.09298 0.76859 6.15417 1.03787Z" fill="currentColor" />
        <path d="M9.35349 5.31311C9.54875 5.50837 9.54875 5.82496 9.35349 6.02022L6.68682 8.68689C6.49156 8.88215 6.17497 8.88215 5.97971 8.68689L4.64638 7.35355C4.45112 7.15829 4.45112 6.84171 4.64638 6.64645C4.84164 6.45118 5.15822 6.45118 5.35349 6.64645L6.33327 7.62623L8.64638 5.31311C8.84164 5.11785 9.15822 5.11785 9.35349 5.31311Z" fill="currentColor" />
    </svg>
);

export default CheckProgressIcon;
