/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';

import { Button, Form } from 'react-bootstrap';
import { FormError, FormField } from '../../components/Form';
import { Form as FormikForm, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { saveTrip, updateTrip } from '../../utils/API/trips';
import moment from 'moment';
import FormItem from '../../components/Form/FormItem';
import FormDatePicker from '../../components/Form/FormDatePicker';

const Checkbox = ({ field, ...props }) => <Form.Check {...field} {...props} />;

const initialValues = (currentData, kmCost) => ({
  id: currentData.id || undefined,
  date: currentData.date ? moment(currentData.date) : moment(),
  description: currentData.description || '',
  kilometers: currentData.kilometers || '',
  kilometer_cost: currentData.kilometer_cost || kmCost,
});

async function onSubmit(
  values,
  { setSubmitting, resetForm, setFieldValue },
  close,
  updateTrips
) {
  const cleanedValues = {
    ...values,
    kilometers: +values.kilometers.toString().replace(',', '.'),
    kilometer_cost: +values.kilometer_cost.toString().replace(',', '.'),
    date: values.date.format('YYYY-MM-DD'),
  };
  const { createAnother } = values;
  const { id } = values;
  try {
    if (id) {
      await updateTrip(cleanedValues);
    } else {
      await saveTrip(cleanedValues);
    }
  } finally {
    setSubmitting(false);
    if (!values.createAnother) {
      close();
    } else {
      updateTrips();
      resetForm();
      setFieldValue('createAnother', createAnother);
    }
  }
}

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Campo obbligatorio'),
  description: Yup.string()
    .required('Campo obbligatorio')
    .min(5, 'Lunghezza minima 5 caratteri')
    .max(50, 'Lunghezza massima 50 caratteri'),
  kilometers: Yup.number()
    .required('Campo obbligatorio')
    .integer('Inserire un valore intero')
    .positive("Valore non ammesso, dev'essere maggiore di 0"),
  kilometer_cost: Yup.number()
    .required('Campo obbligatorio')
    .positive("Valore non ammesso, dev'essere maggiore di 0"),
});

export const TripForm = ({
  close,
  currentTrip,
  kmCost,
  month,
  updateTrips,
}) => {
  const [totalExpectedCost, setTotalExpectedCost] = useState(0);

  const CalculatedField = (_props) => {
    const { values } = _props;

    useEffect(() => {
      // update totals
      let val = 0;
      if (values.kilometer_cost && values.kilometers) {
        val = values.kilometer_cost * values.kilometers;
      }
      if (!Number.isNaN(val)) {
        setTotalExpectedCost(Math.floor(val * 100) / 100);
      }
    }, [values]);

    return (
      <Form.Group controlId="formKilometerCost" className="form-group">
        <Form.Label>Costo totale previsto</Form.Label>
        <FormField
          type="input"
          name="total_expected_cost"
          value={totalExpectedCost}
          disabled
        />
        <FormError name="total_expected_cost" />
      </Form.Group>
    );
  };

  const isNew = !currentTrip?.id;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, updateTrips);
      }}
      initialValues={initialValues(currentTrip, kmCost)}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormDatePicker
            name="date"
            label="Data Viaggio *"
            minDate={month.clone().startOf('month')}
            maxDate={month.clone().endOf('month')}
          />
          <FormItem
            type="textarea"
            name="description"
            rows="1"
            label="Descrizione *"
            className="form-group"
          />
          <FormItem
            type="number"
            step={1}
            pattern="[0-9]+"
            name="kilometers"
            label="Km *"
            className="form-group"
          />
          <FormItem
            type="number"
            pattern="[0-9]+"
            name="kilometer_cost"
            label="Costo per Km *"
            className="form-group"
          />
          <CalculatedField id="formKilometerCost" values={values} />
          {isNew && (
            <>
              <hr />
              <Form.Group controlId="formDescription" className="form-group">
                <FormField
                  name="createAnother"
                  component={Checkbox}
                  label="Crea subito un altro viaggio"
                />
              </Form.Group>
            </>
          )}
          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {currentTrip.id ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
