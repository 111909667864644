import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AbsenceDayCell } from './AbsenceDayCell';
import { decimalFormatter } from '../../components/Table/utility';
import { forwardRef } from 'react';
import { DayHeaderCell } from '../../components/Cells/CalendarTable/DayHeaderCell';

const AbsencesTable = forwardRef((props, ref) => {
  const { 
    dayHeaders,
    data,  
    isReadOnly,
    isEmbeddedInWorklog,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onScroll,
  } = props;
  
  return (
    <TableContainer component={Paper} onScroll={onScroll} ref={ref}>
      <Table stickyHeader className='month-table'>
        <TableHead>
          <TableRow>
            <TableCell>{isEmbeddedInWorklog ? 'Assenze' : 'Utente'}</TableCell>
            <TableCell>{isEmbeddedInWorklog ? 'Ore' : 'GG.'}</TableCell>
            {dayHeaders.map((header) => (
              <DayHeaderCell 
                day={header.day}
                isWeekEnd={header.weekEnd}
                isHoliday={header.isHolidays}
                isToday={header.isToday}
                key={header.day.format('DD')}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((userData) => (
            <TableRow
              key={userData.userId}
            >
              <TableCell>
                {isEmbeddedInWorklog ? '' : userData.userFullName}
              </TableCell>
              <TableCell>
                {decimalFormatter.format(isEmbeddedInWorklog ? userData.totalHours : userData.totalHours / 8)}
              </TableCell>
              {dayHeaders.map((header) => {
                const date = header.day.format('YYYY-MM-DD');
                const cellData = userData.items.find(i => i.date === date);
                return <AbsenceDayCell
                  data={cellData}
                  date={date}
                  isReadOnly={isReadOnly}
                  isEmbeddedInWorklog={isEmbeddedInWorklog}
                  isWeekEnd={header.weekEnd}
                  isHoliday={header.isHolidays}
                  isToday={header.isToday}
                  onAddClick={onAddClick}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                  key={date}
                />
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

AbsencesTable.defaultProps = {
  dayHeaders: [],
  data: [],
  isReadOnly: false,
  isEmbeddedInWorklog: false,
  onAddClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
  onScroll: () => {},
};

AbsencesTable.propTypes = {
  dayHeaders: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  isReadOnly: PropTypes.bool,
  isEmbeddedInWorklog: PropTypes.bool,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onScroll: PropTypes.func,
};

AbsencesTable.displayName = "AbsencesTable";
export default AbsencesTable;
