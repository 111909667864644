/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormError, FormTimePicker as FormTimePickerOptions } from '.';

const FormTimePicker = (props) => {
  const { label, name } = props;

  return (
    <Form.Group controlId={`form${name}`} className="form-group">
      <Form.Label>{label}</Form.Label>
      <FormTimePickerOptions {...props} />
      <FormError {...props} />
    </Form.Group>
  );
};

FormTimePicker.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  minTime: undefined,
  maxTime: undefined,
};

FormTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  minTime: PropTypes.any,
  maxTime: PropTypes.any,
};

export default FormTimePicker;
