import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { itIT } from '@mui/x-data-grid';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { decimalFixed2ColumnOptions, currencyValueFormatter } from '../../components/DataGrid/DataGridUtils';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter';

const columns = [
  { field: 'date', headerName: 'Data', flex: 1 },
  { field: 'description', headerName: 'Descrizione', flex: 3 },
  { field: 'cost', headerName: 'Spesa', ...decimalFixed2ColumnOptions},
];

export const BonusExpensesDataGrid = ({
  expenses,
  available,
  budget,
}) => {

  const footerData = [    
    { id: 1, label: 'Totale speso', value: budget - available, ...currencyValueFormatter},
    { id: 2, label: 'Budget', value: budget, ...currencyValueFormatter},
    { id: 3, label: 'Disponibilità', value: available, ...currencyValueFormatter},
  ];

  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={expenses}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
          }}                    
          slotProps={{
              footer: { data: footerData },
          }}
        />
      </Box>
    </>
  );
};

BonusExpensesDataGrid.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  available: PropTypes.number,
  budget: PropTypes.number,
};

BonusExpensesDataGrid.defaultProps = {
  expenses: [],
  available: 0,
  budget: 0,
};
