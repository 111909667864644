export const columnsFromData = (props) => {
  const { data, headerPrefix, formatters = {}, virtualColumns = {} } = props;
  if (data && data.length > 0) {
    return Object.keys(data[0])
      .map((key) => {
        const column = {};
        column.Header = headerPrefix ? `${headerPrefix}.${key}` : key;
        column.accessor = key;
        if (formatters[key]) column.Cell = formatters[key];
        return column;
      })
      .concat(
        Object.keys(virtualColumns).map((key) => {
          const column = {};
          column.Header = key;
          column.accessor = key;
          column.Cell = virtualColumns[key];
          return column;
        })
      );
  }
  return [];
};

export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

export const decimalFormatter = new Intl.NumberFormat('it-IT', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

export const decimalFormatterFixed2 = new Intl.NumberFormat('it-IT', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
