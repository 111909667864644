import PropTypes from 'prop-types';

const RowDetail = (props) => {
  const { label, value, valueExtraClasses, icon } = props;

  return (
    <div className='total__details'>
      <div className="total__details__label">
        <div className={`total__details__icon ${icon}`} />
        {label}
      </div>
      <div className={['total__details__value', valueExtraClasses].join(" ")}>{value}</div>
    </div>
  );
};

RowDetail.defaultProps = {
  label: '',
  value: '',
  valueExtraClasses: [],
  icon: '',
};

RowDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  valueExtraClasses: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
};

export default RowDetail;
