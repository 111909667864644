/* eslint-disable react/prop-types */

import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createAbsences, updateAbsence } from '../../utils/API/absences';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import moment from 'moment';
import FormTimePicker from '../../components/Form/FormTimePicker';
import FormDatePicker from '../../components/Form/FormDatePicker';

async function onSubmit(values, actions, close, onDataChanged) {
  const { setSubmitting, resetForm } = actions;
  const { id, isAllDay, isPeriod, fromDate, toDate, createAnother, fromTime, toTime, totalTime } = values;

  try {
    const data = {
      fromTime: fromTime.format('HH:mm'),
      toTime: toTime.format('HH:mm'),
      totalTime: totalTime.format('HH:mm'),
    };
    if (isAllDay) {
      data.fromTime = null;
      data.toTime = null;
      data.totalTime = '08:00';
    }
    if (id) {
      await updateAbsence(id, data);
    } else {
      data.fromDate = fromDate.format('YYYY-MM-DD');      
      data.toDate = null;
      if (isPeriod) {
        data.toDate = toDate.format('YYYY-MM-DD');        
      }      
      await createAbsences(data);
    }
  } finally {
    setSubmitting(false);
    onDataChanged();
    if (!createAnother) {
      close();
    } else {
      resetForm(values);
    }
  }
}

const validationSchema = Yup.object().shape({
  isPeriod: Yup.boolean(),
  isAllDay: Yup.boolean(),
  createAnother: Yup.boolean(),
  fromDate: Yup.date().required('Campo obbligatorio'),
  toDate: Yup.date().when('isPeriod', {
    is: true,
    then: Yup.date()
      .required('Campo obbligatorio')
      .test('to_date_test', 'Periodo non valido', (value, context) => {
        const { fromDate } = context.parent;
        if (moment(value, 'YYYY-MM-DD').isSameOrBefore(moment(fromDate, 'YYYY-MM-DD'))) {
          return false;
        }
        return true;
      }),
  }),
  fromTime: Yup.object().when('isAllDay', {
    is: false,
    then: Yup.object()
      .required('Campo obbligatorio')
      .test('from_time_test', 'Ora inizio non valida', (value) => {
        if (value.isBefore(moment('07:00', 'HH:mm'))) {
          return false;
        }
        const minutes = value.minutes();
        if (minutes % 5 !== 0) {
          return false;
        }
        return true;
      }),
  }),
  toTime: Yup.object().when('isAllDay', {
    is: false,
    then: Yup.object()
      .required('Campo obbligatorio')
      .test('to_time_test', 'Ora fine non valida', (value, context) => {
        const { fromTime } = context.parent;
        if (value.isAfter(moment('19:00', 'HH:mm'))) {
          return false;
        }
        if (fromTime && value.isSameOrBefore(moment(fromTime, 'HH:mm'))) {
          return false;
        }
        const minutes = value.minutes();
        if (minutes % 5 !== 0) {
          return false;
        }
        return true;
      }),
  }),
  totalTime: Yup.object().when('isAllDay', {
    is: false,
    then: Yup.object()
      .required('Campo obbligatorio')
      .test('total_time_test', 'Tempo totale non valido', (value, context) => {
        const { fromTime, toTime } = context.parent;
        if (!value) {
          return false;
        }
        if (value.isBefore(moment('00:05', 'HH:mm'))) {
          return false;
        }
        if (value.isAfter(moment('07:55', 'HH:mm'))) {
          return false;
        }
        const minutes = value.minutes();
        if (minutes % 5 !== 0) {
          return false;
        }
        const absenceMinutes = value.hours() * 60 + value.minutes();
        const calculatedAbsenceMinutes = moment
          .duration(toTime.diff(fromTime))
          .asMinutes();
        return absenceMinutes <= calculatedAbsenceMinutes;
      }),
  }),
});

const AbsenceForm = (props) => {
  const { month, close, editFormData, isReadOnly, onDataChanged } = props;

  const defaultDate = editFormData.selectedDate ? moment(editFormData.selectedDate, 'YYYY-MM-DD') : month.startOf('month');

  const initialValues = {
    id: undefined,
    fromDate: defaultDate,
    toDate: defaultDate,
    fromTime: moment('09:00', 'HH:mm'),
    toTime: moment('18:00', 'HH:mm'),
    totalTime: moment('08:00', 'HH:mm'),
    isAllDay: true,
    isPeriod: false,
    createAnother: false,
  };

  if (editFormData.id) {
    initialValues.id = editFormData.id;
    initialValues.requestInsertDatetime = editFormData.requestInsertDatetime;
    initialValues.requestUpdateDatetime = editFormData.requestUpdateDatetime;
    initialValues.fromDate = moment(editFormData.date, 'YYYY-MM-DD');
    initialValues.totalTime = moment(
      editFormData.totalTime ?? '08:00',
      'HH:mm'
    );
    initialValues.fromTime = moment(editFormData.fromTime ?? '09:00', 'HH:mm');
    initialValues.toTime = moment(editFormData.toTime ?? '18:00', 'HH:mm');
    initialValues.isAllDay = initialValues.totalTime.hours() === 8;
  }

  const hasId = !!initialValues?.id;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, onDataChanged);
      }}
      initialValues={initialValues}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
        setFieldValue,
      }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormItem
            values={values.id}
            type="text"
            name="id"
            style={{ display: 'none' }}
            value={undefined}
          />
          <FormDatePicker
            name="fromDate"
            label="Data *"
            disabled={hasId || isReadOnly}
          />
          {!isReadOnly && (
            <FormCheckbox name="isAllDay" label="Tutto il giorno" />
          )}
          <div className="inline-fields">
            <FormTimePicker
              name="fromTime"
              label="Dalle (min 7)"
              minTime={moment('07:00', 'HH:mm')}
              maxTime={moment('18:55', 'HH:mm')}
              disabled={values.isAllDay || isReadOnly}
            />
            <FormTimePicker
              name="toTime"
              label="Alle (max 19)"
              minTime={moment('07:05', 'HH:mm')}
              maxTime={moment('19:00', 'HH:mm')}
              disabled={values.isAllDay || isReadOnly}
            />
            <FormTimePicker
              name="totalTime"
              label="Totale"
              minTime={moment('00:05', 'HH:mm')}
              maxTime={moment('07:55', 'HH:mm')}
              disabled={values.isAllDay || isReadOnly}
            />
          </div>
          <div
            className="mb-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              hidden={isReadOnly}
              variant="primary"
              onClick={() => {
                const duration = moment.duration(
                  values.toTime.diff(values.fromTime)
                );
                setFieldValue(
                  'totalTime',
                  moment(
                    moment.utc(duration.as('milliseconds')).format('HH:mm'),
                    'HH:mm'
                  )
                );
              }}
              disabled={values.isAllDay}
            >
              Calcola
            </Button>
          </div>
          {!hasId && (
            <>
              <FormCheckbox name="isPeriod" label="Periodo" />
              <FormDatePicker
                name="toDate"
                label="Data Fine *"
                disabled={!values.isPeriod}
              />
            </>
          )}
          {hasId && (
            <>
              <div className="inline-fields">
                <FormItem
                  type="datetime"
                  name="requestInsertDatetime"
                  label="Data/ora inserimento"
                  disabled={true}
                />
                <FormItem
                  type="datetime"
                  name="requestUpdateDatetime"
                  label="Data/ora aggiornamento"
                  disabled={true}
                />
              </div>
            </>
          )}
          <div className="buttonContent">
            {!hasId && (
              <FormCheckbox
                name="createAnother"
                label="Aggiungi un altro"
              />
            )}
            <div className="spacer" />
            <Button
              onClick={close}
              variant={isReadOnly ? 'primary' : 'secondary'}
            >
              {isReadOnly ? 'Chiudi' : 'Annulla'}
            </Button>
            <Button
              hidden={isReadOnly}
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !isValid}
            >
              {hasId ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default AbsenceForm;
