import _ from 'lodash';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';

export const RetrospectiveReportCard = ({
                                          title, icon, iconBackgroundColor, iconColor, data
                                        }) => (
  <Card variant="outlined" className="retrospective-card">
    <div className="retrospective-card-title">
      <Box className="icon"
           sx={{
             backgroundColor: iconBackgroundColor,
             color: iconColor
           }}>
        {icon}
      </Box>
      <div>{title}</div>
    </div>
    <div className="retrospective-card-content">
      {data.map(({ header, value, isPercentage, footer }, index) => (
        <div key={index} className="retrospective-card-data">
          <div className="retrospective-card-data-header">{header}</div>
          <div className="retrospective-card-data-body">
            {!_.isNil(value) ? `${value}${isPercentage ? '%' : ''}` : 'n.d.'}
          </div>
          {footer && <div className="retrospective-card-data-footer">{footer}</div>}
        </div>
      ))}
    </div>
  </Card>
);

RetrospectiveReportCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconBackgroundColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(
      PropTypes.shape({
        header: PropTypes.string.isRequired,
        value: PropTypes.number,
        isPercentage: PropTypes.bool,
        footer: PropTypes.string
      })
    )
  ).isRequired
};

RetrospectiveReportCard.defaultProps = {
  icon: null
};