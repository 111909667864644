import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

export const WorklogIssueCell = ({ 
    projectSummary,    
    issueSummary,
}) => (
    <TableCell>
        <Box>
            {projectSummary}
        </Box>
        <Box>
            {issueSummary}
        </Box>
    </TableCell>
);

WorklogIssueCell.propTypes = {
    projectSummary: PropTypes.string,
    issueSummary: PropTypes.string,
};
WorklogIssueCell.defaultProps = {
    projectSummary: '',
    issueSummary: '',
};
