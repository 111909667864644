import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { TextHighlight } from '../TextHighlight';

const allUsers = 'Tutti gli utenti';
const futureDate = '9999-99-99';

const makeLabel = (users, selected, maxSelected, defaultLabel, promptLabelType) => {
  const addLabel = promptLabelType === 'username';
  const data = users
    .filter((u) => selected.includes(u.id))
    .map((u) => promptLabelType === 'username' ? u.username.toUpperCase() : u.full_name);
  if (maxSelected === 1 && data.length > 0) {
    return addLabel ? `Utente: ${data[0]}` : data[0];
  }
  if (data.length > 6) {
    return `Utenti (${data.length} selezionati)`;
  }
  if (data.length > 0) {
    return addLabel ? `Utenti: ${data.join(', ')}` : data.join(', ');
  }
  return defaultLabel;
};

const scoreMatches = (text, search) => {
  if (!(search?.length > 0 && text?.length > 0)) {
    return 0;
  }
  const startsWith = new RegExp(`\\b${search.toLowerCase()}`, 'g');
  const startScore = text.toLowerCase().match(startsWith)?.length ?? 0;
  const contains = new RegExp(search.toLowerCase(), 'g');
  const containScore = text.toLowerCase().match(contains)?.length ?? 0;
  return startScore * 100 + containScore;
};

export const MultiSelectUser = ({
                                  users,
                                  selected,
                                  setSelected,
                                  maxSelected,
                                  promptLabel,
                                  fixedPromptLabel,
                                  promptLabelType,
                                  emptySelectionLabel,
                                  className,
                                  variant
                                }) => {
  const isUnselected = selected.length <= 0;
  const defaultLabel = promptLabel ?? allUsers;
  const text = useMemo(
    () =>
      fixedPromptLabel
        ? defaultLabel
        : makeLabel(users, selected, maxSelected, defaultLabel, promptLabelType),
    [defaultLabel, fixedPromptLabel, selected, maxSelected, users]
  );

  const [search, setSearch] = useState('');

  const select = (list) => {
    setSelected(list);
    setSearch('');
  };

  const searchedFirstInactiveLast = [...users]
    .sort(
      ({ outgoing_date: a }, { outgoing_date: v }) =>
        -(a ?? futureDate).localeCompare(v ?? futureDate)
    )
    .sort(
      ({ full_name: a1, username: a2 }, { full_name: b1, username: b2 }) =>
        -scoreMatches(`${a1} ${a2}`, search) +
        +scoreMatches(`${b1} ${b2}`, search)
    );

  const onKeyPress = ({ key, stopPropagation }) => {
    setSearch(`${search}${key}`.toLowerCase());
    try {
      stopPropagation();
    } catch (e) {
      /* ignore */
    }
  };
  const onKeyDown = ({ key, stopPropagation }) => {
    if (key === 'Backspace' || key === 'Escape') setSearch('');
    try {
      stopPropagation();
    } catch (e) {
      /* ignore */
    }
  };

  function toggleSelected(id) {
    const list = selected.includes(id)
      ? selected.filter((x) => x !== id)
      : [...selected, id];
    const newSelected = list.slice(-maxSelected);
    select(newSelected);
  }

  return (
    <Dropdown
      className={`multi-select-user d-inline mx-2 ${className}`}
      autoClose={maxSelected > 1 ? 'outside' : true}
    >
      <Dropdown.Toggle
        variant={variant}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
      >
        {text}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          active={isUnselected}
          onClick={() => select([])}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
        >
          {emptySelectionLabel}
        </Dropdown.Item>
        {searchedFirstInactiveLast.length > 0 &&
          <Dropdown.Divider />
        }
        {searchedFirstInactiveLast.map(
          ({ id, username, full_name: name, outgoing_date: end }) => (
            <Dropdown.Item
              key={id}
              active={selected.includes(id)}
              onClick={() => toggleSelected(id)}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              className={cn({ 'inactive-user': end })}
            >
              <TextHighlight
                text={`${(username || '').toLocaleUpperCase()} - ${name || ''}`}
                highlight={search}
              />
              {end && ` × ${end}`}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
    ;
};

MultiSelectUser.defaultProps = {
  users: [],
  selected: [],
  setSelected: () => {
  },
  maxSelected: Number.MAX_VALUE,
  promptLabel: undefined,
  fixedPromptLabel: false,
  promptLabelType: 'username',
  emptySelectionLabel: allUsers,
  className: '',
  variant: 'primary'
};

MultiSelectUser.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      full_name: PropTypes.string
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.number),
  setSelected: PropTypes.func,
  maxSelected: PropTypes.number,
  promptLabel: PropTypes.string,
  fixedPromptLabel: PropTypes.bool,
  promptLabelType: PropTypes.oneOf(['username', 'fullName']),
  emptySelectionLabel: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'info',
    'light',
    'dark'
  ])
};
