import request from '../axios';
import { API } from '../../constants';
import { success, error } from '../notification';
import { getUserParams } from './paramUtil';

export const fetchAbsences = (year, month, usernames) =>
  request
    .get(
      `${API.ABSENCES}?year=${year}&month=${month}&${getUserParams(
        usernames
      )}`
    )
    .then((res) => res.data.result)
    .catch((e) => {
      error('Errore', 'Impossibile caricare le assenze');
      throw e;
    });

export const deleteAbsence = (id) =>
  request
    .delete(`${API.ABSENCES}${id}`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });

export const createAbsences = (data) =>
  request
    .post(API.ABSENCES, data)
    .then(() => {
      success('Esito', 'Inserimento riuscito');
    })
    .catch((e) => {
      error('Errore', 'Inserimento fallito');
      throw e;
    });

export const updateAbsence = (id, data) =>
  request
    .put(`${API.ABSENCES}${id}/`, data)
    .then(() => {
      success('Esito', 'Aggiornamento eseguito con successo');
    })
    .catch((e) => {
      error('Errore', 'Aggiornamento fallito');
      throw e;
    });
