/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { createRetrospective, getRetrospectiveUsersByYear } from '../../utils/API/retrospective';
import { renderSpinner } from '../../utils/Utility';
import { UserSelector } from '../../components/User/UserSelector';
import FormDatePicker from '../../components/Form/FormDatePicker';

const initialValues = () => ({
  id: '',
  date: moment(),
  user: ''
});

const minYear = 2023;
const minDate = moment({ year: minYear, month: 0, day: 1 });
const maxYear = moment().year();
const maxDate = moment({ year: maxYear, month: 11, day: 31 });
const validationSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Inserisci una data valida')
    .required('Campo obbligatorio')
    .min(`${minYear}-01-01`, `L'anno non può essere inferiore a ${minYear}`)
    .max(`${maxYear + 1}-01-01`, `L'anno non può essere superiore a ${maxYear}`),
  user: Yup.number().required('Campo obbligatorio')
});

export const RetrospectiveForm = ({
                                    onClose,
                                    onSubmitSuccess
                                  }) => {
  const [loading, setLoading] = useState(true);
  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    fetchUsers().then();
  }, []);

  const fetchUsers = async (date = new Date()) => {
    try {
      const users = await getRetrospectiveUsersByYear(date.getFullYear());
      setAvailableUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    const { id, date, user } = values;
    const payload = {
      id,
      date: date.format('YYYY-MM-DD'),
      user
    };

    try {
      const data = await createRetrospective(payload);
      onSubmitSuccess(data);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (renderSpinner(true));
  }

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
          values,
          setFieldValue
        }) => {
        useEffect(() => {
          const newDate = new Date(values.date);
          if (newDate.getFullYear().toString().length >= 4) {
            setFieldValue('user', '');
            fetchUsers(newDate).then();
          }
        }, [values.date]);

        return <FormikForm noValidate onSubmit={handleSubmit}>
          <p>
            Cerca e seleziona l&apos;utente per il quale desideri creare una nuova scheda di retrospettiva.
          </p>

          <FormDatePicker
            name="date"
            label="Data retrospettiva"
            minDate={minDate}
            maxDate={maxDate}
          />

          <UserSelector
            name="user"
            label="Utente"
            availableUsers={availableUsers}
            user={values.user}
            setUser={(user) => setFieldValue('user', user)}
          />

          <div className="buttonContent">
            <div className="spacer" />
            <Button
              onClick={onClose}
              variant="secondary"
            >
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !isValid}
            >
              Inserisci
            </Button>
          </div>
        </FormikForm>;
      }}
    </Formik>
  );
};
