import PropTypes from 'prop-types';
import { decimalFormatter } from '../../components/Table/utility';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useState, memo, useEffect } from 'react';
import { deleteWorklogById, updateWorklogDay, createWorklogDay } from '../../utils/API/worklog';
import TableCell from '@mui/material/TableCell';

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(nextProps) === JSON.stringify(prevProps);

export const WorklogDayCell = memo(({
    worklog,
    date,
    issueId,
    isReadOnly,
    isWeekEnd,
    isHoliday,    
    isToday,
    onDeleteClick,
    onEditClick,
    onTimeSpentChange,
}) => {

  const [formattedTimeSpent, setFormattedTimeSpent] = useState(worklog ? decimalFormatter.format(worklog.time_spent): '');
  
  useEffect(() => {
    setFormattedTimeSpent(worklog ? decimalFormatter.format(worklog.time_spent): '');
  }, [worklog]);

  const cnClasses = cn({
    weekend: isWeekEnd && !isHoliday,
    holiday: isHoliday,
    today: isToday,
  });

  const handleTimeSpentChange = () => {
    const value = parseFloat(formattedTimeSpent.replace(',', '.'));
    if (worklog) {
      if (Number.isNaN(value)) {
        deleteWorklogById({
          id: worklog.id,
        }).then(() => onTimeSpentChange());
      } else {
        if (value === worklog.time_spent) {
          return;
        }
        const data = {
          id: worklog.id,
          time_spent: value,
          date,
          issue: worklog.issue,
        };
        updateWorklogDay(data).then(() => onTimeSpentChange());
      }
    } else {
      if (Number.isNaN(value)) {
        return;
      }
      const data = {
        description: '',
        time_spent: value,
        date,        
        issue: issueId,
      };
      createWorklogDay(data).then(() => onTimeSpentChange());
    }
  };

  return (
    <TableCell
      className={cnClasses}
    >
      <Tooltip 
        placement="top"
        arrow
        title={worklog && !isReadOnly && (
              <>
                <IconButton
                  onClick={() => {
                    onDeleteClick(worklog.id);
                  } }
                >
                  <DeleteOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    onEditClick(worklog);
                  } }
                >
                  <EditOutlinedIcon />
                </IconButton>
              </>               
            )}
      >
        <TextField
          variant='standard'
          disabled={isReadOnly}
          InputProps={{ disableUnderline: true }}
          value={formattedTimeSpent}
          onChange={(event) => {
            setFormattedTimeSpent(event.target.value);
          }}
          onBlur={() => handleTimeSpentChange()}          
          onKeyDown={(event) => {
            if (event.key === 'Enter'){
              handleTimeSpentChange();
            }
          }}
        />
      </Tooltip>
    </TableCell>
  );
}, arePropsEqual);

WorklogDayCell.propTypes = {
  worklog: PropTypes.shape({
    id: PropTypes.number,
    time_spent: PropTypes.number,
    issue: PropTypes.number,
  }),
  date: PropTypes.string,
  issueId: PropTypes.number,
  isReadOnly: PropTypes.bool,
  isWeekEnd: PropTypes.bool,
  isHoliday: PropTypes.bool,  
  isToday: PropTypes.bool,  
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onTimeSpentChange: PropTypes.func,
};

WorklogDayCell.defaultProps = {
  worklog: undefined,
  date: undefined,
  issueId: undefined,
  isReadOnly: false,
  isWeekEnd: false,
  isHoliday: false,
  isToday: false,
  onDeleteClick: () => {},
  onEditClick: () => {},
  onTimeSpentChange: () => {},
};

WorklogDayCell.displayName = 'WorklogDayCell';