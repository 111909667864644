import { decimalFormatter, decimalFormatterFixed2, currencyFormatter } from '../Table/utility';

export const upperCaseColumnOptions = {
    valueFormatter: (params) => params.value?.toUpperCase(),
}

export const decimalFixed2ValueFormatter = {
    valueFormatter: (params) => decimalFormatterFixed2.format(params.value),
}

export const decimalValueFormatter = {
    valueFormatter: (params) => decimalFormatter.format(params.value),
}

export const currencyValueFormatter = {
    valueFormatter: (params) => currencyFormatter.format(params.value),
}

export const decimalFixed2ColumnOptions = {
    ...decimalFixed2ValueFormatter,
    type: 'number',    
};
  
export const decimalColumnOptions = {
    ...decimalValueFormatter,
    type: 'number',    
};
  
export const currencyColumnOptions = {
    ...currencyValueFormatter,
    type: 'number',    
};
  
export const booleanColumnOptions = {
    valueFormatter: (params) => {
        if (params.value === undefined) {
            return '';
        }
        return params.value ? 'Sì' : 'No';
    },
}

