import PropTypes from 'prop-types';
import { currencyFormatter } from '../Table/utility';

const { format } = currencyFormatter;

export const CurrencyCell = ({ value, className = '' }) => (
  <div className={`w-100 ${className}`}>{format(value)}</div>
);

CurrencyCell.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CurrencyCell.defaultProps = { className: '' };
