import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

export const ExportToolbar = ({
  csvFilename,
}) => (
  <GridToolbarContainer>
    <GridToolbarExport
      csvOptions={{
        fileName: csvFilename,
        utf8WithBom: true,
      }}
      printOptions={{
        disableToolbarButton: true,
      }}
    />
  </GridToolbarContainer>
);

ExportToolbar.defaultProps = {
    csvFilename: '',
};

ExportToolbar.propTypes = {
    csvFilename: PropTypes.string,
};
