/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */

import React, { useEffect } from 'react';
import {
  useBlockLayout,
  useColumnOrder,
  useFlexLayout,
  useRowSelect,
  useTable,
} from 'react-table';
import { StyledTable } from './StyledTable';
import { ActionCellRenderer } from '../Cells/ActionCellRenderer';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import _ from 'lodash';
import cn from 'classnames';
import { useSticky } from 'react-table-sticky';
import { useTranslation } from 'react-i18next';
import { EmptyTable } from './EmptyTable';

const Thead = Styled.thead`
  border-top: 0px solid rgba(0,0,0,0);
`;

const BaseTable = (props) => {
  const {
    columns,
    data,
    hiddenColumns,
    tableClassName,
    sticky,
    columnOrder,
    actions,
    footer,
    striped,
    bordered,
    setSelectedFlatRows,
    getRowProps,
    getCellProps,
    isEmptyTableFn,
  } = props;
  let tableClass = tableClassName;

  const isEmptyTable = isEmptyTableFn(data);

  if (actions && !_.find(columns, ['Header', '#'])) {
    columns.push({
      Header: '#',
      accessor: 'rowNumber',
      width: '30',
      Cell: (prop) => {
        const { cell } = prop;
        return <span>{cell.row.index + 1}</span>;
      },
    });
  }

  if (actions && !_.find(columns, ['Header', 'action'])) {
    columns.push({
      Header: 'action',
      accessor: 'action',
      headerClassName: 'text-center',
      cellClassName: 'text-center',
      width: 60,
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <ActionCellRenderer actions={actions} rowData={original} />,
    });
  }

  const checkboxColumn = (columns, hooks) => {
    if (setSelectedFlatRows) {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line react/prop-types
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          width: 8,
        },
        ...columns,
      ]);
    }
  };

  const useTableOptions = {
    autoResetSelectedRows: true,
    columns,
    data,
    initialState: {
      columnOrder,
      hiddenColumns,
    },
  };
  const plugins = [useColumnOrder];

  if (sticky) {
    plugins.push(useSticky);
    plugins.push(useBlockLayout);
    tableClass = `sticky ${tableClassName}`;
  } else {
    plugins.push(useFlexLayout);
  }

  const { t } = useTranslation();

  const renderCellFooter = (footer) =>
    typeof footer === 'string' && footer !== '' ? t(footer) : footer;

  const renderFooter = (footerGroups) => {
    const classesFooter = cn('footer', {
      hidden: !footer,
    });

    return footer ? (
      <tfoot className={classesFooter}>
        {footerGroups.map((footerGroup, rowIndex) => (
          <tr
            {...footerGroup.getHeaderGroupProps()}
            className="tr"
            key={rowIndex}
          >
            {footerGroup.headers.map((column, colIndex) => (
              <td
                {...column.getHeaderProps()}
                className="td"
                key={`${rowIndex}_${colIndex}`}
              >
                {renderCellFooter(column.render('Footer'))}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    ) : (
      <></>
    );
  };

  const IndeterminateCheckbox = React.forwardRef(
    // eslint-disable-next-line react/prop-types
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    { ...useTableOptions, columns },
    ...plugins,
    useRowSelect,
    (hooks) => {
      checkboxColumn(columns, hooks);
    }
  );

  useEffect(() => {
    if (setSelectedFlatRows) {
      setSelectedFlatRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  const renderCell = (cell) => {
    if (typeof cell.value == 'boolean') {
      return cell.value ? 'Sì' : 'No';
    }
    return cell.render('Cell');
  };

  const renderTable = () => (
    <StyledTable
      striped={striped}
      bordered={bordered}
      {...getTableProps()}
      className={tableClass}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeader column={column} key={column.id} />
            ))}
          </tr>
        ))}
      </Thead>
      <tbody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps(getCellProps(cell))}
                  className={cell.column.className}
                >
                  {renderCell(cell)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
      {renderFooter(footerGroups)}
    </StyledTable>
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <div
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
          display: 'block',
          maxWidth: '100%',
        }}
      >
        {isEmptyTable ? <EmptyTable /> : renderTable()}
      </div>
    </div>
  );
};

const TableHeader = (props) => {
  const { column } = props;
  const thRef = React.useRef(null);

  const { t } = useTranslation();

  const renderHeader = (header) => {
    if (typeof header === 'string') {
      return t(header);
    }
    return header;
  };

  useEffect(() => {
    if (column.isToday) {
      thRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [column.isToday]);

  return (
    <th
      ref={thRef}
      {...column.getHeaderProps()}
      id={column.render('id')}
      className={column.headerClassName}
    >
      {renderHeader(column.render('Header'))}
    </th>
  );
};

BaseTable.defaultProps = {
  columns: [],
  data: [],
  hiddenColumns: [],
  columnOrder: [],
  // tableClassName: ' base-table ',
  sticky: false,
  actions: {},
  footer: false,
  striped: true,
  bordered: true,
  getRowProps: () => {},
  getCellProps: () => {},
  isEmptyTableFn: (data) => {
    return !(data && data.length > 0);
  },
};

BaseTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  tableClassName: PropTypes.string,
  sticky: PropTypes.bool,
  actions: PropTypes.shape({ edit: PropTypes.func, remove: PropTypes.func }),
  footer: PropTypes.bool,
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  isEmptyTableFn: PropTypes.func,
};

export default BaseTable;
