import Styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

export const Avatar = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  border: 1px solid #D5ECFC;
  position: relative;
  margin-right: 15px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 50%;
`;

export const StyledSpinner = Styled(Spinner)`
  position: absolute;
`;
