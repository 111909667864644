/* eslint camelcase: [0] */

import { FileArrowDown } from 'react-bootstrap-icons';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { openPreview } from './API/fileDownload';

export const roundTime = (time) => _.ceil(time, 2);

export const getDaysOfMonth = (start) =>
  new Array(moment(start).daysInMonth())
    .fill(null)
    .map((x, i) => moment(start).startOf('month').add(i, 'days'));

export const prepareDaysColumnHeader = ({ month, calendarWorklogsHeader }) => {
  const daysOfMonth = getDaysOfMonth(month.toDate());
  const columnsDaysHeader = [];
  daysOfMonth?.forEach((dayOfMonth) => {
    const currentDate = dayOfMonth.format('YYYY-MM-DD');
    if (calendarWorklogsHeader.length > 0) {
      const calendarDay = _.find(calendarWorklogsHeader, {
        date: currentDate,
      });
      const isToday = dayOfMonth.isSame(new Date(), 'day');
      if (calendarDay) {
        const { holidays = '', working_day = false } = calendarDay;
        const isHolidays = !_.isEmpty(holidays);
        columnsDaysHeader.push({
          day: dayOfMonth,
          isHolidays,
          workDay: working_day,
          weekEnd: !working_day,
          isToday,
        });
      }
    }
  });

  return columnsDaysHeader;
};

export const prepareDaysData = (props) => {
  const { worklogs, userIssues, userProjects } = props;
  worklogs.sort((a, b) => {
    if (a.issue_type === b.issue_type) {
      return a.description?.localeCompare(b.description);
    }
    if (a.issue_type === 'non-working') {
      return 1;
    }
    if (b.issue_type === 'non-working') {
      return -1;
    }
    return a.description?.localeCompare(b.description);
  });
  const data = [];
  if (worklogs.length > 0) {
    worklogs.forEach((worklog) => {
      const dd = _.split(worklog.date, '-')[2];

      const issue = userIssues.find((el) => el.id === worklog.issue);
      
      if (issue) {
        const project = userProjects.find((el) => el.id === issue.projectId);
        if (project) {
          const issueData = data.find((el) => el.issueId === issue.id);

          if (issueData) {
            Object.assign(issueData.days, { [dd]: worklog });
            issueData.hours += worklog.time_spent;
            issueData.hours = roundTime(issueData.hours);          
          } else {
            data.push({
              issueId: issue.id,
              issueSummary: issue.summary,
              projectSummary: project.summary,
              days: { [dd]: worklog },
              hours: worklog.time_spent,
            });
          }
        }
      }
    });
  }
  return data;
};

export const renderSpinner = (isLoading) => {
  if (isLoading) {
    return (
      <div className="spinnerPageContent">
        <div className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }
  return <></>;
};

export const extractSummaryData = (reportData) => {
  const detailExpenses = _.find(reportData, { type: 'expenses' });
  const detailWorklogs = _.find(reportData, { type: 'worklogs' });
  return { ...detailExpenses.detail, ...detailWorklogs.detail };
};

export const completeAttachmentColumn = (attachmentColumn) =>
  Object.assign(attachmentColumn, {
    width: 40,
    Cell: (prop) => {
      const {
        cell: { value },
      } = prop;
      return (
        value && (
          <div className="tableAction">
            <FileArrowDown
              size="26px"
              className="icon"
              onClick={() => openPreview(value)}
            />
          </div>
        )
      );
    },
  });
