import PropTypes from 'prop-types';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import I3Modal from './I3Modal';

const I3ModalConfirmation = (props) => {
  const {
    show,
    onHide,
    title,
    variant,
    questionTitle,
    questionText,
    onConfirm,
    textHide,
    textConfirm,
  } = props;

  const renderIcon = () => {
    if (variant === 'warning') {
      return (
        <div className="warning-icon-content">
          <ExclamationTriangle size="30px" color="var(--red-45)" />
        </div>
      );
    }
    return <></>;
  };

  return (
    <I3Modal
      show={show}
      onHide={onHide}
      title={title}
      className="I3Modal-confirm"
    >
      <>
        <div className="center-content">
          {renderIcon()}
          <div className="question-title">{questionTitle}</div>
          <div className="question-text">{questionText}</div>
        </div>
        <div className="buttonContent">
          <Button type="button" variant="secondary" onClick={() => onHide()}>
            {textHide}
          </Button>
          <Button type="button" variant="primary" onClick={() => onConfirm()}>
            {textConfirm}
          </Button>
        </div>
      </>
    </I3Modal>
  );
};

I3ModalConfirmation.defaultProps = {
  show: false,
  onHide: () => {},
  title: '',
  variant: 'warning',
  questionTitle: '',
  questionText: '',
  textHide: 'Annulla',
  textConfirm: 'Conferma',
  onConfirm: () => {},
};

I3ModalConfirmation.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.string,
  questionTitle: PropTypes.string,
  questionText: PropTypes.string,
  textHide: PropTypes.string,
  textConfirm: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default I3ModalConfirmation;
