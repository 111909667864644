/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormField } from '.';

const Checkbox = ({ field, ...props }) => (
  <Form.Check {...field} checked={field.value && !props.disabled} {...props} />
);

const FormCheckbox = (props) => {
  const { name } = props;

  return (
    <Form.Group controlId={name} className="form-group checkbox">
      <FormField component={Checkbox} {...props} />
    </Form.Group>
  );
};

FormCheckbox.defaultProps = {
  name: '',
  label: '',
  disabled: false,
};

FormCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormCheckbox;
