/* eslint-disable no-param-reassign */
import axios from 'axios';
import { keycloak } from '../keycloak';

const baseConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
};

const request = axios.create(baseConfig);

request.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${keycloak?.token}`;
  return req;
});

export const initAuth = (auth, login, logout) => {
  if (auth?.authenticated) {
    // eslint-disable-next-line camelcase
    const { given_name, family_name, email, resource_access } =
      auth.tokenParsed;
    login(
      {
        // eslint-disable-next-line camelcase
        first_name: given_name,
        // eslint-disable-next-line camelcase
        last_name: family_name,
        email,
      },
      resource_access
    );
  } else {
    logout();
  }
};

export default request;
