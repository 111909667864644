const TrashcanIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1982_24984)">
            <path d="M3.33325 5.83325H16.6666" stroke="#C20600" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33325 9.16675V14.1667" stroke="#C20600" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6667 9.16675V14.1667" stroke="#C20600" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.16675 5.83325L5.00008 15.8333C5.00008 16.2753 5.17568 16.6992 5.48824 17.0118C5.8008 17.3243 6.22472 17.4999 6.66675 17.4999H13.3334C13.7754 17.4999 14.1994 17.3243 14.5119 17.0118C14.8245 16.6992 15.0001 16.2753 15.0001 15.8333L15.8334 5.83325" stroke="#C20600" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#C20600" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1982_24984">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default TrashcanIcon;