import { MultiSelectUser } from '../Form/MultiSelectUser';
import * as PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

export const UsersSelector = ({
                                name,
                                label,
                                availableUsers,
                                usersById,
                                selected,
                                setSelected,
                                maxSelected,
                                fixedPromptLabel,
                                promptLabelType
                              }) => (
  <div className="form-group">
    <label className={(maxSelected === 1 || !selected.length) ? 'form-label' : ''}>
      {label}
      {maxSelected > 1 && (
        <div className="pills">
          {selected.map((id) => (
            <span
              key={+id}
              className="pill removable-pill"
              onClick={() => setSelected(selected.filter((u) => u !== id))}
            >
            {usersById[id].full_name}{' '}
          </span>
          ))}
        </div>
      )}
    </label>
    <br />
    <MultiSelectUser
      promptLabel={`Seleziona ${label.toLowerCase()}`}
      fixedPromptLabel={fixedPromptLabel}
      promptLabelType={promptLabelType}
      emptySelectionLabel="Nessuno"
      users={availableUsers}
      selected={selected}
      setSelected={setSelected}
      maxSelected={maxSelected}
      variant="light"
      className="multi-select-small"
    />
    <br />
    <ErrorMessage name={name} render={msg => <span className="error-message">{msg}</span>} />
  </div>
);

UsersSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  availableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  usersById: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired,
  maxSelected: PropTypes.number,
  fixedPromptLabel: PropTypes.bool,
  promptLabelType: PropTypes.oneOf(['username', 'fullName'])
};

UsersSelector.defaultProps = {
  maxSelected: Number.MAX_VALUE,
  fixedPromptLabel: false,
  promptLabelType: 'username'
};