import { useCallback, useEffect } from 'react';

export const useKeyPress = (key, handleKeyPress) => {
  const listener = useCallback(
    (e) => {
      if (e?.key?.toUpperCase() === key?.toUpperCase()) {
        handleKeyPress();
      }
    },
    [handleKeyPress, key]
  );
  useEffect(() => {
    document?.addEventListener('keydown', listener);
    return () => {
      document?.removeEventListener('keydown', listener);
    };
  }, [listener]);
};

export const ShortcutHelp = () => (
  <>
    <span>
      Psst... sai che puoi aprire le form di inserimento km, spese, e ore anche
      premendo{' '}
    </span>
    <code> F9</code>?
    <br />
    <span>(E puoi usare </span>
    <code>ESC</code>
    <span> per chiuderli.)</span>
  </>
);
