import { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AUTH_CLIENTS } from '../constants';
import { getUser } from '../utils/API/user';

const initialState = {
  language: 'it',
  first_name: '',
  last_name: '',
  email: '',
};

const getRoles = (resourceAccess) => {
  if (!resourceAccess) return [];
  return resourceAccess[AUTH_CLIENTS.i3portalBackend]?.roles ?? [];
};

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(initialState);
  const [permissions, setPermissions] = useState([]);
  const updateUser = useCallback(
    (data) => setUser((old) => ({ ...old, ...data })),
    []
  );
  const login = useCallback(
    (u, p) => {
      setUser(u);
      setPermissions(getRoles(p));
      getUser().then(updateUser);
    },
    [updateUser]
  );
  const logout = useCallback(() => setUser(initialState), []);

  const hasClaim = useCallback(
    (claim) => permissions.includes(claim),
    [permissions]
  );
  const value = {
    user: Object.freeze(user),
    login,
    logout,
    updateUser,
    hasClaim,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UserContext = createContext(initialState);
