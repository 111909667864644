import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserListContext } from '../../context/UserListContext';
import { UsersSelector } from './UsersSelector';

export const UserSelector = ({
                               name,
                               label,
                               availableUsers,
                               user,
                               setUser
                             }) => {
  const { usersById } = useContext(UserListContext);

  return (
    <UsersSelector
      name={name}
      label={label}
      availableUsers={availableUsers}
      usersById={usersById}
      selected={user ? [user] : []}
      setSelected={([u]) => setUser(u)}
      maxSelected={1}
      promptLabelType="fullName"
    />
  );
};

UserSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  availableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  setUser: PropTypes.func.isRequired
};

UserSelector.defaultProps = {
  user: undefined
};
