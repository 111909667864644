import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { itIT } from '@mui/x-data-grid';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import { currencyColumnOptions, currencyValueFormatter } from '../../components/DataGrid/DataGridUtils';
import { DataGridFooter } from '../../components/DataGrid/DataGridFooter';
import { useContext } from 'react';
import { UserListContext } from '../../context/UserListContext';
import moment from 'moment';
import { ExportToolbar } from '../../components/DataGrid/ExportToolbar';

const columns = [
  { field: 'userFullName', headerName: 'Utente', flex: 1 },
  { field: 'totalBookExpenses', headerName: 'Totale budget libri', flex: 1, ...currencyColumnOptions },
  { field: 'totalSmartWorkingExpenses', headerName: 'Totale budget smart working', flex: 1, ...currencyColumnOptions},
];

const getRows = ({
  expenses,
  users,
}) => expenses.reduce((acc, cur) => {
  const user = users.find(i => i.username === cur.username);
  let item = acc.find(i => i.id === user.id);
  if (!item) {
    item = {
      id: user.id,
      userFullName: user.full_name,  
      totalBookExpenses: 0,
      totalSmartWorkingExpenses: 0,
    }
    acc.push(item);
  }
  if (cur.type === 'bonus_books') {
    item.totalBookExpenses += cur.cost;
  } else {
    item.totalSmartWorkingExpenses += cur.cost;
  }
  return acc;
}, []);

export const AdminBonusExpensesDataGrid = ({
  bookExpenses,
  smartWorkingExpenses,
  year,
}) => {

  const { users } = useContext(UserListContext);
  const rows = getRows({ expenses: bookExpenses.concat(smartWorkingExpenses), users});

  const totalBookExpensesAllUsers = rows.reduce(    
    (acc, curr) => acc + curr.totalBookExpenses, 0    
  );

  const totalSmartWorkingExpensesAllUsers = rows.reduce(    
    (acc, curr) => acc + curr.totalSmartWorkingExpenses, 0    
  );

  const footerData = [
    { id: 1, label: 'Totale budget libri', value: totalBookExpensesAllUsers, ...currencyValueFormatter},
    { id: 2, label: 'Totale budget smart working', value: totalSmartWorkingExpensesAllUsers, ...currencyValueFormatter}
  ];

  const csvFilename = `${moment().format('YYYY-MM-DD')} - Report budget libri-smart working (${year})`;

  return (
    <>
      <Box sx={{ height: 700 }}>
        <BorderlessStripedDataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
              footer: DataGridFooter,
              toolbar: ExportToolbar,
          }}                      
          slotProps={{
              footer: { data: footerData },
              toolbar: { csvFilename },
          }}
        />
      </Box>
    </>
  );
};

AdminBonusExpensesDataGrid.propTypes = {
  bookExpenses: PropTypes.arrayOf(PropTypes.object),
  smartWorkingExpenses: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
};

AdminBonusExpensesDataGrid.defaultProps = {
  bookExpenses: [],
  smartWorkingExpenses: [],
  year: undefined,
};
