import _ from 'lodash';
import request from '../axios';
import { API } from '../../constants';
import { notifyResponseError, success } from '../notification';

const getFormData = (pValues) => {
  const formData = new FormData();

  _.each(pValues, (val, key) => {
    const keyString = key == null ? null : `${key}`;
    if (keyString === 'attachment') {
      const file = _.filter(val, { deleted: false });
      if (file.length > 0 && file[0].fileData.path) {
        formData.append(keyString, file[0].fileData);
      }
    } else {
      formData.append(keyString, val);
    }
  });

  return formData;
};

export const sendFiscalDocuments = (values) => {
  const formData = getFormData(values);

  return request
    .post(`${API.UTILITIES}send-fiscal-documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      success('Esito', 'Invio riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });
};
