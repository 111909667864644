/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Styled from 'styled-components';
import { Badge, Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormItem from './Form/FormItem';
import { useMemo } from 'react';
import Editor from 'react-simple-wysiwyg';

const FormContainer = Styled.div`
  margin-bottom: 15px;
`;

const ToLabel = Styled.span`
  margin-bottom: 5px;
  display: inline-block;
`;

const UsersContainer = Styled.div`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 15px;
`;

const initialValues = (subject, body) => ({
  subject,
  body
});

function onValidate() {
  return {};
}

function MailForm({ htmlEditor = false, subject, body = '', users, onSend, onClose }) {
  const showSubject = useMemo(() => !!subject, [subject]);

  async function onSubmit(values, { setSubmitting }) {
    try {
      await onSend({ ...values, usernames: users.map((u) => u.username) });
      onClose();
    } finally {
      setSubmitting(false);
    }
  }

  const Users = () =>
    users.map((user) => (
      <div key={`user-${user.id}`}>
        <Badge bg="secondary">
          {user.first_name} {user.last_name}
        </Badge>{' '}
      </div>
    ));

  return (
    <Formik
      validate={onValidate}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values, actions).then();
      }}
      initialValues={initialValues(subject, body)}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormContainer>
            {showSubject && (
              <FormItem
                type="text"
                name="subject"
                label="Oggetto"
                className="form-group"
              />
            )}
            {htmlEditor ? (
              <Editor
                value={values.body}
                onChange={(e) => setFieldValue('body', e.target.value)}
              />
            ) : (
              <FormItem
                type="textarea"
                rows="10"
                name="body"
                label="Testo"
                className="form-group"
              />
            )}
          </FormContainer>
          <ToLabel>Destinatari</ToLabel>
          <UsersContainer>
            <Users />
          </UsersContainer>
          <div className="buttonContent">
            <Button variant="secondary" onClick={onClose}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || (showSubject && !values.subject?.length) || !values.body?.length}
            >
              Invia
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

export default MailForm;
