import PropTypes from 'prop-types';
import { GridActionsCellItem, itIT } from '@mui/x-data-grid';
import { BorderlessStripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { StatusBadge } from '../../components/StatusBadge';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import EmailSendIcon from '../../components/Icons/EmailSendIcon';
import EmailSentIcon from '../../components/Icons/EmailSentIcon';

export const RetrospectiveDataGrid = ({
                                        data,
                                        year,
                                        onEditClick,
                                        onDeleteClick,
                                        onNotifyClick
                                      }) => {
  const outcomeStatusMap = {
    achieved: 'success',
    partially_achieved: 'warning',
    not_achieved: 'error'
  };

  const moodMap = {
    positive: {
      label: 'Positivo',
      status: 'success'
    },
    neutral: {
      label: 'Neutro',
      status: 'warning'
    },
    negative: {
      label: 'Negativo',
      status: 'error'
    }
  };

  const getPreviousGoalsLabel = (retrospective) => {
    if (!retrospective.previousGoals?.length) {
      return '';
    }
    return !_.isNil(retrospective.previousGoalsPercentage) ? `${Math.round(retrospective.previousGoalsPercentage)}%` : 'Esito mancante';
  };

  const columns = useMemo(() => [
    {
      field: 'user',
      headerName: 'Utente',
      width: 70,
      valueGetter: (params) => params.row.user?.username.toUpperCase() || 'N/A'
    },
    {
      field: 'date',
      headerName: 'Data',
      width: 100,
      valueFormatter: ({ value }) => value ? new Date(value).toLocaleDateString() : ''
    },
    {
      field: 'interviewers',
      headerName: 'Intervistatori',
      width: 180,
      valueGetter: (params) => params.row.interviewers?.map(interviewer => interviewer.username.toUpperCase()).sort().join(', ') || ''
    },
    {
      field: 'previousGoals',
      headerName: `Obiettivi ${year - 1}`,
      flex: 2,
      valueGetter: (value) => ({
          value: value.row.previousGoals.map(goal => goal.type.name).join(', '),
          items: value.row.previousGoals.map(goal => ({
            key: goal.id,
            label: goal.type.name,
            status: outcomeStatusMap[goal.outcome] || 'info'
          }))
        }),
      renderCell: (params) => (
        <Box
          sx={{
            'display': 'flex',
            'overflowX': 'scroll',
            'scrollbarColor': 'transparent transparent',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          {params.value.items.map(item => <StatusBadge key={item.key}
                                                       value={item.label}
                                                       status={item.status} />)}
        </Box>
      ),
      sortComparator: (v1, v2) => v1.value.localeCompare(v2.value)
    },
    {
      field: 'percentage',
      headerName: `Esiti ${year - 1}`,
      width: 140,
      valueGetter: (value) => ({
        value: value.row.previousGoals?.length ? value.row.previousGoalsPercentage : undefined,
        label: getPreviousGoalsLabel(value.row),
        status: outcomeStatusMap[value.row.previousGoalsOutcome] || 'info'
      }),
      renderCell: (params) => (
        <StatusBadge value={params.value.label} status={params.value.status} />
      ),
      sortComparator: (v1, v2) => {
        if (v1.value === undefined) {
          return -1;
        }
        if (v2.value === undefined) {
          return 1;
        }
        if (v1.value === null) {
          return -1;
        }
        if (v2.value === null) {
          return 1;
        }
        return v1.value - v2.value;
      }
    },
    {
      field: 'currentGoals',
      headerName: `Obiettivi ${year}`,
      valueGetter: (value) => value.row.currentGoals.map(goal => goal.type.name).join(', '),
      flex: 2
    },
    {
      field: 'mood',
      headerName: `Clima`,
      width: 140,
      valueGetter: (value) => ({
        value: value.row.mood,
        label: moodMap[value.row.mood]?.label,
        status: moodMap[value.row.mood]?.status || 'info'
      }),
      renderCell: (params) => (
        <StatusBadge value={params.value.label} status={params.value.status} showIcon={false} />
      ),
      sortComparator: (v1, v2) => v1.value.localeCompare(v2.value)
    },
    {
      field: 'comments',
      headerName: 'Commenti condivisi',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      )
    },
    {
      field: 'notes',
      headerName: 'Note interne',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      cellClassName: 'actions',
      width: 150,
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          icon={<EditOutlinedIcon />}
          label="Edit"
          onClick={() => onEditClick(id)}
          color="inherit"
          key={`edit_${id}`}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlinedIcon />}
          label="Delete"
          onClick={() => onDeleteClick(row)}
          color="inherit"
          key={`delete_${id}`}
        />,
        <GridActionsCellItem
          icon={row.lastNotificationDate ? <EmailSentIcon /> : <EmailSendIcon />}
          label="Notify"
          onClick={() => onNotifyClick(row)}
          color="inherit"
          key={`notify_${id}`}
        />
      ]
    }
  ], [
    onEditClick,
    onDeleteClick,
    onNotifyClick
  ]);
  const rows = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  return (
    <Box sx={{ height: 756 }}>
      <BorderlessStripedDataGrid
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        density="standard"
        disableColumnMenu
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        getRowHeight={() => 'auto'}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'name', sort: 'asc' }
            ]
          },
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[25, 50, 100]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
        }}
      />
    </Box>
  );
};

RetrospectiveDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onNotifyClick: PropTypes.func
};

RetrospectiveDataGrid.defaultProps = {
  data: [],
  year: new Date().getFullYear(),
  onEditClick: () => {
  },
  onDeleteClick: () => {
  },
  onNotifyClick: () => {
  }
};